import { formatDisplayPrice } from 'uibook/utils/formatDisplayPrice';
import { useProductsContext } from '@/hooks/useProductsContext';
import { useCustomerContext } from '@/hooks/useCustomerContext';
import { Link } from '@/elements/Link/Link';
import { useConsumerTypeContext } from '@/hooks/useConsumerTypeContext';
import { Badge, CSS_FONT_WEIGHT, ProductCard } from 'uibook';
import { SearchVariantForPLP } from '@/types/productTypes';
import { getImageProps } from 'next/image';

type ProductCardWrapperProps = {
  dataTestId?: string;
  product: SearchVariantForPLP;
  isPriorityImage?: boolean;
};

export const ProductCardWrapper = ({
  dataTestId,
  product,
  isPriorityImage,
}: ProductCardWrapperProps) => {
  const { consumerTypePrefixPath, consumerIsBusiness, consumerMoneyField } =
    useConsumerTypeContext();
  const { shouldDisplayPrices } = useProductsContext();
  const { isAddingTech, preApprovedAmount } = useCustomerContext();

  const displayPrice = formatDisplayPrice(product.lowestMonthlyCost[consumerMoneyField]);

  const content = (
    <ProductCard
      product={product}
      displayPrice={displayPrice}
      priceRenderState={shouldDisplayPrices ? 'displayed' : 'loading'}
      isPriorityImage={isPriorityImage}
      priceSuffix={consumerIsBusiness ? 'excl. VAT' : undefined}
      getImageProps={getImageProps}
      useGridLayoutOnDesktop
      isNextImage
    />
  );

  if (
    isAddingTech &&
    preApprovedAmount &&
    product.lowestMonthlyCost[consumerMoneyField] &&
    preApprovedAmount >= product.lowestMonthlyCost[consumerMoneyField]
  ) {
    return (
      <Link
        href={consumerTypePrefixPath(`/products/${product.variantSlug}`)}
        data-testid={dataTestId}
      >
        <Badge
          type="wrapped"
          fontWeight={CSS_FONT_WEIGHT.vars.medium}
          color="blue"
          fontSize={14}
          textTransform="uppercase"
          dataTestId={`${dataTestId}-eligible`}
          icon
        >
          Pre-approved
        </Badge>
        {content}
      </Link>
    );
  }

  return (
    <Link
      href={consumerTypePrefixPath(`/products/${product.variantSlug}`)}
      data-testid={dataTestId}
    >
      {content}
    </Link>
  );
};

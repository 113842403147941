import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconExclaimationCircleFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M12 .75a11.48 11.48 0 0 0-7.997 3.422A11.1 11.1 0 0 0 .75 12.197 11.044 11.044 0 0 0 11.813 23.25H12a11.353 11.353 0 0 0 11.25-11.447A11.035 11.035 0 0 0 12 .75m-1.406 15.506A1.387 1.387 0 0 1 12 14.812a1.434 1.434 0 0 1 1.425 1.379 1.38 1.38 0 0 1-1.378 1.434 1.435 1.435 0 0 1-1.453-1.369m.469-3.75V6.881a.937.937 0 1 1 1.875 0v5.625a.937.937 0 1 1-1.875 0"
    />
  </svg>
);
export const IconExclaimationCircleFilled = memo(SvgIconExclaimationCircleFilled);

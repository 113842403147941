import { cn } from '../../utils/cn';
import { IconClose } from 'uibook-icons/solid/IconClose';

type ChipProps = React.ComponentPropsWithoutRef<'button'> & {
  /** Label of the chip */
  label: string;

  /**
   * Whether the chip is selected - will apply the blue background and white text
   *
   * @default false
   */
  isSelected: boolean;

  /**
   * Whether to show the close icon
   *
   * @default false
   */
  showClose?: boolean;
};

/**
 * ChipComponent
 *
 * Renders a button that can be used as a chip - can be selected with suitable styles and has an
 * optional close icon.
 *
 * @returns The Chip button
 */
export const Chip = ({ label, isSelected, showClose, className, ...props }: ChipProps) => (
  <button
    className={cn('flex items-center justify-center whitespace-nowrap rounded-2xl px-3 py-1.5', {
      'bg-blue-500 text-white outline-none': isSelected,
      'outline-charcoal-300 hocus:outline-2 hover:outline-charcoal-500 bg-white outline outline-1':
        !isSelected,
      [`${className}`]: className,
    })}
    aria-checked={isSelected ? 'true' : undefined}
    {...props}
  >
    {label}
    {showClose && (
      <IconClose
        data-testid={props['data-testid'] ? `${props['data-testid']}-close` : undefined}
        className={cn('text-charcoal-500 ml-1 w-3.5 stroke-[3]', {
          'text-white': isSelected,
        })}
      />
    )}
  </button>
);

import { Divider } from '@/elements/Divider/Divider';
import Icon from '@/elements/Icon';
import { footerSocialMediaLinks, getFooterLists } from './footerConstants';
import { Link } from '@/elements/Link/Link';
import { cn, Typography, useMerchantTheme } from 'uibook';
import { useConsumerTypeContext } from '@/hooks/useConsumerTypeContext';
import { IconLogoPoweredByRaylo } from 'uibook-icons/custom/IconLogoPoweredByRaylo';
import { Theme } from 'uibook-themes';

type FooterLinkListProps = ReturnType<typeof getFooterLists>['footerListOne' | 'footerListTwo'];

const FooterLinkList = ({ title, links }: FooterLinkListProps) => {
  const { isRayloPayTheme } = useMerchantTheme();
  return (
    <div className="flex w-full flex-col gap-6 pt-4 md:pt-20">
      <Typography
        variant="body2"
        className={cn('text-white', {
          'font-bold': isRayloPayTheme,
        })}
      >
        {title}
      </Typography>
      <div
        className={cn('flex flex-col gap-3', {
          'text-pink-300': !isRayloPayTheme,
          'text-blue-200': isRayloPayTheme,
        })}
      >
        {links.map(({ label, link }) => (
          <Link
            key={`${label}-${link}`}
            href={link}
            className={cn('type-body2 hover:underline', {
              'font-bold': isRayloPayTheme,
            })}
          >
            {label}
          </Link>
        ))}
      </div>
    </div>
  );
};

type FooterProps = {
  dataTestId?: string;
};

export const Footer = ({ dataTestId }: FooterProps) => {
  const { isRayloPayTheme, themeName } = useMerchantTheme();
  const { consumerIsBusiness } = useConsumerTypeContext();

  const { footerListOne, footerListTwo } = getFooterLists({
    isRayloPay: isRayloPayTheme,
    consumerIsBusiness,
  });

  const { title: listOneTitle, links: listOneLinks } = footerListOne;
  const { title: listTwoTitle, links: listTwoLinks } = footerListTwo;

  return (
    <footer
      data-testid={dataTestId}
      className="bg-charcoal-500 flex w-full flex-col items-center p-6"
    >
      <div className="max-w-container flex w-full flex-col pb-5 md:flex-row-reverse md:pb-24">
        <div className="flex w-full">
          <FooterLinkList title={listOneTitle} links={listOneLinks} />
          <FooterLinkList title={listTwoTitle} links={listTwoLinks} />
        </div>

        <div className="flex w-full flex-col gap-6">
          <div className="flex w-full max-w-[16.4375rem] flex-col pt-16">
            {isRayloPayTheme && (
              <IconLogoPoweredByRaylo
                className="h-8 self-start text-white"
                data-testid="raylo-pay-logo"
              />
            )}
            {!isRayloPayTheme && <Icon name="Logo" />}

            <Typography variant="fineprint" className="text-charcoal-300 mb-4 pt-8">
              {
                {
                  [Theme.FLEX]:
                    'Sony Interactive Entertainment, trading as PlayStation, is an Introducer Appointed Representative of Raylo Group Limited, which is authorised and regulated by the Financial Conduct Authority (FRN 841488).',
                  [Theme.RAYLO]:
                    'Raylo Group Limited is authorised and regulated by the Financial Conduct Authority (841488).',
                }[themeName]
              }
            </Typography>

            <Typography variant="fineprint" className="text-charcoal-300">
              {
                {
                  [Theme.FLEX]:
                    'Raylo is a trading name of Raylo Group Limited, a company registered in England and Wales (Company No. 11554120). Registered office address is 5 New Street Square, London, EC4A 3TW. PlayStation acts as a credit broker, not a lender, and only introduces customers to Raylo Group Limited.',
                  [Theme.RAYLO]:
                    'Raylo is a trading name of Raylo Group Limited. Raylo Group Limited is registered in England and Wales company number 11554120.',
                }[themeName]
              }
            </Typography>
          </div>

          {!isRayloPayTheme && (
            <div className="flex w-full flex-col gap-1">
              <Typography variant="fineprint" className="text-charcoal-300" bold>
                Head Office
              </Typography>

              <Typography variant="fineprint" className="text-charcoal-300">
                Head 5 New Street Square, City of London, EC4A 3TW
              </Typography>
            </div>
          )}
        </div>
      </div>

      <div className="max-w-container flex w-full flex-col gap-2">
        <Divider className="bg-charcoal-400" />
        <div className="flex w-full flex-col gap-4 pt-3 md:flex-row-reverse md:justify-between">
          {!isRayloPayTheme && (
            <div className="flex items-center gap-4">
              {footerSocialMediaLinks.map(({ icon, link }) => (
                <a
                  key={link}
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  title={`Raylo on ${icon}`}
                >
                  <Icon name={icon} />
                </a>
              ))}
            </div>
          )}

          <Typography variant="fineprint" className="text-charcoal-300 mr-auto">
            © Copyright of Raylo {new Date().getFullYear()}. All rights reserved.
          </Typography>
        </div>
      </div>
    </footer>
  );
};

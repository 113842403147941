import { SideNavSubMenu } from '../SideNavSubMenu/SideNavSubMenu';
import { SideNavProps, SubMenuType } from '../Navbar.types';
import { NavBarConsumerToggle } from '../NavBarConsumerToggle/NavBarConsumerToggle';
import { Button } from '../../Button/Button';
import { LoggedInSubMenu } from '../LoggedInSubMenu/LoggedinSubMenu';
import { Drawer } from '../../Drawer/Drawer';
import { DrawerHeader } from '../../Drawer/DrawerHeader';
import { useMerchantTheme } from '../../MerchantTheme/MerchantTheme';

export const SideNav = ({
  isOpen,
  onClose,
  navData,
  subMenuOpen,
  setSubMenuOpen,
  setSideNavOpen,
  handleUpgradeExitModalOpen,
  displayUpgradeModal,
  accountHomeUrl,
  productsUrl,
  LinkComponent,
  loggedInData,
  isLoggedIn,
  desktopSide,
  overallAccountStatus,
}: SideNavProps) => {
  const { isRayloTheme } = useMerchantTheme();
  const setSubMenu = (subMenuType: SubMenuType, subMenuOption?: string | null) => {
    const menu = `${subMenuType}${subMenuOption ? `-${subMenuOption}` : ''}`;
    setSubMenuOpen(menu);
  };

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      anchorMobile="right"
      anchorDesktop={desktopSide}
      sliderProps={{
        'data-testid': 'sidenav',
      }}
    >
      <DrawerHeader
        title={loggedInData ? loggedInData.email : 'Menu'}
        onClose={onClose}
        className="bg-tv-header text-white"
        buttonProps={{
          'data-testid': 'sidenav-close',
        }}
      />
      {loggedInData && (
        <LoggedInSubMenu
          loggedInData={loggedInData}
          overallAccountStatus={overallAccountStatus}
          accountsUrl={accountHomeUrl}
          handleUpgradeExitModalOpen={handleUpgradeExitModalOpen}
          displayUpgradeModal={displayUpgradeModal}
          LinkComponent={LinkComponent}
          closeSideNav={onClose}
        />
      )}
      {navData && (
        <>
          {isRayloTheme && !isLoggedIn && <NavBarConsumerToggle location="sideNav" />}
          <SideNavSubMenu
            items={navData}
            setSubMenu={setSubMenu}
            setSideNavOpen={setSideNavOpen}
            activeItem={subMenuOpen}
            accountHomeUrl={accountHomeUrl}
            productsUrl={productsUrl}
            LinkComponent={LinkComponent}
            loggedIn={!!isLoggedIn}
          />
        </>
      )}
      {loggedInData && (
        <div className="m-6">
          <Button
            className="w-full"
            variant="outlined"
            onClick={() => (window.location.href = `${accountHomeUrl}/logout`)}
          >
            Logout
          </Button>
        </div>
      )}
    </Drawer>
  );
};

import { styled } from 'styled-components';
import { px2Rem } from '../../../utils/px2Rem';
import { BREAKPOINTS } from '../../../constants';

export const StyledQuickLinks = styled.li`
  padding-bottom: ${px2Rem(72)};

  @media (min-width: ${BREAKPOINTS.tablet}px) {
    padding-bottom: 0;
  }
`;

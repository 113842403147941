'use client';

import { styled } from 'styled-components';

const StyledRayloModal = styled.div`
  & .css-ci8l2m-ModalBody {
    padding: 0 !important;
  }
`;

export { StyledRayloModal };

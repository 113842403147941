import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconCustomerSupport = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    strokeWidth={1.5}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M5.333 17.333h-.666A2.667 2.667 0 0 1 2 14.667V12a2.667 2.667 0 0 1 2.667-2.667h.666A.667.667 0 0 1 6 10v6.667a.667.667 0 0 1-.667.666m14 0h-.666a.667.667 0 0 1-.667-.666V10a.666.666 0 0 1 .667-.667h.666A2.667 2.667 0 0 1 22 12v2.667a2.667 2.667 0 0 1-2.667 2.666"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4.667 9.333A7.333 7.333 0 0 1 12 2v0a7.333 7.333 0 0 1 7.333 7.333m-4.666 11.334h2A2.667 2.667 0 0 0 19.333 18v-.667"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.333 22H12a1.333 1.333 0 1 1 0-2.667h1.333a1.333 1.333 0 0 1 0 2.667m-4-6.94a3.96 3.96 0 0 0 5.334 0m-5.334-4.851v1.166m5.334-1.166v1.166"
    />
  </svg>
);
export const IconCustomerSupport = memo(SvgIconCustomerSupport);

import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useAppContext } from '../../hooks/useAppContext';
import { BenefitsInfo } from '@/components/BenefitsInfo/BenefitsInfo';
import { IProductsWrapper } from './types';
import { NavMobileApp, useMerchantTheme } from 'uibook';
import { useCustomerContext } from '../../hooks/useCustomerContext';
import { Footer } from '@/components/footer/Footer';
import TrustPilotBanner from '@/components/trustpilotbanner';
import { useConsumerTypeContext } from '@/hooks/useConsumerTypeContext';
import { SmbInfoModal } from '../SmbInfoModal/SmbInfoModal';

export const ProductsWrapper = ({ children, context }: IProductsWrapper) => {
  const { isRayloTheme } = useMerchantTheme();
  const { isMobileApp } = useCustomerContext();
  const { consumerIsPersonal, consumerIsBusiness } = useConsumerTypeContext();
  const { isRayloPay } = useAppContext();
  const router = useRouter();

  const { boot, update } = useIntercom();

  useEffect(() => {
    if (isRayloPay) {
      boot({
        customLauncherSelector: '.launch-intercom',
      });
      if (router.asPath.includes('products/')) {
        update({
          alignment: 'middle',
          horizontalPadding: 32,
          verticalPadding: 150,
        });
      }
    }
  }, [isRayloPay, boot, update, router?.asPath]);

  const isProductDescriptionPage = router?.asPath.includes('products/');

  return (
    <>
      {isMobileApp && <NavMobileApp />}

      <div className="flex w-full flex-col">{children}</div>

      {isRayloTheme && !isProductDescriptionPage && !isMobileApp && (
        <TrustPilotBanner
          /**
           * On the Business PLP, the `TrustPilotBanner` renders directly before the `<Footer />`.
           * Both have the same background colour, so we need to add a border to separate them.
           */
          enableBorderBottom={consumerIsBusiness}
          dataTestId="trust-pilot-banner"
        />
      )}
      {consumerIsPersonal && <BenefitsInfo className="bg-white py-12 sm:px-4" />}
      {!isMobileApp && <Footer />}
      {['products', 'product'].includes(context) && <SmbInfoModal />}
    </>
  );
};

import { useMerchantTheme } from '../../MerchantTheme/MerchantTheme';
import { SearchField } from '../../SearchField/SearchField';

type NavBarFakeSearchFieldProps = {
  onClick: (event: React.MouseEvent) => void;
};

export const NavBarFakeSearchField = ({ onClick }: NavBarFakeSearchFieldProps) => {
  const { isRayloTheme } = useMerchantTheme();

  return (
    <SearchField
      value=""
      onChange={() => {}}
      wrapperProps={{ onClick: onClick }}
      placeholder={!isRayloTheme ? 'Search for products...' : 'Search for products, brands...'}
    />
  );
};

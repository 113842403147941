import React from 'react';
import Icon from '../../elements/Icon';
import { IBulletList } from './BulletList.types';
import { Typography } from '../../foundations/Typography/Typography';
import { cn } from '../../utils/cn';

export const BulletList = ({
  dataTestId,
  items,
  bulletIcon,
  className,
  itemClassName,
}: IBulletList) => {
  return (
    <div data-testid={dataTestId} className={cn('flex flex-col gap-2', className)}>
      {items.map((item: string | React.ReactElement, itemIndex) => (
        <div key={itemIndex} className={cn('flex gap-3.5', itemClassName)}>
          <div className="flex h-[1.0625rem] w-fit flex-shrink-0 items-center">
            {bulletIcon && typeof bulletIcon !== 'string' ? (
              bulletIcon
            ) : bulletIcon ? (
              <Icon name={bulletIcon} />
            ) : (
              <span className="bg-tv-icon-bullet block size-1 rounded-full" />
            )}
          </div>
          {typeof item === 'string' ? <Typography variant="body2">{item}</Typography> : item}
        </div>
      ))}
    </div>
  );
};

'use client';

import React, { useState } from 'react';
import { palette } from '../../colors';
import { px2Rem } from '../../utils/px2Rem';
import Container from '../../elements/Container';
import Copy from '../../elements/Copy';
import Callout from '../../designSystem/components/Callout';
import Header from '../../elements/Header';
import Spacer from '../../elements/Spacer';
import { Modal } from '../../components/Modal/Modal';
import LegacyButton from '../../designSystem/components/Button';
import { CloseButton } from '../navbar/utils/utilComponents';

export const DemoModeBanner = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <Callout
        text="This is a demo experience."
        link={{ text: 'Learn more.', onClick: openModal }}
      />
      <Modal
        dataTestId="demo-mode-modal"
        mobileWidth="80%"
        desktopWidth="50%"
        offsetTop={100}
        modalOpen={isModalOpen}
        closeModal={closeModal}
      >
        <Container
          dataTestId="demo-mode-modal-content"
          padding={px2Rem(20)}
          backgroundColor={palette.white}
        >
          <Container alignRight>
            <CloseButton fillColour="black" hoverFillColour="black" onClick={closeModal} />
          </Container>
          <Spacer height={12} />
          <Header level={2}>About the demo experience</Header>
          <Spacer height={24} />
          <Copy>
            We&apos;re excited to share this demo of the Raylo app with you and gather your
            feedback.
          </Copy>
          <Spacer height={16} />
          <Copy>
            All data in this demo is simulated. You can explore the features, but you will not be
            able to action anything. No leases will be started and no devices will be shipped.
          </Copy>
          <Spacer height={24} />
          <LegacyButton medium onClick={closeModal}>
            <Copy color={palette.white}>Okay</Copy>
          </LegacyButton>
        </Container>
      </Modal>
    </>
  );
};

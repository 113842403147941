import { gql } from '../../graphql/generated';

export const AUTHENTICATE_CLIENT_MUTATION = gql(/* GraphQL */ `
  mutation AuthenticateClient($clientId: String!) {
    authenticateClient(clientId: $clientId) {
      accessToken {
        value
        expiresAt
      }
    }
  }
`);

import { useEffect, useState } from 'react';
import { cn, Typography } from 'uibook';
import { SortMenu } from '../SortMenu/SortMenu';
import { FilterProductCount } from '../FilterProductCount/FilterProductCount';
import { useInstantSearch } from 'react-instantsearch';
import { capitalize } from '@/utils/strings.utils';
import { ClearRefinements } from '../ClearRefinements/ClearRefinements';

type HeaderLabel = {
  type: 'search' | 'category';
  text: string;
} | null;

const initialHeaderLabel: HeaderLabel = {
  type: 'category',
  text: 'All products',
};

export const FilterHeader = () => {
  const [headerLabel, setHeaderLabel] = useState<HeaderLabel>(initialHeaderLabel);

  const { indexUiState } = useInstantSearch();

  /**
   * Not ideal, but as we are statically-generating the page, and the `indexUiState` is based on the
   * default state of the PLP, we need to update the `HeaderLabel` on the client-side only.
   */
  useEffect(() => {
    if (indexUiState.query) {
      setHeaderLabel({
        type: 'search',
        text: `“${indexUiState.query}”`,
      });
      return;
    }
    if (!indexUiState.refinementList?.category) {
      setHeaderLabel(initialHeaderLabel);
      return;
    }
    if (indexUiState.refinementList.category.length === 1) {
      setHeaderLabel({
        type: 'category',
        text: capitalize(indexUiState.refinementList.category[0]),
      });
      return;
    }

    setHeaderLabel(null);
  }, [indexUiState.query, indexUiState.refinementList?.category]);

  /**
   * Very important not to add any extra divs here, `<FilterSortTrigger />` need to be a direct
   * child of the parent component so we can make use the the `sticky` functionality with CSS.
   *
   * Other divs can still be added if needed, but not anything which becomes a parent of the
   * `<FilterSortTrigger />` component.
   */
  return (
    <>
      <div className="flex flex-col justify-center gap-2" data-testid="filter-header">
        {headerLabel ? (
          <>
            {headerLabel.type === 'search' && (
              <Typography variant="body2" medium data-testid="filter-header-search-results">
                Search results for
              </Typography>
            )}

            <div className="flex flex-wrap items-end gap-4">
              <Typography
                variant="h2"
                bold
                /**
                 * `break-all` would be ideal here, but it causes the text to break in the middle of
                 * word, such as "iphone 16 pro max" on a small screen would break to:
                 *
                 * ```
                 * iphone 16 pr
                 * o max
                 * ```
                 *
                 * Instead, we use `overflow-wrap: anywhere;` which formats the text correctly on
                 * smaller screens:
                 *
                 * ```
                 * iphone 16
                 * pro max
                 * ```
                 *
                 * @url https://tailwindcss.com/docs/word-break
                 * @url https://github.com/tailwindlabs/tailwindcss/discussions/10545#discussioncomment-4922470
                 */
                className="lg:type-h1 [overflow-wrap:anywhere]"
                data-testid="filter-header-text"
              >
                {headerLabel.text}
              </Typography>
              <FilterProductCount
                className={cn('hidden flex-shrink-0 lg:block', {
                  'pb-1': !!headerLabel,
                })}
              />
            </div>
          </>
        ) : (
          <FilterProductCount
            className={cn('hidden flex-shrink-0 lg:block', {
              'pb-1': !!headerLabel,
            })}
          />
        )}
      </div>

      <div className="hidden flex-shrink-0 lg:ml-auto lg:block">
        <SortMenu />
      </div>

      <div className="flex w-full justify-between gap-4 lg:hidden">
        <FilterProductCount />
        <ClearRefinements />
      </div>
    </>
  );
};

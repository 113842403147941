import 'tailwindcss-config/src/tailwind.css';
import '@/styles/globals.css';
import type { AppProps } from 'next/app';
import { DemoModeBannerWrapper } from '@/components/DemoModeBannerWrapper/DemoModeBannerWrapper';
import { GlobalAppProvider, RayloAppProps } from '@/providers/GlobalAppProvider';
import { GoogleTagManagerTracking } from '@/components/GoogleTagManagerTracking/GoogleTagManagerTracking';
import { getThemeNameByMerchantId } from 'uibook-themes';

function App(props: AppProps<RayloAppProps>) {
  const themeName = getThemeNameByMerchantId(process.env.NEXT_PUBLIC_RAYLO_MERCHANT_ID);

  return (
    <GlobalAppProvider {...props.pageProps} themeName={themeName}>
      <DemoModeBannerWrapper />
      <props.Component {...props.pageProps} />
      <GoogleTagManagerTracking />
    </GlobalAppProvider>
  );
}

export default App;

import { useEffect, useRef } from 'react';
import useScript from '@/hooks/useScript';
import { ITrustPilotBanner } from './types';
import { cn, palette, Typography } from 'uibook';

export const TrustPilotBanner = ({ enableBorderBottom, dataTestId }: ITrustPilotBanner) => {
  const [loaded] = useScript('//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js');

  // Create a reference to the <div> element which will represent the TrustBox
  const ref = useRef(null);

  useEffect(() => {
    if (loaded && window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, [loaded]);

  return (
    <div
      className={cn('bg-charcoal-500 overflow-x-hidden p-6 text-white', {
        'border-charcoal-400 border-b': enableBorderBottom,
      })}
    >
      <div
        data-testid={dataTestId}
        className="max-w-container mx-auto flex h-[21.875rem] flex-col items-center justify-center gap-8 text-center"
      >
        <Typography variant="body1" bold className="sm:type-h3 text-white">
          4.5 stars across 7000+ Trustpilot reviews.{' '}
          <span
            style={{
              color: palette.charcoal[300],
            }}
          >
            Thousands are joining Raylo every month.
          </span>
        </Typography>
        <div
          ref={ref}
          className="trustpilot-widget"
          data-testid={dataTestId}
          data-locale="en-GB"
          data-template-id="53aa8912dec7e10d38f59f36"
          data-businessunit-id="5cdd6c38b4acc6000128faf9"
          data-style-height="240px"
          data-style-width="100%"
          data-theme="dark"
          data-stars="4,5"
          data-review-languages="en"
          style={{
            position: 'relative',
            height: '140px',
            width: '110%',
            borderStyle: 'none',
            display: 'block',
            overflow: 'hidden',
          }}
        >
          <a href="https://uk.trustpilot.com/review/www.raylo.com" rel="noopener">
            Trustpilot
          </a>
        </div>
      </div>
    </div>
  );
};

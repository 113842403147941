import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconSuccess = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 25" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 24.012c6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12s-12 5.372-12 12c0 6.627 5.373 12 12 12m5.167-15.104a1.042 1.042 0 0 0-1.634-1.292l-5.462 6.91-1.57-2.228a1.042 1.042 0 0 0-1.703 1.2L8.57 16.01a1.802 1.802 0 0 0 2.917.084z"
      clipRule="evenodd"
    />
  </svg>
);
export const IconSuccess = memo(SvgIconSuccess);

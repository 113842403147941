import { StyledFormCheckbox } from './FormCheckbox.styles';
import { IFormCheckbox } from './FormCheckbox.types';
import { Typography } from '../../foundations/Typography/Typography';
import { cn } from '../../utils/cn';

export const FormCheckbox = ({
  dataTestId,
  onChange,
  checked,
  disabled,
  icon: Icon,
  iconProps,
  label,
  showError,
  errorMessage,
  children,
  backgroundColor,
  size = 24,
  padding,
  hoverColor,
  id,
}: IFormCheckbox) => (
  <div className="flex w-full flex-col">
    <StyledFormCheckbox
      data-testid={dataTestId}
      $errorBorder={showError}
      $backgroundColor={backgroundColor}
      $size={size}
      $padding={padding}
      $hoverColor={hoverColor}
    >
      <div className="checkbox">
        <input
          type="checkbox"
          id={id}
          onChange={onChange}
          checked={checked}
          disabled={disabled ?? false}
          data-testid={`${dataTestId}-checkbox`}
          className="peer"
        />
        <span
          data-testid={`${dataTestId}-tick-icon`}
          className="peer-checked:bg-tv-checkbox-active peer-disabled:bg-tv-checkbox-disabled"
        />
      </div>
      <div className="label">
        {Icon && (
          <Icon
            {...iconProps}
            data-testid={`${dataTestId}-icon`}
            className={cn('mr-2 h-6 text-pink-500', iconProps?.className)}
          />
        )}
        {label}
        {children}
      </div>
    </StyledFormCheckbox>
    {errorMessage && showError && (
      <Typography
        variant="body2"
        className="text-error-600 pt-3"
        data-testid={`${dataTestId}--message`}
      >
        {errorMessage}
      </Typography>
    )}
  </div>
);

import { useMemo } from 'react';
import { cn, Typography, useMerchantTheme } from 'uibook';
import { IconCustomerSupport } from 'uibook-icons/solid/IconCustomerSupport';
import { IconEcologyGlobeNature } from 'uibook-icons/solid/IconEcologyGlobeNature';
import { IconInsurance } from 'uibook-icons/solid/IconInsurance';
import { IconPhoneDoubleCamera } from 'uibook-icons/solid/IconPhoneDoubleCamera';
import { IconShieldPhone } from 'uibook-icons/solid/IconShieldPhone';
import { IconWalletOpen } from 'uibook-icons/solid/IconWalletOpen';
import { IconDeliveryTruck } from 'uibook-icons/solid/IconDeliveryTruck';

type BenefitsInfoProps = {
  dataTestId?: string;
  className?: string;
};

export const BenefitsInfo = ({ dataTestId, className }: BenefitsInfoProps) => {
  const { isRayloPayTheme } = useMerchantTheme();

  const items = useMemo(() => {
    if (isRayloPayTheme) {
      return [
        {
          icon: IconDeliveryTruck,
          title: 'Free next day delivery',
          copy: 'Consoles and accessories with free next-day DPD delivery.',
        },
        {
          icon: IconInsurance,
          title: 'Peace of mind',
          copy: 'Great insurance cover from just £4.99/month.',
        },
        {
          icon: IconEcologyGlobeNature,
          title: 'Greener too',
          copy: 'Reduce your carbon impact by 56% when you lease instead of buy.',
        },
        {
          icon: IconWalletOpen,
          title: 'Pay nothing today',
          copy: 'Get your console for £0 today, return for free within 14 days.',
        },
      ];
    }
    return [
      {
        icon: IconCustomerSupport,
        title: 'Wow Service',
        copy: 'Our UK based team works super hard. Check our reviews.',
      },
      {
        icon: IconPhoneDoubleCamera,
        title: 'Top notch accessories',
        copy: 'Free case with screen protection that you’ll actually want to use.',
      },
      {
        icon: IconEcologyGlobeNature,
        title: 'Greener too',
        copy: 'Our refurb and reuse approach means that all Raylo tech gets as many lives as possible.',
      },
      {
        icon: IconShieldPhone,
        title: 'Peace of mind',
        copy: 'Great insurance cover from just £5.99/month. Underwritten by Collinson, made simple by Raylo.',
      },
    ];
  }, [isRayloPayTheme]);

  return (
    <div data-testid={dataTestId} className={cn(className)}>
      <div className="max-w-container mx-auto grid items-start gap-12 sm:grid-cols-2 lg:grid-cols-4">
        {items.map(({ icon: Icon, title, copy }, index) => (
          <div
            key={index}
            data-testid="benefits-info-item"
            className="flex w-full flex-col items-center gap-4 px-8 text-center sm:items-start sm:px-0 sm:text-left"
          >
            <Icon className="text-tv-icon-primary w-10 stroke-1" data-testid={`icon-${index}`} />
            <Typography bold data-testid={`title-${index}`}>
              {title}
            </Typography>
            <Typography className="text-charcoal-400" data-testid={`copy-${index}`}>
              {copy}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};

import { hexToRGB } from './utils/hexToRGB';
import { FLEX_FONTS, FLEX_PALETTE, FLEX_VARIABLES } from './themes/flex.theme';
import { Theme } from './themes.types';
import { DEFAULT_THEME_PALETTE, DEFAULT_THEME_VARIABLES } from './themes/default.theme';
import { ASSETS_FONTS_BASE_URL } from '../../uibook/src/constants';

export { Theme };

export const THEMES = {
  [Theme.RAYLO]: {
    tailwindName: 'raylo',
    variables: DEFAULT_THEME_VARIABLES,
    palette: DEFAULT_THEME_PALETTE,
    fonts: {},
    features: [
      'plp.filter.category',
      'plp.filter.model',
      'plp.filter.make',
      'plp.filter.condition',
      'plp.filter.price',
    ] as const,
  },
  [Theme.FLEX]: {
    /** Shortened name, as `sony-playstation-flex` will generate very long classNames for Tailwind */
    tailwindName: 'flex',
    variables: FLEX_VARIABLES,
    palette: FLEX_PALETTE,
    fonts: FLEX_FONTS,
    features: ['homepage', 'plp.filter.model', 'plp.filter.price'] as const,
  },
};

/**
 * Retrieves the theme name by decoding the provided merchant ID.
 *
 * @param merchantId - The encoded merchant ID string. If undefined, the function returns undefined.
 * @returns The decoded theme name if it exists in the THEMES object, otherwise undefined.
 */
export const getThemeNameByMerchantId = (merchantId: string | undefined) => {
  if (merchantId) {
    const decodedMerchantName = atob(merchantId).replace('Merchant-', '');
    return THEMES[decodedMerchantName as Theme] ? (decodedMerchantName as Theme) : undefined;
  }
};

type ThemeFeature = (typeof THEMES)[keyof typeof THEMES]['features'][number];

/**
 * Returns whether a feature exists in the specified theme.
 *
 * @param themeName - The name of the theme to check. If undefined, the function returns false.
 * @param feature - The feature to look for within the theme.
 * @returns `true` if the feature exists in the theme, otherwise `false`.
 */
export const themeFeatureExists = (themeName: Theme | undefined, feature: ThemeFeature) => {
  if (!themeName) {
    return false;
  }
  const features = [...THEMES[themeName].features];
  return features.includes(feature);
};

/**
 * Gets the theme based on the config, and converts the values to CSS custom properties, which are
 * then applied to the root element.
 *
 * A theme such as:
 *
 * ```json
 * {
 *   "navbar": "#0070f3"
 * }
 * ```
 *
 * Will be converted to:
 *
 * ```css
 * :root {
 *   --theme-navbar: #0070f3;
 * }
 * ```
 *
 * This is used in combination with `themePalettePlugin` in
 * `packages/tailwindcss-config/src/plugins/themePalette.plugin.ts`
 */
export const themeNameToCssCustomProperties = (themeName: Theme) =>
  Object.entries(THEMES[themeName].variables)
    .map(([key, value]) => `--theme-${key}: ${hexToRGB(value)};`)
    .join('\n');

/**
 * Generates the CSS `@font-face` rules for the given theme. This is used to add extra web fonts to
 * the theme, if there are any.
 *
 * At the moment, only a single extra font-family is supported, which is the `brand` font-family.
 * Using CSS Custom Properties, if there is a `--font-family-brand` variable in the CSS, it will be
 * used as the font-family for the brand, otherwise it will default to the Raylo font. Config for
 * this is in `packages/tailwindcss-config/tailwind.config.ts`
 *
 * @param themeName - The name of the theme for which to generate the font-face rules.
 * @returns A string containing the CSS `@font-face` rules for the specified theme, or an empty
 *   string if the theme has no fonts.
 */
export const themeNameToFontFace = (themeName: Theme) => {
  if (Object.keys(THEMES[themeName].fonts).length === 0) {
    return '';
  }

  const fontFaces = Object.entries(THEMES[themeName].fonts)
    .flatMap(([fontName, fontFaces]) => {
      return fontFaces.map(
        (fontFace) => `
          @font-face {
            font-family: "${fontName}";
            font-weight: ${fontFace.weight};
            src: url("${ASSETS_FONTS_BASE_URL}/${fontFace.filename}.woff2") format('woff2'), url("${ASSETS_FONTS_BASE_URL}/${fontFace.filename}.woff") format('woff');
            font-display: swap;
          }`,
      );
    })
    .join('\n');

  const fontFacesProperties = Object.keys(THEMES[themeName].fonts)
    .map((fontName) => `--font-family-${fontName}: "${fontName}";`)
    .join('\n');

  return `
    ${fontFaces}

    :root {
      ${fontFacesProperties}
    }
  `;
};

/**
 * Converts a theme variable to a CSS variable.
 *
 * Useful for working with styled-components which have not been migrated to Tailwind. Also adds a
 * level of type-safety.
 *
 * ```css
 * .my-component {
 *  color: ${themeVarToCssVar('header')};
 * }
 * ```
 *
 * This will be converted to:
 *
 * ```css
 * .my-component {
 *   color: rgb(var(--theme-header));
 * }
 * ```
 */
export const themeVarToCssVar = (themeVar: keyof typeof DEFAULT_THEME_VARIABLES) =>
  `rgb(var(--theme-${themeVar}))`;

import { useCustomerContext } from '@/hooks/useCustomerContext';
import { Typography } from 'uibook';
import { IconCheckBadge } from 'uibook-icons/solid/IconCheckBadge';
import { IconExclaimationCircleFilled } from 'uibook-icons/solid/IconExclaimationCircleFilled';

export const BannerContent = ({
  formattedAmount,
  shortVersion = false,
  inArrears,
}: {
  formattedAmount: string;
  shortVersion?: boolean;
  inArrears?: boolean;
}) => {
  const { itemInArrearsPaymentSlug } = useCustomerContext();

  return (
    <div
      className="flex items-center"
      data-testid={inArrears ? 'in-arrears-banner' : 'pre-approved-amount-banner'}
    >
      {inArrears ? (
        <>
          <IconExclaimationCircleFilled className="text-error-500 size-5" />
          <Typography className="ml-2" variant="body2">
            Payments overdue.&nbsp;
          </Typography>
          <Typography
            className="text-blue-500 hover:underline"
            href={itemInArrearsPaymentSlug}
            element="a"
            variant="body2"
            title="Make overdue payment"
          >
            Pay now
          </Typography>
        </>
      ) : (
        <>
          <IconCheckBadge className="size-6 text-blue-300" />
          <Typography className="ml-2" variant="body2">
            {shortVersion ? 'Pre-approved' : 'Available pre-approved limit'}:&nbsp;
          </Typography>
          <Typography bold>{formattedAmount}/mo</Typography>
        </>
      )}
    </div>
  );
};

import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconCheckBadge = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 25" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.793 2.89c-.195.093-.367.23-.502.398L9.948 4.95a1.45 1.45 0 0 1-1.285.533L6.53 5.25a1.447 1.447 0 0 0-1.599 1.6l.232 2.133a1.45 1.45 0 0 1-.533 1.286l-1.67 1.344a1.448 1.448 0 0 0 0 2.257l1.67 1.343a1.45 1.45 0 0 1 .533 1.287l-.232 2.133a1.448 1.448 0 0 0 1.6 1.6l2.132-.232a1.45 1.45 0 0 1 1.285.533l1.342 1.671a1.448 1.448 0 0 0 2.257 0l1.338-1.665a1.45 1.45 0 0 1 1.285-.534l2.132.232a1.447 1.447 0 0 0 1.599-1.6l-.232-2.133a1.45 1.45 0 0 1 .533-1.286l1.677-1.338a1.448 1.448 0 0 0 0-2.258l-1.672-1.344a1.45 1.45 0 0 1-.533-1.285l.232-2.133a1.45 1.45 0 0 0-1.599-1.6l-2.132.232a1.45 1.45 0 0 1-1.285-.534l-1.343-1.672a1.45 1.45 0 0 0-1.754-.398m4.557 8.308a.75.75 0 0 0-1.2-.9l-3.479 4.64-2.054-2.054a.75.75 0 1 0-1.06 1.06l2.122 2.123a1.417 1.417 0 0 0 2.134-.15v-.001z"
      clipRule="evenodd"
    />
  </svg>
);
export const IconCheckBadge = memo(SvgIconCheckBadge);

import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconEcologyGlobeNature = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    strokeWidth={1.5}
    viewBox="0 0 25 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M22.355 12.35c0 5.879-4.766 10.645-10.645 10.645-5.88 0-10.646-4.766-10.646-10.645h21.29Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3.498 19.124h5.309a1.936 1.936 0 0 0 0-3.871 1.935 1.935 0 0 1-1.936-1.936v-.967m14.323 4.839h-3.678a1.935 1.935 0 0 0-1.935 1.935v3.145"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m23.11 8.12-1.95-2.646c.64.51 1.56-.236.926-1.153l-2.247-3.058a.484.484 0 0 0-.775 0l-2.248 3.058c-.634.917.29 1.664.927 1.153L15.79 8.12a.808.808 0 0 0 .402 1.326h6.516a.808.808 0 0 0 .403-1.326Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19.449 9.447v2.903M15 12l-4.6-6.79a.44.44 0 0 0-.36-.21.44.44 0 0 0-.36.21L7 9.166M9 12 5.522 7.236A.55.55 0 0 0 5.09 7a.55.55 0 0 0-.438.224L1 12"
    />
  </svg>
);
export const IconEcologyGlobeNature = memo(SvgIconEcologyGlobeNature);

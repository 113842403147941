import { AccountStatusType } from '../components/navbar/Navbar.types';
import { CheckoutState, Order } from '../types/navData.types';

const validUpgradeCheckoutStates: CheckoutState[] = ['STARTED', 'ABANDONED'];

/** Checks whether the currently selected account has orders in arrears or upgrade */
export const getAccountStatus = (orders: Order[]): AccountStatusType =>
  orders?.some((order) => (order.items[0]?.subscription?.arrearsAmount?.valueInSubunit ?? 0) > 0)
    ? 'arrears'
    : orders?.some((order) => {
          const { eligible, checkout } = order.items[0]?.subscription?.upgrade || {};
          return eligible || (checkout && validUpgradeCheckoutStates.includes(checkout.state));
        })
      ? 'upgrade'
      : null;

'use client';

import { ILottieAnimation } from './LottieAnimation.types';
import { useCallback, useEffect, useMemo, useRef } from 'react';

export const LottieAnimation = ({
  dataTestId,
  isLoop,
  autoplay,
  animationData,
  height,
  width,
  className,
}: ILottieAnimation) => {
  const lottieLoaded = useRef(false);
  const defaultOptions = useMemo(
    () => ({
      loop: isLoop || false,
      autoplay: autoplay ?? true,
      animationData,
    }),
    [isLoop, autoplay, animationData],
  );

  const animationRef = useRef(null);

  const getLottie = useCallback(async () => {
    if (!animationRef.current || lottieLoaded.current) return;
    lottieLoaded.current = true;
    // use webflow lottie if available
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Webflow lottie is not typed
    const webflowLottie = window?.Webflow?.require('lottie').lottie;

    if (webflowLottie) {
      webflowLottie.loadAnimation({
        ...defaultOptions,
        container: animationRef.current,
      });
      return;
    }

    // use lottie-web if webflow lottie is not available
    const lottie = await import('lottie-web');

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    lottie.default.loadAnimation({
      ...defaultOptions,
      container: animationRef.current,
    });
  }, [animationRef, defaultOptions]);

  useEffect(() => {
    if (lottieLoaded.current) return;
    getLottie();
  }, [getLottie]);

  return (
    <div data-testid={dataTestId} className={className}>
      <div ref={animationRef} style={{ height, width, margin: '0 auto' }} />
    </div>
  );
};

import { HexColor } from '../themes.types';

/**
 * Converts a hexadecimal color code to an RGB color string.
 *
 * @param {string} hex - The hexadecimal color code (e.g., "#RRGGBB").
 * @returns {string} The RGB color string in the format "R G B".
 * @throws {Error} If the provided hex color format is invalid.
 * @url https://developer.mozilla.org/en-US/docs/Web/CSS/color_value/rgb
 */
export const hexToRGB = (hex: HexColor) => {
  const sanitized = hex.replace(/^#/, '');

  if (sanitized.length !== 6) {
    throw new Error('Invalid hex colour format.');
  }

  const r = parseInt(sanitized.slice(0, 2), 16);
  const g = parseInt(sanitized.slice(2, 4), 16);
  const b = parseInt(sanitized.slice(4, 6), 16);

  return `${r} ${g} ${b}`;
};

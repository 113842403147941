import { Dispatch, SetStateAction } from 'react';
import TextAccordion from '../../TextAccordion';
import { MenuLinks, MenuItem } from '../utils/utilComponents';
import { palette } from '../../../colors';
import { aboutSubMenuLinks, helpSubMenuLinks } from '../utils/utils';
import { StyledQuickLinks } from './NavBarQuickLinks.styles';
import { SideNavType, useNavBarContext } from '../NavBarContext/NavBarContext';
import { cn } from '../../../utils/cn';
import { Button } from '../../Button/Button';
import { useMerchantTheme } from '../../MerchantTheme/MerchantTheme';
import { MediaObject } from '../../MediaObject/MediaObject';
import { ASSETS_SVGS_BASE_URL } from '../../../constants';

export const NavbarQuickLinks = ({
  accountHomeUrl,
  setSideNavOpen,
  loggedIn,
}: {
  accountHomeUrl: string;
  setSideNavOpen: Dispatch<SetStateAction<SideNavType>>;
  loggedIn: boolean;
}) => {
  const { isRayloTheme, themeHasFeature } = useMerchantTheme();
  const { LinkComponent, consumerIsBusiness, suffixUrlsWithConsumerType } = useNavBarContext();
  const howItWorksOnClick = () => {
    setSideNavOpen(null);
    window.location.assign(
      `https://www.raylo.com/${consumerIsBusiness ? 'business' : ''}#how-it-works`,
    );
  };

  return (
    <StyledQuickLinks>
      {!loggedIn && (
        <MenuItem
          onClick={themeHasFeature('homepage') ? '/' : howItWorksOnClick}
          label="How it works"
          icon="nav-recycle"
          isBold
          border
          as="div"
        />
      )}
      {isRayloTheme && !loggedIn && (
        <TextAccordion
          dataTestId="about-accordion"
          primaryColour={palette.charcoal[500]}
          data={[
            {
              title: 'About',
              body: (
                <MenuLinks
                  links={aboutSubMenuLinks.map((link) => ({
                    ...link,
                    link: suffixUrlsWithConsumerType(link.link),
                  }))}
                  LinkComponent={LinkComponent}
                />
              ),
            },
          ]}
          boldHeaders
          showDivider
          iconSize={16}
          headerBackgroundHoverColor={palette.charcoal[100]}
          isSubMenu
          iconWidth={3}
          iconPaddingLeft={0}
        />
      )}
      <div className={cn({ 'border-charcoal-200 border-t': loggedIn })}>
        <TextAccordion
          dataTestId="help-accordion"
          primaryColour={palette.charcoal[500]}
          data={[
            {
              title: 'Help',
              body: (
                <MenuLinks
                  links={helpSubMenuLinks.map((link) => ({
                    ...link,
                    link: suffixUrlsWithConsumerType(link.link),
                  }))}
                />
              ),
            },
          ]}
          boldHeaders
          showDivider
          iconSize={16}
          headerBackgroundHoverColor={palette.charcoal[100]}
          isSubMenu
          iconWidth={3}
          iconPaddingLeft={0}
        />
      </div>
      <div className="p-6">
        {isRayloTheme && !loggedIn && (
          <Button
            size="large"
            className="mb-6 w-full"
            onClick={() => window.location.assign(`${accountHomeUrl}/account`)}
          >
            Login
          </Button>
        )}
        <MediaObject
          media={
            <img
              data-testid="raylo-certified-img"
              src={`${ASSETS_SVGS_BASE_URL}/logo_b-corp.svg`}
              alt="B Corporation Certified Logo"
              width={36}
              height={60}
            />
          }
          mediaWrapperProps={{ className: 'shrink-0' }}
          variant="info"
          subtitle="Raylo is proud to be B Corporation Certified"
        />
      </div>
    </StyledQuickLinks>
  );
};

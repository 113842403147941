'use client';

import { useMemo, useState } from 'react';
import { Configure, Index, InstantSearch } from 'react-instantsearch';
import { Drawer } from '../Drawer/Drawer';
import { DrawerHeader } from '../Drawer/DrawerHeader';
import { useNavBarContext } from '../navbar/NavBarContext/NavBarContext';
import { SearchField } from '../SearchField/SearchField';
import { GlobalSearchQueryResults } from './GlobalSearchQueryResults';
import { GlobalSearchProductCards } from './GlobalSearchProductCards';
import {
  createClientInstantSearchAdapter,
  SEARCH_INDEX_PRODUCTS,
  SEARCH_INDEX_SEARCH_TERMS,
} from 'uibook/utils/search';
import { useGlobalSearchTracking } from './hooks/useGlobalSearchTracking';
import { useMerchantTheme } from '../MerchantTheme/MerchantTheme';

type GlobalSearchInstantSearchProps = {
  searchTerm: string;
};

const GlobalSearchInstantSearch = ({ searchTerm }: GlobalSearchInstantSearchProps) => {
  const { searchClientConfig } = useNavBarContext();

  const instantSearchAdapter = useMemo(
    () =>
      createClientInstantSearchAdapter({
        apiKey: searchClientConfig.apiKey,
        host: searchClientConfig.host,
      }),
    [searchClientConfig.apiKey, searchClientConfig.host],
  );

  return (
    <InstantSearch
      searchClient={instantSearchAdapter.searchClient}
      future={{
        preserveSharedStateOnUnmount: true,
      }}
    >
      <Index indexName={SEARCH_INDEX_SEARCH_TERMS}>
        <Configure query={searchTerm} />
        <GlobalSearchQueryResults />
      </Index>
      <Index indexName={SEARCH_INDEX_PRODUCTS}>
        <Configure query={searchTerm} />
        <GlobalSearchProductCards />
      </Index>
    </InstantSearch>
  );
};

export const GlobalSearch = () => {
  const { isRayloTheme } = useMerchantTheme();
  const { searchIsOpen, handleCloseSearchOverlay, router, getSearchQueryUrl } = useNavBarContext();
  const { trackSearchEvent } = useGlobalSearchTracking();

  const [searchTerm, setSearchTerm] = useState('');

  const handleCloseSearch = () => {
    handleCloseSearchOverlay();
  };

  /** Handle changes to the search input field */
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  /**
   * Close the overlay and go to the PLP if the user presses "Enter" whilst focussing in the
   * keyboard.
   */
  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (searchTerm && event.key === 'Enter') {
      event.preventDefault();
      trackSearchEvent(searchTerm);
      router.push(getSearchQueryUrl(searchTerm));
      handleCloseSearchOverlay();
    }
  };

  return (
    <Drawer
      open={searchIsOpen}
      onClose={handleCloseSearch}
      sliderProps={{
        className: 'flex flex-col',
        'data-testid': 'global-search-drawer',
      }}
    >
      <DrawerHeader
        onClose={handleCloseSearch}
        title="Search"
        className="text-charcoal-500 bg-white"
        buttonProps={{
          'data-testid': 'global-search-close-cta',
        }}
      />
      <div className="flex h-[calc(100dvh-5.0625rem)] flex-col">
        <form>
          <SearchField
            name="q"
            placeholder={
              !isRayloTheme ? 'Search for products...' : 'Search for products, brands...'
            }
            value={searchTerm}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            className="mx-6 flex-shrink-0"
            title="Search"
            /** Important, as we want the search input to auto-focus when the drawer is opened */
            autoFocus
            enterKeyHint="search"
            data-testid="global-search-input"
          />
        </form>
        <GlobalSearchInstantSearch searchTerm={searchTerm} />
      </div>
    </Drawer>
  );
};

import type { ImageLoaderProps } from 'next/image';

export const PRODUCT_CARD_IMAGE_SIZES = {
  mobile: {
    width: 80,
    height: 140,
  },
  desktop: {
    width: 210,
    height: 192,
  },
  productDetailsPage: {
    width: 340,
    height: 340,
  },
  recommendations: {
    width: 230,
    height: 230,
  },
} as const;

/**
 * An image loader which is compatible with `next/image`, which applies transforms to the IMGIX
 * image, based on the params passed in.
 *
 * It can also be used by calling it directly with the `src`, `width`, and `quality` params.
 *
 * @url https://nextjs.org/docs/pages/api-reference/next-config-js/images#imgix
 */
export const imgixLoader = ({ src, width, quality }: ImageLoaderProps): string => {
  if (!src) {
    return '';
  }

  try {
    const url = new URL(src);
    const params = url.searchParams;
    params.set('auto', params.getAll('auto').join(',') || 'format');
    params.set('w', params.get('w') || width.toString());
    params.set('q', params.get('q') || (quality || 80).toString());

    return url.href;
  } catch (error) {
    console.error(error);
    return src;
  }
};

/**
 * A non-Next.js implementation of the `getImageProps()` method from `next/image`. This can be used
 * if the component is being rendered outside of Next.js, as the Next.js version does extra work
 * (such as adding priority images to the <head> tag of the page)
 */
export const getReactImageProps = (
  imgProps: React.ComponentPropsWithoutRef<'img'> & ImageLoaderProps & { priority?: boolean },
): { props: React.ComponentPropsWithoutRef<'img'> } => {
  return {
    props: {
      alt: imgProps.alt,
      width: imgProps.width,
      height: imgProps.height,
      src: imgixLoader({ src: imgProps.src, width: imgProps.width, quality: imgProps.quality }),
      sizes: imgProps.sizes,
      loading: 'lazy',
      decoding: 'async',
      ...(imgProps.priority ? { fetchpriority: 'high' } : {}),
    },
  };
};

type GetProductCardImageUrlProps = {
  src?: string;
  device: keyof typeof PRODUCT_CARD_IMAGE_SIZES;
};

/**
 * Small wrapper for applying consistent transforms to product images (including removing the
 * background, and forcing the image to maintain an aspect ratio)
 */
export const getProductCardImageUrl = ({ src, device }: GetProductCardImageUrlProps): string => {
  if (!src) {
    return '';
  }

  const { width, height } = PRODUCT_CARD_IMAGE_SIZES[device];

  const url = new URL(src);

  /**
   * Apply the following transforms to the image
   *
   * @url https://docs.imgix.com/apis/url
   */
  Object.entries({
    fm: 'avif',
    fit: 'fill',
    fill: 'solid',
    'fill-color': '00FFFFFF',
    trim: 'color',

    /**
     * Lock in the aspect ratio, as this is a fixed size image, but the `imgixLoader` doesn't take a
     * height param so we need to set it here
     */
    ar: `${width}:${height}`,
  }).map(([key, value]) => url.searchParams.set(key, value));

  return url.toString();
};

type GetProductCardImageSrcSetProps = GetProductCardImageUrlProps & {
  quality: number;
};

/**
 * `next/image` likes to create a wide range of `srcSet` values, but we only want to use the initial
 * width and double the width, as we know that the image will be displayed at that width on the
 * page. Mobile is only displayed at the `PRODUCT_CARD_IMAGE_SIZES.mobile.width` width, and desktop
 * is only displayed at the `PRODUCT_CARD_IMAGE_SIZES.desktop.width` width.
 *
 * This allows us to reduce the amount of HTML rendered for the PLP page, as we only generate the
 * exact srcSet values that are needed.
 */
export const getProductCardImageSrcSet = ({
  src,
  device,
  quality,
}: GetProductCardImageSrcSetProps) => {
  if (!src) {
    return '';
  }

  const initialWidth = PRODUCT_CARD_IMAGE_SIZES[device].width;

  /** Generate `1x` and `2x` image urls */
  return [initialWidth, initialWidth * 2]
    .map((width) => {
      return [
        imgixLoader({
          src: getProductCardImageUrl({ src, device }),
          width: width,
          quality: quality,
        }),
        `${width}w`,
      ].join(' ');
    })
    .join(', ');
};

/**
 * Formats the image URL by removing the query parameters related to image trimming.
 *
 * @param image - The image URL to be formatted.
 * @returns The formatted image URL.
 */
export const formatImageUrl = (image: string | undefined) => {
  return image?.replace('?trim-auto', '')?.replace('?trim=auto', '');
};

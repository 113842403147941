'use client';

import { cva, VariantProps } from 'class-variance-authority';
import { PolymorphicComponentPropWithRef } from '../../types/polymorphicComponent.types';
import { cn } from '../../utils/cn';
import { Children, cloneElement, PropsWithChildren } from 'react';
import { useMerchantTheme } from '../MerchantTheme/MerchantTheme';
import { Theme } from 'uibook-themes';

/** Generate type-safe className props using `class-variance-authority` */
const buttonClassNames = cva('inline-flex items-center font-medium', {
  variants: {
    variant: {
      filled: 'bg-blue-500 text-white hover:bg-blue-300 focus:bg-blue-200 focus:outline-none',
      outlined:
        'outline-charcoal-400 text-charcoal-500 focus:text-charcoal-400 focus:outline-charcoal-300 bg-transparent outline outline-1 outline-offset-[-1px] hover:outline-2 hover:-outline-offset-2 focus:-outline-offset-2',
      text: 'hover:underline',
    },
    size: {
      small: 'type-fineprint',
      medium: 'type-body2',
      large: 'type-body1',
    },
    disabled: {
      true: 'pointer-events-none',
    },
    /** This is only used for the `text` variant */
    color: {
      blue: 'text-blue-500 hover:text-blue-400 focus:text-blue-300',
      pink: 'text-pink-300 hover:text-pink-200 focus:text-pink-100',
      charcoal: 'text-charcoal-500 hover:text-blue-400 focus:text-blue-300',
    },
  },
  compoundVariants: [
    {
      variant: ['filled', 'outlined'],
      className: 'justify-center px-4 text-center uppercase tracking-wider',
    },
    {
      variant: ['filled', 'outlined'],
      size: 'small',
      className: 'min-h-8 py-2',
    },
    {
      variant: ['filled', 'outlined'],
      size: 'medium',
      className: 'min-h-12 py-3.5',
    },
    {
      variant: ['filled', 'outlined'],
      size: 'large',
      className: 'min-h-14 py-4',
    },
    {
      variant: 'filled',
      disabled: true,
      className: 'bg-charcoal-100 text-charcoal-300',
    },
    {
      variant: 'outlined',
      disabled: true,
      className: 'outline-charcoal-300 text-charcoal-300 outline-1 outline-offset-0',
    },
    {
      variant: 'text',
      disabled: true,
      className: 'text-charcoal-300',
    },
  ],
  defaultVariants: {
    variant: 'filled',
    size: 'medium',
  },
});

const flexButtonClassNames = cva('', {
  variants: {
    variant: {
      filled:
        'hover:bg-tp-flex-orange-500 focus:bg-tp-flex-orange-600 bg-tp-flex-orange-500 hocus:outline-tp-flex-orange-500',
      outlined:
        'hover:bg-tp-flex-sky-500 bg-tp-flex-sky-500 focus:bg-tp-flex-sky-600 hocus:outline-tp-flex-sky-500 outline-transparent',
      text: 'text-tp-flex-sky-500 hover:text-tp-flex-sky-500 focus:text-tp-flex-sky-600',
    },
    size: {
      small: '',
      medium: '',
      large: '',
    },
    disabled: {
      true: '',
      false: '',
    },
  },
  compoundVariants: [
    {
      variant: ['filled', 'outlined'],
      className:
        'hocus:outline hocus:outline-1 hocus:-outline-offset-1 hocus:shadow-[0_0_0_2px_#fff_inset] font-brand-alpha rounded-full font-medium normal-case tracking-normal',
    },
    {
      variant: ['filled', 'outlined'],
      size: 'small',
      className: 'min-h-6 px-4 py-1.5 text-[0.8125rem]',
    },
    {
      variant: ['filled', 'outlined'],
      size: 'medium',
      className: 'min-h-10 px-6 py-2.5 text-[0.9375rem]',
    },
    {
      variant: ['filled', 'outlined'],
      size: 'large',
      className: 'min-h-12 px-9 py-3 text-[1.0625rem]',
    },
    {
      variant: ['filled', 'outlined'],
      className: 'hocus:text-white text-white',
    },
    {
      variant: ['filled', 'outlined'],
      disabled: true,
      className:
        'bg-charcoal-100 text-charcoal-300 outline-charcoal-300 outline outline-2 -outline-offset-2',
    },
    {
      variant: 'text',
      disabled: true,
      className: 'text-charcoal-300',
    },
  ],
  defaultVariants: {
    variant: 'filled',
    size: 'medium',
  },
});

type ButtonCvaProps = VariantProps<typeof buttonClassNames>;

const DEFAULT_ELEMENT = 'button';

type ButtonProps<C extends React.ElementType = typeof DEFAULT_ELEMENT> =
  PolymorphicComponentPropWithRef<
    C,
    {
      /**
       * The size of the button
       *
       * @default 'medium'
       */
      size?: ButtonCvaProps['size'];

      /**
       * Set to true if the element is disabled. For anchor elements, `disabled` is not a valid HTML
       * prop, but we still want to be able to style it as disabled, so we use this prop.
       *
       * @default false
       */
      disabled?: ButtonCvaProps['disabled'];
    } & (
      | {
          /**
           * The variant of the button
           *
           * @default 'filled'
           */
          variant?: ButtonCvaProps['variant'];

          /**
           * The color of the text for the `text` variant. It is not used for the `filled` or
           * `outlined` variants.
           */
          color?: never;
        }
      | {
          /**
           * The variant of the button
           *
           * @default 'filled'
           */
          variant: 'text';

          /**
           * The color of the text for the `text` variant
           *
           * @default 'blue'
           */
          color?: ButtonCvaProps['color'];

          /**
           * Optional start icon to go to the left of the text
           *
           * ```tsx
           * <Button variant="text" startIcon={<IconCustomBackArrow />}>
           *   Text
           * </Button>;
           * ```
           *
           * You can also override the default width of the icon by passing a className to the icon,
           * otherwise the width will be based on the `size` of the button.
           *
           * ```tsx
           * <Button variant="text" startIcon={<IconCustomBackArrow className="w-6" />}>
           *   Text
           * </Button>;
           * ```
           */
          startIcon?: React.ReactNode;

          /**
           * Optional end icon to go to the left of the text
           *
           * ```tsx
           * <Button variant="text" endIcon={<IconCustomBackArrow />}>
           *   Text
           * </Button>;
           * ```
           *
           * You can also override the default width of the icon by passing a className to the icon,
           * otherwise the width will be based on the `size` of the button.
           *
           * ```tsx
           * <Button variant="text" endIcon={<IconCustomBackArrow className="w-6" />}>
           *   Text
           * </Button>;
           * ```
           */
          endIcon?: React.ReactNode;
        }
    )
  >;

const BaseButton = <C extends React.ElementType = typeof DEFAULT_ELEMENT>({
  as,
  variant,
  size,
  color,
  className,
  startIcon,
  endIcon,
  children,
  ...buttonProps
}: ButtonProps<C>) => {
  /**
   * By using the `as` prop, we can render the component as an `a` tag instead of a `button`, which
   * allows us to re-use all of the styles and logic for both `a` and `button` elements.
   *
   * ```tsx
   * <Button as="a" href="/link">
   *   My Account
   * </Button>;
   * ```
   *
   * However, it doesn't stop there, it's also possible to render the component as a `NextLink` from
   * `next/link`, which allows us to apply our own styles to the `NextLink` component, but retain
   * the `Link` functionality.
   *
   * ```tsx
   * <Button as={NextLink} href="/link">
   *   My Account
   * </Button>;
   * ```
   *
   * Whilst this is all great, it's important to note that the `as` prop doesn't mean that you
   * should use an element such as `span` or `div` as the `as` prop, as this will break the
   * semantics of the button. Only use `as` with elements that are valid for the button.
   */
  const Component = as || DEFAULT_ELEMENT;

  /** The `text` variant can include icons, so handle it slightly differently */
  if (variant === 'text') {
    return (
      <Component
        className={cn(
          buttonClassNames({
            /**
             * Apply the default variant to the Button instead of adding it in the `cva` method,
             * otherwise it will apply the color props to the non-text Button.
             */
            variant: variant,
            size,
            disabled: buttonProps.disabled,
            color: color ?? 'blue',
            className,
          }),
        )}
        {...buttonProps}
      >
        {startIcon && (
          <ButtonStartOrEndIcon className="mr-3" size={size}>
            {startIcon}
          </ButtonStartOrEndIcon>
        )}
        {children}
        {endIcon && (
          <ButtonStartOrEndIcon className="ml-3" size={size}>
            {endIcon}
          </ButtonStartOrEndIcon>
        )}
      </Component>
    );
  }

  /** If it's the `filled` or `outlined` variant, we can handle it normally (without any icons) */
  return (
    <Component
      className={cn(buttonClassNames({ variant, size, disabled: buttonProps.disabled, className }))}
      {...buttonProps}
    >
      {children}
    </Component>
  );
};

/**
 * Global Button which can be used for buttons, links, and NextLink components. It has a number of
 * variants, sizes, and colors to choose from.
 *
 * - `variant`: `filled`, `outlined`, `text`
 *
 *   - Default: `filled`
 * - `size`: `small`, `medium`, `large`
 *
 *   - Default: `medium`
 * - `color`: `blue`, `pink`, `charcoal` (only used for the `text` variant)
 *
 *   - Default: `blue`
 * - `disabled`: `true`, `false`
 * - `startIcon`: `ReactNode`
 * - `endIcon`: `ReactNode`
 * - `as`: `React.ElementType`
 * - `className`: `string`
 *
 * @themeable this component may be styled differently depending on the merchant theme
 *
 * ### Using the `variant` prop
 *
 * ```tsx
 * <Button>Filled Button (default)</Button>
 *
 * <Button variant="filled">Filled Button</Button>
 * <Button variant="outlined">Outlined Button</Button>
 * <Button variant="text">Text Button</Button>
 * ```
 * ### Using the `size` prop
 *
 * ```tsx
 * <Button>Medium Button (default)</Button>
 *
 * <Button size="small">Small Button</Button>
 * <Button size="medium">Medium Button</Button>
 * <Button size="large">Large Button</Button>
 * ```
 *
 * ### Using the `color` prop
 *
 * This prop is only used for the `text` variant
 *
 * ```tsx
 * <Button variant="text">Blue Text Button (default)</Button>
 *
 * <Button variant="text" color="blue">Blue Text Button</Button>
 * <Button variant="text" color="pink">Pink Text Button</Button>
 * <Button variant="text" color="charcoal">Charcoal Text Button</Button>
 * ```
 *
 * ### Using the `as` prop
 *
 * Example as a `button` element
 *
 * ```tsx
 * <Button>Text</Button>
 * ```
 *
 * Example as an `a` element
 *
 * ```tsx
 * <Button as="a" href="/">Text</Button>
 * ```
 * Example as a `NextLink` element
 *
 * ```tsx
 * <Button as={NextLink} href="/">Text</Button>
 * ```
 */
export const Button = <C extends React.ElementType = typeof DEFAULT_ELEMENT>(
  props: ButtonProps<C>,
) => {
  const { themeName } = useMerchantTheme();

  if (themeName === Theme.FLEX) {
    return (
      <BaseButton
        {...props}
        className={cn(
          flexButtonClassNames({
            variant: props.variant,
            size: props.size,
            disabled: props.disabled,
            className: props.className,
          }),
        )}
      />
    );
  }

  return <BaseButton {...props} />;
};

type ButtonStartOrEndIconProps = PropsWithChildren<{
  size: ButtonCvaProps['size'];
  className?: string;
}>;

/** Small wrapper function to add the default width className to the icon */
function ButtonStartOrEndIcon({ size = 'medium', className, children }: ButtonStartOrEndIconProps) {
  return Children.map(Children.toArray(children), (child) => {
    const childElement = child as React.ReactElement;
    return cloneElement(childElement, {
      /**
       * Set the default width of the icon, this can be overridden by adding a `className` to the
       * icon.
       *
       * ```tsx
       * <Button variant="text" startIcon={<IconCustomBackArrow className="w-6" />}>
       *   Button Text
       * </Button>;
       * ```
       */
      className: cn(
        {
          'w-4': size === 'small',
          'w-5': size === 'medium',
          'w-6': size === 'large',
        },
        /**
         * Apply the className from when the `<ButtonStartOrEndIcon />` component is used (see usage
         * above in the `<Button />` component)
         */
        className,
        /**
         * Apply the className from the icon itself, this allows the user to override the default
         *
         * ```tsx
         * <Button variant="text" startIcon={<IconCustomBackArrow className="text-blue-500" />}>
         *   Button Text
         * </Button>;
         * ```
         */
        childElement.props.className,
      ),
    });
  });
}

/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 *
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production. Learn more
 * about it here:
 * https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
  '\n  fragment VariantCostSummaryInitialFields on PricePlanInitialCostSummary {\n    totalAmount {\n      valueAfterTax {\n        value\n      }\n      valueBeforeTax {\n        value\n      }\n    }\n  }\n\n  fragment VariantCostSummaryRecurringFields on PricePlanRecurringCostSummary {\n    totalAmount {\n      valueAfterTax {\n        value\n      }\n      valueBeforeTax {\n        value\n      }\n    }\n    insuranceAmount {\n      valueAfterTax {\n        value\n      }\n      valueBeforeTax {\n        value\n      }\n    }\n  }\n':
    types.VariantCostSummaryInitialFieldsFragmentDoc,
  '\n  query ProductsRepoCustomerSpecificPricing($ids: [ID!], $merchantId: ID!, $checkoutToken: String) {\n    variants(forCheckout: true, ids: $ids, checkoutToken: $checkoutToken, merchantId: $merchantId) {\n      id\n      costSummaries(merchantId: $merchantId) {\n        id\n        recurring {\n          ...VariantCostSummaryRecurringFields\n        }\n      }\n    }\n  }\n':
    types.ProductsRepoCustomerSpecificPricingDocument,
  '\n  query ProductsRepoCustomerSpecificLowestCostSummaryPaged(\n    $ids: [ID!]\n    $merchantId: ID!\n    $checkoutToken: String\n    $first: Int!\n    $after: String\n  ) {\n    variantsConnection(\n      forCheckout: true\n      ids: $ids\n      checkoutToken: $checkoutToken\n      merchantId: $merchantId\n      after: $after\n      first: $first\n    ) {\n      nodes {\n        id\n        lowestCostSummary(merchantId: $merchantId) {\n          id\n          recurring {\n            ...VariantCostSummaryRecurringFields\n          }\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n':
    types.ProductsRepoCustomerSpecificLowestCostSummaryPagedDocument,
  '\n  query ProductsRepoGetCustomerDataWithPreApprovalAndUpgrades {\n    customer {\n      id\n      firstName\n      lastName\n      email\n      organizations {\n        id\n        name\n      }\n      # @TODO migrate this to use ordersConnection, but the complexity is too much so it will need splitting out\n      # https://xylofi.atlassian.net/browse/DEV-3083\n      # eslint-disable-next-line @graphql-eslint/no-deprecated\n      orders {\n        id\n        organization {\n          id\n        }\n        items {\n          id\n          subscription {\n            id\n            arrearsAmount {\n              valueInSubunit\n            }\n            upgrade {\n              eligible\n              checkout {\n                id\n                token\n                state\n              }\n            }\n            activeAsset {\n              id\n              variant {\n                id\n                product {\n                  id\n                  displayName\n                }\n              }\n            }\n          }\n        }\n      }\n      preApproval {\n        checkout {\n          id\n          token\n        }\n        recurringTaxableAmount {\n          valueAfterTax {\n            formattedValue\n            value\n          }\n          valueBeforeTax {\n            formattedValue\n            value\n          }\n        }\n        successful\n      }\n    }\n  }\n':
    types.ProductsRepoGetCustomerDataWithPreApprovalAndUpgradesDocument,
  '\n  query ProductsRepoGetDeliveryDates($ids: [ID!], $merchantId: ID!) {\n    variants(forCheckout: true, ids: $ids, merchantId: $merchantId) {\n      id\n      available(merchantId: $merchantId)\n      deliveryExpectedBetween(merchantId: $merchantId) {\n        max\n        min\n      }\n    }\n  }\n':
    types.ProductsRepoGetDeliveryDatesDocument,
  '\n  query ProductsRepoGetProductByVariantSlug($merchantId: ID!, $slugs: [String!]) {\n    variants(merchantId: $merchantId, slugs: $slugs, forCheckoutInput: { onlyAvailable: false }) {\n      id\n      product {\n        id\n        canAddTradeIn\n        displayName\n        specifications\n        keyFeatures\n        optionTypes {\n          id\n          slug\n          displayName\n          position\n        }\n        category {\n          id\n          displayName\n        }\n        manufacturer {\n          id\n          displayName\n        }\n        overview\n        variants(forCheckoutInput: { onlyAvailable: false }, merchantId: $merchantId) {\n          id\n          displayName\n          slug\n          subtitle\n          specifications\n          position\n          available(merchantId: $merchantId)\n          condition\n          images {\n            id\n            alt\n            position\n            tag\n            title\n            url\n          }\n          optionValues {\n            id\n            displayName\n            raw\n            position\n            optionType {\n              id\n              slug\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.ProductsRepoGetProductByVariantSlugDocument,
  '\n  query ProductsRepoGetPaginatedProductDetails($merchantId: ID!, $first: Int, $after: String) {\n    productsConnection(\n      forCheckoutInput: { onlyAvailable: false }\n      merchantId: $merchantId\n      first: $first\n      after: $after\n    ) {\n      nodes {\n        available\n        id\n        displayName\n        variantConditions\n        position\n        manufacturer {\n          id\n          displayName\n        }\n        category {\n          id\n          displayName\n        }\n        keyFeatures\n        variants(forCheckoutInput: { onlyAvailable: false }, merchantId: $merchantId) {\n          id\n          images {\n            id\n            url\n          }\n          displayName\n          available(merchantId: $merchantId)\n          condition\n          position\n          slug\n          optionValues {\n            id\n            raw\n            displayName\n            optionType {\n              id\n              slug\n            }\n          }\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n':
    types.ProductsRepoGetPaginatedProductDetailsDocument,
  '\n  query ProductsRepoGetPaginatedProductLowestCostSummaries(\n    $merchantId: ID!\n    $first: Int\n    $after: String\n  ) {\n    productsConnection(\n      forCheckoutInput: { onlyAvailable: false }\n      merchantId: $merchantId\n      first: $first\n      after: $after\n    ) {\n      nodes {\n        id\n        lowestCostSummary(merchantId: $merchantId) {\n          id\n          termLength\n          recurring {\n            ...VariantCostSummaryRecurringFields\n          }\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n':
    types.ProductsRepoGetPaginatedProductLowestCostSummariesDocument,
  '\n  query ProductsRepoGetPaginatedProductsForNav($merchantId: ID!, $first: Int, $after: String) {\n    productsConnection(\n      forCheckoutInput: { onlyAvailable: true }\n      merchantId: $merchantId\n      first: $first\n      after: $after\n    ) {\n      nodes {\n        id\n        available\n        category {\n          id\n          displayName\n        }\n        position\n        displayName\n        manufacturer {\n          id\n          displayName\n        }\n        variants(forCheckoutInput: { onlyAvailable: true }, merchantId: $merchantId) {\n          id\n          position\n          displayName\n          slug\n          available\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n':
    types.ProductsRepoGetPaginatedProductsForNavDocument,
  '\n  query ProductsRepoGetVariantSlugs($merchantId: ID!) {\n    variants(forCheckoutInput: { onlyAvailable: false }, merchantId: $merchantId) {\n      id\n      slug\n      product {\n        id\n        position\n      }\n    }\n  }\n':
    types.ProductsRepoGetVariantSlugsDocument,
  '\n  query ProductsRepoGetAccountIntercom {\n    customer {\n      id\n      email\n      messagingDetails {\n        intercom {\n          appId\n          webUserHash\n          userId\n        }\n      }\n    }\n  }\n':
    types.ProductsRepoGetAccountIntercomDocument,
  '\n  query ProductsRepoVariantPricePlans($ids: [ID!], $merchantId: ID!) {\n    variants(forCheckout: true, merchantId: $merchantId, ids: $ids) {\n      id\n      costSummaries(merchantId: $merchantId) {\n        id\n        includesInsurance\n        termLength\n        recurring {\n          ...VariantCostSummaryRecurringFields\n        }\n        initial {\n          ...VariantCostSummaryInitialFields\n        }\n        feeSummary {\n          nonReturnFee {\n            formattedValue\n          }\n        }\n      }\n    }\n  }\n':
    types.ProductsRepoVariantPricePlansDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 *
 * @example
 *   ```ts
 *   const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 *   ```
 *
 *   The query argument is unknown!
 *   Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function gql(
  source: '\n  fragment VariantCostSummaryInitialFields on PricePlanInitialCostSummary {\n    totalAmount {\n      valueAfterTax {\n        value\n      }\n      valueBeforeTax {\n        value\n      }\n    }\n  }\n\n  fragment VariantCostSummaryRecurringFields on PricePlanRecurringCostSummary {\n    totalAmount {\n      valueAfterTax {\n        value\n      }\n      valueBeforeTax {\n        value\n      }\n    }\n    insuranceAmount {\n      valueAfterTax {\n        value\n      }\n      valueBeforeTax {\n        value\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment VariantCostSummaryInitialFields on PricePlanInitialCostSummary {\n    totalAmount {\n      valueAfterTax {\n        value\n      }\n      valueBeforeTax {\n        value\n      }\n    }\n  }\n\n  fragment VariantCostSummaryRecurringFields on PricePlanRecurringCostSummary {\n    totalAmount {\n      valueAfterTax {\n        value\n      }\n      valueBeforeTax {\n        value\n      }\n    }\n    insuranceAmount {\n      valueAfterTax {\n        value\n      }\n      valueBeforeTax {\n        value\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function gql(
  source: '\n  query ProductsRepoCustomerSpecificPricing($ids: [ID!], $merchantId: ID!, $checkoutToken: String) {\n    variants(forCheckout: true, ids: $ids, checkoutToken: $checkoutToken, merchantId: $merchantId) {\n      id\n      costSummaries(merchantId: $merchantId) {\n        id\n        recurring {\n          ...VariantCostSummaryRecurringFields\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ProductsRepoCustomerSpecificPricing($ids: [ID!], $merchantId: ID!, $checkoutToken: String) {\n    variants(forCheckout: true, ids: $ids, checkoutToken: $checkoutToken, merchantId: $merchantId) {\n      id\n      costSummaries(merchantId: $merchantId) {\n        id\n        recurring {\n          ...VariantCostSummaryRecurringFields\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function gql(
  source: '\n  query ProductsRepoCustomerSpecificLowestCostSummaryPaged(\n    $ids: [ID!]\n    $merchantId: ID!\n    $checkoutToken: String\n    $first: Int!\n    $after: String\n  ) {\n    variantsConnection(\n      forCheckout: true\n      ids: $ids\n      checkoutToken: $checkoutToken\n      merchantId: $merchantId\n      after: $after\n      first: $first\n    ) {\n      nodes {\n        id\n        lowestCostSummary(merchantId: $merchantId) {\n          id\n          recurring {\n            ...VariantCostSummaryRecurringFields\n          }\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ProductsRepoCustomerSpecificLowestCostSummaryPaged(\n    $ids: [ID!]\n    $merchantId: ID!\n    $checkoutToken: String\n    $first: Int!\n    $after: String\n  ) {\n    variantsConnection(\n      forCheckout: true\n      ids: $ids\n      checkoutToken: $checkoutToken\n      merchantId: $merchantId\n      after: $after\n      first: $first\n    ) {\n      nodes {\n        id\n        lowestCostSummary(merchantId: $merchantId) {\n          id\n          recurring {\n            ...VariantCostSummaryRecurringFields\n          }\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function gql(
  source: '\n  query ProductsRepoGetCustomerDataWithPreApprovalAndUpgrades {\n    customer {\n      id\n      firstName\n      lastName\n      email\n      organizations {\n        id\n        name\n      }\n      # @TODO migrate this to use ordersConnection, but the complexity is too much so it will need splitting out\n      # https://xylofi.atlassian.net/browse/DEV-3083\n      # eslint-disable-next-line @graphql-eslint/no-deprecated\n      orders {\n        id\n        organization {\n          id\n        }\n        items {\n          id\n          subscription {\n            id\n            arrearsAmount {\n              valueInSubunit\n            }\n            upgrade {\n              eligible\n              checkout {\n                id\n                token\n                state\n              }\n            }\n            activeAsset {\n              id\n              variant {\n                id\n                product {\n                  id\n                  displayName\n                }\n              }\n            }\n          }\n        }\n      }\n      preApproval {\n        checkout {\n          id\n          token\n        }\n        recurringTaxableAmount {\n          valueAfterTax {\n            formattedValue\n            value\n          }\n          valueBeforeTax {\n            formattedValue\n            value\n          }\n        }\n        successful\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ProductsRepoGetCustomerDataWithPreApprovalAndUpgrades {\n    customer {\n      id\n      firstName\n      lastName\n      email\n      organizations {\n        id\n        name\n      }\n      # @TODO migrate this to use ordersConnection, but the complexity is too much so it will need splitting out\n      # https://xylofi.atlassian.net/browse/DEV-3083\n      # eslint-disable-next-line @graphql-eslint/no-deprecated\n      orders {\n        id\n        organization {\n          id\n        }\n        items {\n          id\n          subscription {\n            id\n            arrearsAmount {\n              valueInSubunit\n            }\n            upgrade {\n              eligible\n              checkout {\n                id\n                token\n                state\n              }\n            }\n            activeAsset {\n              id\n              variant {\n                id\n                product {\n                  id\n                  displayName\n                }\n              }\n            }\n          }\n        }\n      }\n      preApproval {\n        checkout {\n          id\n          token\n        }\n        recurringTaxableAmount {\n          valueAfterTax {\n            formattedValue\n            value\n          }\n          valueBeforeTax {\n            formattedValue\n            value\n          }\n        }\n        successful\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function gql(
  source: '\n  query ProductsRepoGetDeliveryDates($ids: [ID!], $merchantId: ID!) {\n    variants(forCheckout: true, ids: $ids, merchantId: $merchantId) {\n      id\n      available(merchantId: $merchantId)\n      deliveryExpectedBetween(merchantId: $merchantId) {\n        max\n        min\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ProductsRepoGetDeliveryDates($ids: [ID!], $merchantId: ID!) {\n    variants(forCheckout: true, ids: $ids, merchantId: $merchantId) {\n      id\n      available(merchantId: $merchantId)\n      deliveryExpectedBetween(merchantId: $merchantId) {\n        max\n        min\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function gql(
  source: '\n  query ProductsRepoGetProductByVariantSlug($merchantId: ID!, $slugs: [String!]) {\n    variants(merchantId: $merchantId, slugs: $slugs, forCheckoutInput: { onlyAvailable: false }) {\n      id\n      product {\n        id\n        canAddTradeIn\n        displayName\n        specifications\n        keyFeatures\n        optionTypes {\n          id\n          slug\n          displayName\n          position\n        }\n        category {\n          id\n          displayName\n        }\n        manufacturer {\n          id\n          displayName\n        }\n        overview\n        variants(forCheckoutInput: { onlyAvailable: false }, merchantId: $merchantId) {\n          id\n          displayName\n          slug\n          subtitle\n          specifications\n          position\n          available(merchantId: $merchantId)\n          condition\n          images {\n            id\n            alt\n            position\n            tag\n            title\n            url\n          }\n          optionValues {\n            id\n            displayName\n            raw\n            position\n            optionType {\n              id\n              slug\n            }\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ProductsRepoGetProductByVariantSlug($merchantId: ID!, $slugs: [String!]) {\n    variants(merchantId: $merchantId, slugs: $slugs, forCheckoutInput: { onlyAvailable: false }) {\n      id\n      product {\n        id\n        canAddTradeIn\n        displayName\n        specifications\n        keyFeatures\n        optionTypes {\n          id\n          slug\n          displayName\n          position\n        }\n        category {\n          id\n          displayName\n        }\n        manufacturer {\n          id\n          displayName\n        }\n        overview\n        variants(forCheckoutInput: { onlyAvailable: false }, merchantId: $merchantId) {\n          id\n          displayName\n          slug\n          subtitle\n          specifications\n          position\n          available(merchantId: $merchantId)\n          condition\n          images {\n            id\n            alt\n            position\n            tag\n            title\n            url\n          }\n          optionValues {\n            id\n            displayName\n            raw\n            position\n            optionType {\n              id\n              slug\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function gql(
  source: '\n  query ProductsRepoGetPaginatedProductDetails($merchantId: ID!, $first: Int, $after: String) {\n    productsConnection(\n      forCheckoutInput: { onlyAvailable: false }\n      merchantId: $merchantId\n      first: $first\n      after: $after\n    ) {\n      nodes {\n        available\n        id\n        displayName\n        variantConditions\n        position\n        manufacturer {\n          id\n          displayName\n        }\n        category {\n          id\n          displayName\n        }\n        keyFeatures\n        variants(forCheckoutInput: { onlyAvailable: false }, merchantId: $merchantId) {\n          id\n          images {\n            id\n            url\n          }\n          displayName\n          available(merchantId: $merchantId)\n          condition\n          position\n          slug\n          optionValues {\n            id\n            raw\n            displayName\n            optionType {\n              id\n              slug\n            }\n          }\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ProductsRepoGetPaginatedProductDetails($merchantId: ID!, $first: Int, $after: String) {\n    productsConnection(\n      forCheckoutInput: { onlyAvailable: false }\n      merchantId: $merchantId\n      first: $first\n      after: $after\n    ) {\n      nodes {\n        available\n        id\n        displayName\n        variantConditions\n        position\n        manufacturer {\n          id\n          displayName\n        }\n        category {\n          id\n          displayName\n        }\n        keyFeatures\n        variants(forCheckoutInput: { onlyAvailable: false }, merchantId: $merchantId) {\n          id\n          images {\n            id\n            url\n          }\n          displayName\n          available(merchantId: $merchantId)\n          condition\n          position\n          slug\n          optionValues {\n            id\n            raw\n            displayName\n            optionType {\n              id\n              slug\n            }\n          }\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function gql(
  source: '\n  query ProductsRepoGetPaginatedProductLowestCostSummaries(\n    $merchantId: ID!\n    $first: Int\n    $after: String\n  ) {\n    productsConnection(\n      forCheckoutInput: { onlyAvailable: false }\n      merchantId: $merchantId\n      first: $first\n      after: $after\n    ) {\n      nodes {\n        id\n        lowestCostSummary(merchantId: $merchantId) {\n          id\n          termLength\n          recurring {\n            ...VariantCostSummaryRecurringFields\n          }\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ProductsRepoGetPaginatedProductLowestCostSummaries(\n    $merchantId: ID!\n    $first: Int\n    $after: String\n  ) {\n    productsConnection(\n      forCheckoutInput: { onlyAvailable: false }\n      merchantId: $merchantId\n      first: $first\n      after: $after\n    ) {\n      nodes {\n        id\n        lowestCostSummary(merchantId: $merchantId) {\n          id\n          termLength\n          recurring {\n            ...VariantCostSummaryRecurringFields\n          }\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function gql(
  source: '\n  query ProductsRepoGetPaginatedProductsForNav($merchantId: ID!, $first: Int, $after: String) {\n    productsConnection(\n      forCheckoutInput: { onlyAvailable: true }\n      merchantId: $merchantId\n      first: $first\n      after: $after\n    ) {\n      nodes {\n        id\n        available\n        category {\n          id\n          displayName\n        }\n        position\n        displayName\n        manufacturer {\n          id\n          displayName\n        }\n        variants(forCheckoutInput: { onlyAvailable: true }, merchantId: $merchantId) {\n          id\n          position\n          displayName\n          slug\n          available\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ProductsRepoGetPaginatedProductsForNav($merchantId: ID!, $first: Int, $after: String) {\n    productsConnection(\n      forCheckoutInput: { onlyAvailable: true }\n      merchantId: $merchantId\n      first: $first\n      after: $after\n    ) {\n      nodes {\n        id\n        available\n        category {\n          id\n          displayName\n        }\n        position\n        displayName\n        manufacturer {\n          id\n          displayName\n        }\n        variants(forCheckoutInput: { onlyAvailable: true }, merchantId: $merchantId) {\n          id\n          position\n          displayName\n          slug\n          available\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function gql(
  source: '\n  query ProductsRepoGetVariantSlugs($merchantId: ID!) {\n    variants(forCheckoutInput: { onlyAvailable: false }, merchantId: $merchantId) {\n      id\n      slug\n      product {\n        id\n        position\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ProductsRepoGetVariantSlugs($merchantId: ID!) {\n    variants(forCheckoutInput: { onlyAvailable: false }, merchantId: $merchantId) {\n      id\n      slug\n      product {\n        id\n        position\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function gql(
  source: '\n  query ProductsRepoGetAccountIntercom {\n    customer {\n      id\n      email\n      messagingDetails {\n        intercom {\n          appId\n          webUserHash\n          userId\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ProductsRepoGetAccountIntercom {\n    customer {\n      id\n      email\n      messagingDetails {\n        intercom {\n          appId\n          webUserHash\n          userId\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function gql(
  source: '\n  query ProductsRepoVariantPricePlans($ids: [ID!], $merchantId: ID!) {\n    variants(forCheckout: true, merchantId: $merchantId, ids: $ids) {\n      id\n      costSummaries(merchantId: $merchantId) {\n        id\n        includesInsurance\n        termLength\n        recurring {\n          ...VariantCostSummaryRecurringFields\n        }\n        initial {\n          ...VariantCostSummaryInitialFields\n        }\n        feeSummary {\n          nonReturnFee {\n            formattedValue\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ProductsRepoVariantPricePlans($ids: [ID!], $merchantId: ID!) {\n    variants(forCheckout: true, merchantId: $merchantId, ids: $ids) {\n      id\n      costSummaries(merchantId: $merchantId) {\n        id\n        includesInsurance\n        termLength\n        recurring {\n          ...VariantCostSummaryRecurringFields\n        }\n        initial {\n          ...VariantCostSummaryInitialFields\n        }\n        feeSummary {\n          nonReturnFee {\n            formattedValue\n          }\n        }\n      }\n    }\n  }\n'];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;

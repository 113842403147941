import { gql } from '../generated';

export const GET_CUSTOMER_DATA = gql(/* GraphQL */ `
  query ProductsRepoGetCustomerDataWithPreApprovalAndUpgrades {
    customer {
      id
      firstName
      lastName
      email
      organizations {
        id
        name
      }
      # @TODO migrate this to use ordersConnection, but the complexity is too much so it will need splitting out
      # https://xylofi.atlassian.net/browse/DEV-3083
      # eslint-disable-next-line @graphql-eslint/no-deprecated
      orders {
        id
        organization {
          id
        }
        items {
          id
          subscription {
            id
            arrearsAmount {
              valueInSubunit
            }
            upgrade {
              eligible
              checkout {
                id
                token
                state
              }
            }
            activeAsset {
              id
              variant {
                id
                product {
                  id
                  displayName
                }
              }
            }
          }
        }
      }
      preApproval {
        checkout {
          id
          token
        }
        recurringTaxableAmount {
          valueAfterTax {
            formattedValue
            value
          }
          valueBeforeTax {
            formattedValue
            value
          }
        }
        successful
      }
    }
  }
`);

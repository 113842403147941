import { gql } from '../generated';

export const CUSTOMER_SPECIFIC_PRICING = gql(/* GraphQL */ `
  query ProductsRepoCustomerSpecificPricing($ids: [ID!], $merchantId: ID!, $checkoutToken: String) {
    variants(forCheckout: true, ids: $ids, checkoutToken: $checkoutToken, merchantId: $merchantId) {
      id
      costSummaries(merchantId: $merchantId) {
        id
        recurring {
          ...VariantCostSummaryRecurringFields
        }
      }
    }
  }
`);

export const CUSTOMER_SPECIFIC_LOWEST_COST_SUMMARY_PAGED = gql(/* GraphQL */ `
  query ProductsRepoCustomerSpecificLowestCostSummaryPaged(
    $ids: [ID!]
    $merchantId: ID!
    $checkoutToken: String
    $first: Int!
    $after: String
  ) {
    variantsConnection(
      forCheckout: true
      ids: $ids
      checkoutToken: $checkoutToken
      merchantId: $merchantId
      after: $after
      first: $first
    ) {
      nodes {
        id
        lowestCostSummary(merchantId: $merchantId) {
          id
          recurring {
            ...VariantCostSummaryRecurringFields
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`);

import { TAILWIND_BREAKPOINTS } from '../../../constants';
import { palette } from '../../../colors';
import { px2Rem } from '../../../utils/px2Rem';
import { css, styled } from 'styled-components';
import { themeVarToCssVar } from 'uibook-themes';

export const StyledNavBarWrapper = styled.div<{
  $hideNav: boolean;
  $calloutHeight: number;
  $fixNav: boolean;
  $navHeight: number;
}>`
  ${({ $hideNav, $calloutHeight, $fixNav, $navHeight }) => `
    ${
      $fixNav &&
      `
        position: fixed;
        ${$hideNav ? `top: -${$calloutHeight + $navHeight}px` : `top: -${$calloutHeight}px`};
      `
    }
    width: 100%;
    z-index: 998;
    left: 0;
    transition: ${$fixNav ? 'all 0.8s ease' : 'none'};

    // <li> styles needed to counteract some webflow styles
    li {
      min-height: auto !important;
      margin-bottom: 0 !important;
    }

    @media (min-width: ${TAILWIND_BREAKPOINTS.lg}px) {
      position: relative;
    }
  `};
`;
export const StyledNavBar = styled.header`
  .trustpilot-nav-img {
    margin-left: ${px2Rem(16)};
    display: none;

    @media (min-width: 480px) {
      display: block;
    }
  }

  .nav-primary-buttons {
    margin-left: auto;
    display: flex;
    align-items: center;
    cursor: pointer;

    button:not(.global-search-trigger) {
      margin-right: ${px2Rem(24)};

      &:last-of-type,
      &.my-account {
        margin-right: 0;
      }
    }
  }

  .quick-links {
    display: flex;
    align-items: center;
    gap: ${px2Rem(16)};

    @media (min-width: 360px) {
      gap: ${px2Rem(24)};
    }

    .dropdown {
      display: none;

      @media (min-width: ${TAILWIND_BREAKPOINTS.lg}px) {
        display: block;
      }
    }

    .text-link {
      display: none;
    }

    @media (min-width: ${TAILWIND_BREAKPOINTS.lg}px) {
      color: ${palette.white};
      font-size: ${px2Rem(14)};
      line-height: ${px2Rem(17)};
      gap: ${px2Rem(16)};

      a:not(.dropdown a):not(.login):not(.consumer-toggle-anchor) {
        text-decoration: none;
        color: inherit;
        padding: ${px2Rem(7.5)} ${px2Rem(8)};
        border-radius: ${px2Rem(4)};
        transition: background-color 0.3s ease-out;
        background-color: transparent;

        &:hover {
          background-color: ${themeVarToCssVar('nav-link-hover')};
        }

        &.text-link {
          display: block;
        }
      }

      .dropdown li {
        font-size: ${px2Rem(14)}!important;
        line-height: ${px2Rem(17)}!important;
      }
    }
  }
`;

export const StyledBurgerNavButton = styled.button<{ $sideNavOpen: boolean }>`
  ${({ $sideNavOpen }) => `
    appearance: none;
    background: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
    display: block;
    height: ${px2Rem(24)};
    width: ${px2Rem(24)};


    @media (min-width: ${TAILWIND_BREAKPOINTS.lg}px) {
      display: none;
    }

    span {
      background-color: ${palette.white};
      height: ${px2Rem(2)};
      width: ${px2Rem(22)};
      display: block;
      margin-bottom: ${px2Rem(5)};
      transform: scaleX(${$sideNavOpen ? 0 : 1});
      transition: transform 0.3s ease;

      &:nth-child(odd) {
        transform-origin: 100% 50%;
      }

      &:nth-child(even) {
        transform-origin: 0 50%;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  `}
`;

const navItemHoverStyles = css`
  border-radius: ${px2Rem(4)};
  padding: ${px2Rem(7.5)} ${px2Rem(8)};
  background-color: transparent;
  transition: background-color 0.3s ease-out;

  &:hover {
    background-color: ${themeVarToCssVar('nav-link-hover')};
  }
`;

export const StyledDesktopItems = styled.div`
  display: none;

  .burger-desktop {
    ${navItemHoverStyles};
    display: flex;
    align-items: center;
    cursor: pointer;
    align-items: center;
    font-size: ${px2Rem(14)};

    .burger-nav-button {
      display: block;
      height: ${px2Rem(16)};
      width: ${px2Rem(16)};

      span {
        width: ${px2Rem(16)};

        &:not(:nth-of-type(3)) {
          margin-bottom: ${px2Rem(3)};
        }
      }
    }

    p {
      font-size: ${px2Rem(14)};
      line-height: ${px2Rem(17)};
    }
  }

  p {
    margin: 0 0 0 ${px2Rem(8)};
  }

  .divider {
    background-color: ${themeVarToCssVar('nav-divider')};
    height: ${px2Rem(32)};
    width: ${px2Rem(1)};
    margin: 0 ${px2Rem(8)};
  }

  .category-links-desktop {
    display: flex;
    gap: ${px2Rem(8)};
  }

  @media (min-width: ${TAILWIND_BREAKPOINTS.lg}px) {
    display: flex;
    align-items: center;
    color: ${palette.white};
    padding: 0 ${px2Rem(16)} ${px2Rem(16)} ${px2Rem(16)};

    a,
    .desktop-link {
      text-decoration: none;
      color: inherit;
      font-size: ${px2Rem(14)};
      line-height: ${px2Rem(17)};
      ${navItemHoverStyles};
    }
  }
`;

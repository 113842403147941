import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import { CollectionFieldSchema } from 'typesense/lib/Typesense/Collection';
import { getThemeNameByMerchantId, Theme } from 'uibook-themes';

const getMerchantSuffix = () => {
  if (typeof process !== 'undefined') {
    if (process.env.NEXT_PUBLIC_RAYLO_MERCHANT_ID) {
      /**
       * - `raylo`
       * - `sony-playstation-flex`
       */
      const merchantName = getThemeNameByMerchantId(process.env.NEXT_PUBLIC_RAYLO_MERCHANT_ID);
      /** Don't add a suffix for the Raylo merchant, but do add for other white-label merchants */
      if (merchantName && merchantName !== Theme.RAYLO) {
        return merchantName;
      }
    }
  }
};

const getCollectionSuffix = () => {
  if (typeof process !== 'undefined') {
    if (process.env.REACT_APP_TYPESENSE_COLLECTION_SUFFIX) {
      return process.env.REACT_APP_TYPESENSE_COLLECTION_SUFFIX;
    }
    if (process.env.NEXT_PUBLIC_TYPESENSE_COLLECTION_SUFFIX) {
      return process.env.NEXT_PUBLIC_TYPESENSE_COLLECTION_SUFFIX;
    }
  }
};

const SUFFIXES = (() => {
  const suffixes: string[] = [];

  const merchantSuffix = getMerchantSuffix();
  if (merchantSuffix) {
    suffixes.push(merchantSuffix);
  }
  const collectionSuffix = getCollectionSuffix();
  if (collectionSuffix) {
    suffixes.push(collectionSuffix);
  }

  return suffixes;
})();

const suffixCollectionName = (collectionName: string) => {
  return [collectionName, ...SUFFIXES].join('_');
};

export const TYPESENSE_COLLECTIONS = {
  products: 'products',
  search_terms: 'search_terms',
  search_term_queries: 'search_term_queries',
  search_term_no_hits_queries: 'search_term_no_hits_queries',
} as const;

type TypesenseCollections = (typeof TYPESENSE_COLLECTIONS)[keyof typeof TYPESENSE_COLLECTIONS];

const TYPESENSE_AGGREGATIONS = {
  search_term_queries_aggregation: 'search_term_queries_aggregation',
  search_term_no_hits_queries_aggregation: 'search_term_no_hits_queries_aggregation',
} as const;

/** Typesense collection name for the `products` index */
export const SEARCH_INDEX_PRODUCTS = suffixCollectionName(TYPESENSE_COLLECTIONS.products);
/** Typesense collection name for the `search_terms` index */
export const SEARCH_INDEX_SEARCH_TERMS = suffixCollectionName(TYPESENSE_COLLECTIONS.search_terms);
/** Typesense collection name for the `search_term_queries` index */
export const SEARCH_INDEX_SEARCH_TERM_QUERIES = suffixCollectionName(
  TYPESENSE_COLLECTIONS.search_term_queries,
);
/** Typesense collection name for the `search_term_no_hits_queries` index */
export const SEARCH_INDEX_SEARCH_TERM_NO_HITS_QUERIES = suffixCollectionName(
  TYPESENSE_COLLECTIONS.search_term_no_hits_queries,
);

export const SEARCH_INDEX_SEARCH_TERM_QUERIES_AGGREGATION = suffixCollectionName(
  TYPESENSE_AGGREGATIONS.search_term_queries_aggregation,
);
export const SEARCH_INDEX_SEARCH_TERM_NO_HITS_QUERIES_AGGREGATION = suffixCollectionName(
  TYPESENSE_AGGREGATIONS.search_term_no_hits_queries_aggregation,
);

/** Named fields so that the schema matches the frontend consistently */
export const typesenseProductsFields = {
  position: 'position',
  category: 'category',
  make: 'make',
  model: 'model',
  condition: 'condition',
  keyFeatures: 'keyFeatures',
  monthlyprice: 'monthlyprice',
  monthlypriceBeforeTax: 'monthlypriceBeforeTax',
  priceGroup: 'priceGroup',
  priceGroupBeforeTax: 'priceGroupBeforeTax',
} as const;

/**
 * Schemas for Typesense, defined here mostly so that we can get a list of facets to use for the
 * products: `typesenseProductsFacets`.
 */
export const typesenseSchemas: Record<TypesenseCollections, CollectionFieldSchema[]> = {
  [TYPESENSE_COLLECTIONS.products]: [
    { name: typesenseProductsFields.position, type: 'int32', sort: true },
    { name: typesenseProductsFields.category, type: 'string', facet: true },
    { name: typesenseProductsFields.make, type: 'string', facet: true },
    { name: typesenseProductsFields.model, type: 'string', facet: true },
    { name: typesenseProductsFields.condition, type: 'string[]', facet: true },
    { name: typesenseProductsFields.keyFeatures, type: 'string[]', optional: true },
    { name: typesenseProductsFields.monthlyprice, type: 'float', facet: true, sort: true },
    { name: typesenseProductsFields.monthlypriceBeforeTax, type: 'float', facet: true },
    { name: typesenseProductsFields.priceGroup, type: 'int32', facet: true },
    { name: typesenseProductsFields.priceGroupBeforeTax, type: 'int32', facet: true },
  ],
  [TYPESENSE_COLLECTIONS.search_terms]: [
    {
      name: 'term',
      type: 'string',
      /**
       * Enable `infix` on the field, which allows infix-search to happen on the field. This is
       * useful for situations where you want to match substrings within a field, such as:
       *
       * - `phone` matching `iPhone`
       *
       * @url https://typesense.org/docs/latest/api/collections.html#field-parameters
       */
      infix: true,
    },
    {
      name: typesenseProductsFields.position,
      type: 'int32',
      optional: true,
    },
    {
      name: typesenseProductsFields.condition,
      type: 'string[]',
      optional: true,
      /**
       * Add `infix` here, as in `createClientInstantSearchAdapter`, the config for
       * `collectionSpecificSearchParameters[SEARCH_INDEX_SEARCH_TERMS]` uses `infix`, so all fields
       * in the search results should have `infix` enabled.
       */
      infix: true,
    },
    {
      name: typesenseProductsFields.keyFeatures,
      type: 'string[]',
      optional: true,
      /**
       * Add `infix` here, as in `createClientInstantSearchAdapter`, the config for
       * `collectionSpecificSearchParameters[SEARCH_INDEX_SEARCH_TERMS]` uses `infix`, so all fields
       * in the search results should have `infix` enabled.
       */
      infix: true,
    },
  ],
  [TYPESENSE_COLLECTIONS.search_term_queries]: [
    { name: 'q', type: 'string' },
    { name: 'count', type: 'int32' },
  ],
  [TYPESENSE_COLLECTIONS.search_term_no_hits_queries]: [
    { name: 'q', type: 'string' },
    { name: 'count', type: 'int32' },
  ],
};

/** List of facets to be used for the products */
export const typesenseProductsFacets = typesenseSchemas[TYPESENSE_COLLECTIONS.products]
  .filter((field) => field.facet)
  .map((field) => field.name);

const typesenseProductsQueryByFields = [
  typesenseProductsFields.model,
  typesenseProductsFields.category,
  typesenseProductsFields.make,
  typesenseProductsFields.condition,
  typesenseProductsFields.keyFeatures,
].join(',');

const typesenseProductsDefaultSortBy = `${typesenseProductsFields.position}:asc`;

type CreateClientInstantSearchAdapterProps = {
  apiKey: string;
  host: string;
};

export const createClientInstantSearchAdapter = ({
  apiKey,
  host,
}: CreateClientInstantSearchAdapterProps) =>
  new TypesenseInstantSearchAdapter({
    server: {
      apiKey: apiKey,
      nodes: [
        {
          host: host,
          port: 443,
          protocol: 'https',
        },
      ],
      /** Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching. */
      cacheSearchResultsForSeconds: 5 * 60,
    },
    collectionSpecificSearchParameters: {
      [SEARCH_INDEX_SEARCH_TERMS]: {
        query_by: [
          'term',
          typesenseProductsFields.condition,
          typesenseProductsFields.keyFeatures,
        ].join(','),
        sort_by: '_text_match:desc,position:asc',
        limit: 3,
        /**
         * Enable `infix` on the field, which allows infix-search to happen on the field. This is
         * useful for situations where you want to match substrings within a field, such as:
         *
         * - `phone` matching `iPhone`
         *
         * @url https://typesense.org/docs/latest/api/search.html#search-parameters
         */
        infix: 'always',
      },
      [SEARCH_INDEX_SEARCH_TERM_QUERIES]: {
        query_by: 'q',
        sort_by: 'count:desc',
        limit: 10,
      },
      [SEARCH_INDEX_PRODUCTS]: {
        query_by: typesenseProductsQueryByFields,
        sort_by: typesenseProductsDefaultSortBy,
        limit: 4,
      },
    },
  });

export const createProductsInstantSearchAdapter = ({
  apiKey,
  host,
}: CreateClientInstantSearchAdapterProps) =>
  new TypesenseInstantSearchAdapter({
    server: {
      apiKey: apiKey,
      nodes: [
        {
          host: host,
          port: 443,
          protocol: 'https',
        },
      ],
      /** Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching. */
      cacheSearchResultsForSeconds: 5 * 60,
    },
    additionalSearchParameters: {
      query_by: typesenseProductsQueryByFields,
      sort_by: typesenseProductsDefaultSortBy,
      limit: 250,
    },
  });

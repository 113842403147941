import isPropValid from '@emotion/is-prop-valid';
import { PropsWithChildren } from 'react';
import { StyleSheetManager } from 'styled-components';
import { GlobalUIStyles, useMerchantTheme } from 'uibook';

export const StyledComponentsProvider = ({ children }: PropsWithChildren) => {
  const { themeName } = useMerchantTheme();

  return (
    <StyleSheetManager shouldForwardProp={isPropValid}>
      <GlobalUIStyles themeName={themeName} />
      {children}
    </StyleSheetManager>
  );
};

import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconWalletOpen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    strokeWidth={1.5}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m3.085 2.89 10.799 3.068a3.72 3.72 0 0 1 2.559 3.456v10.375a2.027 2.027 0 0 1-2.567 2.14l-8.933-2.43a3.65 3.65 0 0 1-2.566-3.412V4.782a2.717 2.717 0 0 1 2.636-2.777h13.65a2.96 2.96 0 0 1 2.962 2.961v8.839a3.033 3.033 0 0 1-3.07 3.008h-2.112"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12.37 13.11a.37.37 0 1 1 0 .742.37.37 0 0 1 0-.741m9.256-3.702h-5.183"
    />
  </svg>
);
export const IconWalletOpen = memo(SvgIconWalletOpen);

import { FilterType } from '../FilterType/FilterType';
import { Icon, Typography, useMerchantTheme } from 'uibook';
import { useAppContext } from '@/hooks/useAppContext';
import { SortMenu } from '../SortMenu/SortMenu';
import { useCustomerContext } from '@/hooks/useCustomerContext';
import { VariantCondition } from '@/graphql/generated/graphql';
import { capitalize } from '@/utils/strings.utils';
import { useConsumerTypeContext } from '@/hooks/useConsumerTypeContext';
import { useMemo } from 'react';
import { FilterTotalRefinements } from '../FilterTotalRefinements/FilterTotalRefinements';
import { ClearRefinements } from '../ClearRefinements/ClearRefinements';
import { typesenseProductsFields } from 'uibook/utils/search';
import { BannerContent } from '@/components/AddTech/BannerContent';
import { SearchRefinementFacet } from '../FilterType/types';

export const FilterWrapper = () => {
  const { themeHasFeature } = useMerchantTheme();
  const { consumerIsBusiness } = useConsumerTypeContext();
  const { formattedPreApprovedAmount, preApprovedAmount, isAddingTech, isUpgrading } =
    useCustomerContext();
  const { setModalOpen } = useAppContext();

  const searchRefinementFacets: SearchRefinementFacet[] = useMemo(
    () => [
      ...(themeHasFeature('plp.filter.category')
        ? [
            {
              title: 'Category',
              attribute: typesenseProductsFields.category,
              type: 'list' as const,
              uiConfig: {
                renderWithIcon: true,
              },
              searchConfig: {
                transformItems: (items) => {
                  return items.map((item) => ({
                    ...item,
                    label: item.label === 'tvs' ? 'TVs' : capitalize(item.label),
                  }));
                },
                sortBy: ['count:desc'] as const,
              },
            } satisfies SearchRefinementFacet,
          ]
        : []),
      ...(themeHasFeature('plp.filter.make')
        ? [
            {
              title: 'Make',
              attribute: typesenseProductsFields.make,
              type: 'labels',
              searchConfig: {
                sortBy: ['count:desc'],
              },
            } satisfies SearchRefinementFacet,
          ]
        : []),
      ...(themeHasFeature('plp.filter.model')
        ? [
            {
              title: 'Model',
              attribute: typesenseProductsFields.model,
              type: 'list',
              uiConfig: {
                showFadeWhenMoreThan: 7,
                ...(themeHasFeature('plp.filter.category') && {
                  isVisible: (indexUiState) => !!indexUiState?.refinementList?.category?.length,
                }),
              },
              searchConfig: {
                sortBy: ['count:desc'],
              },
            } satisfies SearchRefinementFacet,
          ]
        : []),
      ...(themeHasFeature('plp.filter.condition')
        ? [
            {
              title: 'Condition',
              attribute: typesenseProductsFields.condition,
              type: 'list',
              uiConfig: {
                labelMap: {
                  [VariantCondition.New]: 'Brand New',
                  [VariantCondition.Refurbished]: 'Refurbished',
                },
              },
              searchConfig: {
                sortBy: ['name:asc'],
              },
            } satisfies SearchRefinementFacet,
          ]
        : []),
      ...(themeHasFeature('plp.filter.price')
        ? [
            {
              title: 'Monthly Price',
              attribute: consumerIsBusiness
                ? typesenseProductsFields.priceGroupBeforeTax
                : typesenseProductsFields.priceGroup,
              type: 'list',
              uiConfig: {
                showFadeWhenMoreThan: 7,
                showOnlyPreApprovalInfo: isAddingTech,
                preApprovedAmount: preApprovedAmount,
                sortByNumericalValue: true,
                isVisible: () => !isUpgrading,
              },
              searchConfig: {
                transformItems: (items) => {
                  return items.map((item) => ({
                    ...item,
                    label: `£${item.label} - £${parseInt(item.label) + 10}`,
                  }));
                },
                sortBy: ['name:asc'],
              },
            } satisfies SearchRefinementFacet,
          ]
        : []),
    ],
    [themeHasFeature, consumerIsBusiness, isAddingTech, preApprovedAmount, isUpgrading],
  );

  return (
    <div
      className="mb-[4.375rem] h-fit min-h-screen bg-white p-6 lg:mb-0 lg:min-h-0"
      data-testid="desktop-filter-wrapper"
    >
      <div className="pb-6 lg:hidden">
        <div className="border-charcoal-200 mb-6 flex items-center justify-between border-b pb-6">
          <Typography bold variant="h2" element="p" data-testid="filter-menu-copy">
            Filter & Sort
          </Typography>
          <button onClick={() => setModalOpen(null)} data-testid="filter-menu-icon">
            <Icon name="RayloDesignCustomClose" $height={16} $width={16} />
          </button>
        </div>
        <SortMenu />
      </div>

      <div className="flex flex-col gap-6">
        <div className="border-b-charcoal-200 hidden items-center gap-2 border-b pb-6 lg:flex">
          <Typography bold>Filter </Typography>
          <FilterTotalRefinements />
          <ClearRefinements className="ml-auto mt-0.5" />
        </div>
        {searchRefinementFacets.map((facet) => (
          <FilterType
            key={facet.attribute}
            facet={facet}
            preApprovalBanner={
              isAddingTech && (
                <BannerContent formattedAmount={formattedPreApprovedAmount} shortVersion={true} />
              )
            }
          />
        ))}
      </div>
    </div>
  );
};

import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconInfoFill = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 25" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 24.012c6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12s-12 5.372-12 12c0 6.627 5.373 12 12 12m1.2-6h-2.4v-7.2h2.4zm0-9.6h-2.4v-2.4h2.4z"
      clipRule="evenodd"
    />
  </svg>
);
export const IconInfoFill = memo(SvgIconInfoFill);

import { gql } from '../generated';

export const GET_INTERCOM = gql(/* GraphQL */ `
  query ProductsRepoGetAccountIntercom {
    customer {
      id
      email
      messagingDetails {
        intercom {
          appId
          webUserHash
          userId
        }
      }
    }
  }
`);

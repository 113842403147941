import { Alert, BulletList, RayloModal, Typography } from 'uibook';
import { useAppContext } from '../../hooks/useAppContext';
import { IAddTechModal } from './types';
import { useCloseModalOnRouterEvent } from '@/hooks/useCloseModalOnRouterEvent';
import { useFilterByPreApprovedAmount } from '../ProductFilter/hooks/useFilterByPreApprovedAmount';

export const AboutYourLimitModal = ({
  dataTestId,
  formattedAmount,
  preApprovedAmount,
}: IAddTechModal) => {
  const { modalOpen, setModalOpen } = useAppContext();
  const { preApprovedFilterEnabled, filterByPreApprovedAmount } = useFilterByPreApprovedAmount();

  useCloseModalOnRouterEvent(modalOpen === 'aboutYourLimit');

  return (
    <RayloModal
      modalOpen={modalOpen === 'aboutYourLimit'}
      dataTestId={dataTestId}
      handleCloseModal={() => setModalOpen(null)}
      primaryButtonOnClick={() => setModalOpen(null)}
      primaryButtonText="OKAY"
      secondaryButtonText={
        preApprovedFilterEnabled || !preApprovedAmount || preApprovedAmount <= 0
          ? undefined
          : 'FILTER BY PRE-APPROVED'
      }
      secondaryButtonOnClick={
        preApprovedFilterEnabled || !preApprovedAmount || preApprovedAmount <= 0
          ? undefined
          : () => {
              filterByPreApprovedAmount('enable');
              setModalOpen(null);
            }
      }
      title="About your limit"
    >
      <Typography className="mb-3">
        Your available pre-approved limit is <span className="font-bold">{formattedAmount}/mo</span>
      </Typography>
      <Typography className="mb-3">
        This is the maximum you have been approved to spend on extra tech per month.
      </Typography>
      <Alert variant="themed" icon={false} title="Your limit is based on">
        <BulletList
          items={['How long you have been a customer', 'Your payment history']}
          itemClassName="gap-2"
          bulletIcon="Ellipse24RayloBlue"
        />
      </Alert>
      <Typography variant="body2" className="text-charcoal-400 pt-3">
        Your available pre-approved limit can fluctuate and is subject to checks.
      </Typography>
    </RayloModal>
  );
};

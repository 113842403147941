import { useRouter } from 'next/router';
import { PropsWithChildren, useEffect } from 'react';
import { useAppContext } from '@/hooks/useAppContext';
import { setSessionStorage } from '@/utils/handleSessionStorage';
import { cn } from 'uibook';

type AppLayoutProps = PropsWithChildren<{
  dataTestId?: string;
}>;

export const AppLayout = ({ dataTestId, children }: AppLayoutProps) => {
  const router = useRouter();
  const { isShowingBanner } = useAppContext();

  const isProductDescriptionPage = router?.asPath.includes('products/');

  useEffect(() => {
    const subscriptionId = new URLSearchParams(window.location.search).get('originSubscriptionId');

    if (subscriptionId) {
      setSessionStorage('subscriptionId', subscriptionId);
    }
  }, []);

  return (
    <div
      data-testid={dataTestId}
      className={cn({
        'pb-20': isProductDescriptionPage && !isShowingBanner,
        'pb-[112px]': isProductDescriptionPage && isShowingBanner,
        'lg:pb-[112px]': isProductDescriptionPage,
      })}
    >
      {children}
    </div>
  );
};

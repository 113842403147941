import { px2Rem } from '../../utils/px2Rem';
import Icon from '../../elements/Icon';
import { cn, palette } from 'uibook';

type HeaderBannerProps = {
  onClick: () => void;
  children: React.ReactNode;
  className?: string;
};

export const HeaderBanner = ({ children, onClick, className }: HeaderBannerProps) => (
  <div className={cn('w-full bg-white px-6 py-4', className)}>
    <button
      className="flex w-full items-center justify-between gap-2 md:w-auto md:justify-start"
      onClick={onClick}
    >
      {children}
      <Icon name="Info" fill={palette.charcoal[400]} size={px2Rem(16)} />
    </button>
  </div>
);

import { cn, Typography } from 'uibook';
import { useAppContext } from '@/hooks/useAppContext';
import { track } from '@/integrations/segment/segmentTracking';
import { FilterTotalRefinements } from '../FilterTotalRefinements/FilterTotalRefinements';
import { IconFilter } from 'uibook-icons/solid/IconFilter';

type FilterSortTriggerProps = {
  visible: boolean;
};

export const FilterSortTrigger = ({ visible }: FilterSortTriggerProps) => {
  const { setModalOpen, merchantDomain } = useAppContext();

  const openModal = () => {
    setModalOpen('filters');
    track('Filter and Sort Clicked', {
      customer_type: 'new',
      merchant: merchantDomain,
    });
  };

  return (
    <button
      onClick={openModal}
      data-testid="mobile-filter-trigger"
      className={cn(
        'outline-charcoal-100 shadow-7 text-tv-primary size-12 fixed bottom-5 left-1/2 z-50 flex w-auto -translate-x-1/2 transform items-center justify-center gap-2 rounded-full bg-white p-3 px-4 py-2.5 outline outline-1 transition-opacity duration-700 hover:outline-2 md:w-[280px] lg:hidden',
        {
          'pointer-events-none opacity-0': !visible,
          'opacity-100': visible,
        },
      )}
    >
      <IconFilter className="w-6 shrink-0" />
      <Typography bold>Filter/Sort</Typography>
      <FilterTotalRefinements className="absolute -right-1 -top-1" />
    </button>
  );
};

import { useInstantSearch } from 'react-instantsearch';
import { typesenseProductsFields } from 'uibook/utils/search';
import { useConsumerTypeContext } from '@/hooks/useConsumerTypeContext';
import { useCustomerContext } from '@/hooks/useCustomerContext';
import { useMemo } from 'react';

/**
 * Small hook to allow us to toggle/enable the pre-approval filter anywhere in the app, providing
 * this is a child component of the `SearchProvider` component.
 */
export const useFilterByPreApprovedAmount = () => {
  const { consumerIsBusiness } = useConsumerTypeContext();
  const { preApprovedAmount } = useCustomerContext();
  const { setIndexUiState, indexUiState } = useInstantSearch();

  const attribute = consumerIsBusiness
    ? typesenseProductsFields.monthlypriceBeforeTax
    : typesenseProductsFields.monthlyprice;

  const preApprovedFilterEnabled = useMemo(() => {
    return !!indexUiState?.range?.[attribute];
  }, [attribute, indexUiState?.range]);

  const filterByPreApprovedAmount = (type: 'toggle' | 'enable') => {
    if (preApprovedAmount > 0) {
      if (type === 'toggle' && indexUiState.range?.[attribute]) {
        delete indexUiState.range[attribute];
        setIndexUiState(indexUiState);
        return;
      }

      setIndexUiState({
        ...indexUiState,
        range: {
          [attribute]: `:${preApprovedAmount}`,
        },
      });
    }
  };

  return { filterByPreApprovedAmount, preApprovedFilterEnabled };
};

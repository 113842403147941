import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconShieldPhone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    strokeWidth={1.5}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3.334 4.82v6.768a10.58 10.58 0 0 0 6.784 9.878l.925.356a2.67 2.67 0 0 0 1.914 0l.926-.356a10.58 10.58 0 0 0 6.783-9.878V4.821a1.32 1.32 0 0 0-.771-1.21A19.3 19.3 0 0 0 12 2a19.3 19.3 0 0 0-7.895 1.61 1.32 1.32 0 0 0-.771 1.21"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8.667 6h6.666v9.333H8.667z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 11.667a.333.333 0 1 1 0 .666.333.333 0 0 1 0-.666"
    />
  </svg>
);
export const IconShieldPhone = memo(SvgIconShieldPhone);

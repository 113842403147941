import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconConsole = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    strokeWidth={1.5}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4.513 5.312c1.4-.282 4.53-.598 7.487-.598s6.087.316 7.486.598c.345.07.633.294.785.611 1.194 2.487 1.964 7.548 1.93 9.72-.038 2.323-1.632 3.657-3.644 3.643-2.51-.017-3.231-3.647-3.765-4.877H9.208c-.534 1.23-1.256 4.86-3.765 4.877-2.012.013-3.606-1.32-3.644-3.643-.035-2.172.735-7.233 1.93-9.72a1.11 1.11 0 0 1 .784-.611m2.909 5.839V7.49m-1.83 1.83h3.662"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      d="M17.602 8.435a.37.37 0 0 1 0-.738m0 .738a.37.37 0 0 0 0-.738m-2.231 3.246a.37.37 0 0 1 0-.738m.001.738a.369.369 0 0 0 0-.738"
    />
  </svg>
);
export const IconConsole = memo(SvgIconConsole);

import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { cacheConfig } from './apolloCacheConfig';
import { RayloApolloClient } from 'core/apollo/RayloApolloClient';
import Cookies from 'js-cookie';

export const getUserClient = () =>
  new RayloApolloClient<NormalizedCacheObject>({
    authTokenType: 'user',
    graphql: {
      endpoint: process.env.NEXT_PUBLIC_API_ENDPOINT!,
      clientId: process.env.NEXT_PUBLIC_REACT_APP_CLIENT_ID!,
    },
    apollo: {
      ApolloClient: ApolloClient,
      clientConfig: {
        cache: new InMemoryCache(cacheConfig),
      },
      linkConfig: ['createClientAuthLink', 'createUserAuthLink', 'createHttpLink'],
    },
    cookies: {
      get: async (key) => Cookies.get(key),
    },
  }).createClient();

'use client';

import React from 'react';
import { StyledRayloModal } from './RayloModal.styles';
import { IRayloModal } from './RayloModal.types';
import Container from '../../elements/Container';
import { px2Rem } from '../../utils/px2Rem';
import Icon from '../../elements/Icon';
import Spacer from '../../elements/Spacer';
import { styled } from 'styled-components';
import { Modal } from '../../components/Modal/Modal';
import Header from '../../elements/Header';
import Divider from '../components/Divider';
import Copy from '../../elements/Copy';
import { palette } from '../../colors';
import LegacyButton from '../components/Button';

const HeaderSection = styled.div`
  padding: 0 ${px2Rem(20)};
`;
const ContentSection = styled.div`
  padding: 0 ${px2Rem(20)};
`;
const FooterSection = styled.div`
  padding: 0 ${px2Rem(20)};
`;

export const RayloModal = ({
  dataTestId,
  modalOpen,
  handleCloseModal,
  title,
  copy,
  children,
  primaryButtonText,
  primaryButtonOnClick,
  disablePrimaryButton,
  secondaryButtonText,
  secondaryButtonOnClick,
}: IRayloModal) => {
  return (
    <StyledRayloModal data-testid={dataTestId}>
      <Modal
        modalOpen={!!modalOpen}
        closeModal={handleCloseModal}
        desktopWidth={px2Rem(500)}
        mobileWidth={px2Rem(280)}
        offsetTop={84}
      >
        <Container backgroundColor={palette.white}>
          <HeaderSection>
            <Spacer height={24} />
            <Container flexRow>
              <Container
                alignRight
                onClick={handleCloseModal}
                cursorPointer
                dataTestId="modalClose"
              >
                <Icon name="RayloDesignCustomClose" $height={16} />
              </Container>
            </Container>
            <Spacer height={12} />
            {title && (
              <>
                <Container
                  centerVertical
                  styles={{
                    minHeight: `${px2Rem(29)}`,
                  }}
                >
                  <Header fontSize={24} level={2} color={palette.charcoal[500]}>
                    {title}
                  </Header>
                </Container>
                <Divider height={20} />
              </>
            )}
          </HeaderSection>
          <ContentSection>
            {copy?.map((text, i) => (
              <div key={i}>
                <Copy color={palette.charcoal[500]} lineHeight={20}>
                  {text}
                </Copy>
                {i < copy.length - 1 && <Spacer height={16} />}
              </div>
            ))}
            {children}
          </ContentSection>
          <FooterSection>
            {primaryButtonText && (
              <>
                <Spacer height={36} />
                <LegacyButton
                  dataTestId={`${dataTestId}-primaryButton`}
                  type="button"
                  onClick={primaryButtonOnClick}
                  disabled={disablePrimaryButton}
                >
                  {primaryButtonText}
                </LegacyButton>
              </>
            )}
            {secondaryButtonText && (
              <>
                <Spacer height={12} />
                <LegacyButton type="button" secondary onClick={secondaryButtonOnClick}>
                  {secondaryButtonText}
                </LegacyButton>
              </>
            )}
          </FooterSection>
          <Spacer height={24} />
        </Container>
      </Modal>
    </StyledRayloModal>
  );
};

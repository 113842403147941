import { createRef } from 'react';
import { cn } from '../../utils/cn';
import { IconDelete } from 'uibook-icons/solid/IconDelete';
import { IconSearch } from 'uibook-icons/solid/IconSearch';

type SearchFieldProps = React.ComponentPropsWithoutRef<'input'> & {
  /** Required field, as this component does not use a label */
  placeholder: string;
  /** `value` is required, as this component is a controlled input */
  value: string;
  /** `onChange` is required, as this component is a controlled input */
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  /**
   * Additional props to add to the wrapper `div` element. `className` is not passed here, instead
   * add the `className` as a prop directly to the component. All other props are valid.
   */
  wrapperProps?: Omit<React.ComponentPropsWithoutRef<'div'>, 'className'>;
  /** Optional `ref` for the wrapper `div` */
  ref?: React.RefObject<HTMLDivElement>;
  /** Optional `ref` for the `input` element */
  inputRef?: React.RefObject<HTMLInputElement>;
};

/**
 * Search input field, which includes a search icon and a clear button.
 *
 * This component is a controlled input field, so it requires a `value` and an `onChange` handler.
 *
 * Example:
 *
 * ```tsx
 * export const MyComponent = () => {
 *   const [value, setValue] = useState('');
 *
 *   const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
 *     setValue(event.target.value);
 *   };
 *
 *   return (
 *     <SearchField
 *       placeholder="Search for phones"
 *       value={value}
 *       onChange={handleInputChange}
 *     />
 *   );
 * };
 * ```
 */
export const SearchField = ({
  ref,
  inputRef: inputRefFromProps,
  className,
  wrapperProps,
  ...inputProps
}: SearchFieldProps) => {
  /** If an `inputRef` is not passed as a prop, create a ref to use instead */
  const inputRef = inputRefFromProps || createRef<HTMLInputElement>();

  /**
   * Handle clicking on the wrapper div, which should then focus the input. This won't be fired if
   * `wrapperProps.onClick` has a value.
   */
  const handleWrapperClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  /** Clear the input when the "X" is clicked */
  const handleClickClearSearch = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    /**
     * Combine the input element with an empty value, in-case anything other than `value` should be
     * used in the parent component.
     */
    if (inputRef.current) {
      inputProps.onChange({
        target: { ...inputRef.current, value: '' },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <div
      ref={ref}
      onClick={handleWrapperClick}
      data-testid="search-field"
      className={cn(
        'outline-charcoal-300 hover:outline-charcoal-500 focus-within:outline-tv-primary hover:focus-within:outline-tv-primary relative flex h-14 items-center bg-white outline outline-1 outline-offset-[-1px] focus-within:outline-2 focus-within:outline-offset-[-2px] hover:cursor-text hover:focus-within:outline-2',
        className,
      )}
      {...wrapperProps}
    >
      <span className="pl-3">
        <IconSearch className="text-charcoal-300 w-6" />
      </span>
      <input
        {...inputProps}
        ref={inputRef}
        className={cn(
          'type-body1 text-charcoal-500 placeholder:text-charcoal-400 block h-full w-full appearance-none border-none bg-inherit p-3 focus:outline-none [&::-webkit-search-cancel-button]:hidden',
        )}
        type="search"
      />
      {!!inputProps.value && (
        <button
          onClick={handleClickClearSearch}
          className="text-charcoal-300 hover:text-charcoal-500 focus:text-charcoal-500 px-3"
          aria-label="Clear search"
          data-testid="search-field-clear"
        >
          <IconDelete className="w-5" />
        </button>
      )}
    </div>
  );
};

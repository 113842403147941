import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconFilter = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M5.08 12H1.26m21.478 0H10.006m3.988 8.117H1.26m21.478 0H18.92M13.994 4.023H1.26m21.478 0H18.92"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M5.08 12.002a2.463 2.463 0 1 0 4.926 0 2.463 2.463 0 0 0-4.926 0Zm8.914 8.118a2.463 2.463 0 1 0 4.926 0 2.463 2.463 0 0 0-4.926 0Zm0-16.235a2.463 2.463 0 1 0 4.926 0 2.463 2.463 0 0 0-4.926 0Z"
    />
  </svg>
);
export const IconFilter = memo(SvgIconFilter);

'use client';

import { useState, useCallback, useRef, useEffect } from 'react';
import {
  StyledSideNavSubMenuWrapper,
  StyledSideNavSubMenu,
} from '../SideNavSubMenu/SideNavSubMenu.styles';
import { MenuItem } from '../utils/utilComponents';
import { loggedInMenuItems } from '../utils/utils';
import { BusinessAccountModal } from '../BusinessAccountModal/BusinessAccountModal';
import type { LoggedInData } from '../../../types/navData.types';
import { getActiveAccountSlug } from '../../../utils/getAccountSlug';
import { Typography } from '../../../foundations/Typography/Typography';
import { ASSETS_SVGS_BASE_URL } from '../../../constants';
import AccountSwitcherDropdown from '../../AccountSwitcherDropdown/AccountSwitcherDropdown';
import { cn } from '../../../utils/cn';
import { AccountStatusType, handleUpgradeExitModalOpenProps } from '../Navbar.types';

type LoggedInSubMenuProps = {
  loggedInData: LoggedInData;
  accountsUrl: string;
  LinkComponent?: React.ElementType;
  displayUpgradeModal?: boolean;
  handleUpgradeExitModalOpen?: (action: handleUpgradeExitModalOpenProps) => void;
  closeSideNav: () => void;
  overallAccountStatus?: AccountStatusType;
};
export const LoggedInSubMenu = ({
  loggedInData,
  accountsUrl,
  LinkComponent,
  handleUpgradeExitModalOpen,
  displayUpgradeModal,
  closeSideNav,
  overallAccountStatus,
}: LoggedInSubMenuProps) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const toggleAccountsButtonRef = useRef<HTMLButtonElement>(null);
  const [newBusinessModalOpen, setNewBusinessModalOpen] = useState(false);
  const [accountDropdownOpen, setAccountDropdownOpen] = useState(false);

  const loggedInMenu = loggedInData?.activeAccount
    ? loggedInMenuItems(
        getActiveAccountSlug(loggedInData.activeAccount),
        loggedInData.activeAccount.status,
      )
    : [];

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (
        !accountDropdownOpen ||
        !event.target ||
        !menuRef.current ||
        toggleAccountsButtonRef.current?.contains(event.target as Node)
      ) {
        return;
      }

      if (!menuRef.current.contains(event.target as Node)) {
        setAccountDropdownOpen(false);
      }
    },
    [accountDropdownOpen],
  );

  useEffect(() => {
    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [handleClick]);

  if (!loggedInData) return null;

  const loggedInMenuOnClick = (link: string) => () => {
    if (displayUpgradeModal && handleUpgradeExitModalOpen) {
      handleUpgradeExitModalOpen({ url: link });
    } else {
      window.location.replace(`${accountsUrl}${link}`);
    }
  };

  return (
    <>
      <StyledSideNavSubMenuWrapper>
        <StyledSideNavSubMenu $active $isGoingBack={false} $zIndex={3}>
          <div className="border-charcoal-200 relative flex items-center gap-4 border-b px-3 pb-9 pt-7">
            <div className="type-h4 flex h-12 w-12 items-center justify-center rounded-full bg-blue-500 font-bold text-white">
              {loggedInData.activeAccount?.name[0]}
            </div>
            <div className="flex flex-col gap-2">
              <Typography variant="h3" bold>
                {loggedInData.activeAccount?.name}
              </Typography>
              {loggedInData.accounts.length > 1 && (
                <button
                  className="flex items-center gap-2"
                  onClick={() => setAccountDropdownOpen((prev) => !prev)}
                  ref={toggleAccountsButtonRef}
                  data-testid="switch-account-button"
                >
                  <Typography className="text-blue-500 hover:underline">Switch account</Typography>
                  <Typography
                    variant="fineprint"
                    className={cn(
                      'text-charcoal-500 bg-charcoal-100 relative flex h-5 w-5 items-center justify-center rounded-full',
                      {
                        'after:absolute after:right-0 after:top-0 after:h-2 after:w-2 after:rounded-full after:border after:border-white':
                          !!overallAccountStatus,
                        'after:bg-error-500': overallAccountStatus === 'arrears',
                        'after:bg-success-500': overallAccountStatus === 'upgrade',
                      },
                    )}
                  >
                    {loggedInData.accounts.length}
                  </Typography>
                  <img
                    src={`${ASSETS_SVGS_BASE_URL}/chevron-down-blue.svg`}
                    alt="chevron down"
                    className={cn('origin-center transition-transform', {
                      'rotate-180': accountDropdownOpen,
                      'rotate-0': !accountDropdownOpen,
                    })}
                  />
                </button>
              )}
            </div>
            {loggedInData.activeAccount && (
              <AccountSwitcherDropdown
                dataTestId="account-switcher-dropdown"
                activeAccount={loggedInData.activeAccount}
                accounts={loggedInData.accounts}
                isOpen={accountDropdownOpen}
                setIsOpen={setAccountDropdownOpen}
                closeSideNav={closeSideNav}
                ref={menuRef}
              />
            )}
          </div>
          {loggedInMenu.map(({ name, link, badge, icon }) => (
            <MenuItem
              dataTestId={`submenu-item-${name}`}
              key={`category-${name}`}
              onClick={loggedInMenuOnClick(link)}
              label={name}
              icon={icon}
              badge={badge}
              isBold
              productsUrl={accountsUrl}
              LinkComponent={LinkComponent}
            />
          ))}
          <div className="border-charcoal-200 border-y">
            <MenuItem
              isBold
              label="Add a business account"
              onClick={() => setNewBusinessModalOpen(true)}
            />
          </div>
        </StyledSideNavSubMenu>
      </StyledSideNavSubMenuWrapper>
      <BusinessAccountModal
        newBusinessModalOpen={newBusinessModalOpen}
        setNewBusinessModalOpen={setNewBusinessModalOpen}
        addBusinessAccountOnClick={() => {
          closeSideNav();
          loggedInData.addBusinessAccountOnClick();
        }}
      />
    </>
  );
};

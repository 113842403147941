'use client';

import React from 'react';
import { ModalStyles } from './Modal.styles';
import { IModal } from './Modal.types';
import ReactModal from 'react-modal';

/**
 * For the `ReactModal`, tell it where the root of the app is, otherwise it will add `aria-hidden`
 * to every element on the page when it's open.
 */
const appElement = (() => {
  if (typeof process !== 'undefined' && process.env.NEXT_PUBLIC_MODAL_APP_ELEMENT) {
    return process.env.NEXT_PUBLIC_MODAL_APP_ELEMENT;
  }
  return '*';
})();
ReactModal.setAppElement(appElement);

export const Modal = ({
  dataTestId,
  modalOpen,
  closeModal,
  children,
  desktopWidth,
  mobileWidth,
  offsetTop,
  maxWidth,
  maxHeight,
}: IModal) => {
  return (
    <>
      <ModalStyles
        $isOpen={modalOpen}
        $desktopWidth={desktopWidth}
        $mobileWidth={mobileWidth}
        $offSetTop={offsetTop}
        $maxWidth={maxWidth}
        $maxHeight={maxHeight}
      />
      <div data-testid={dataTestId}>
        <ReactModal
          data-testid="base-modal"
          isOpen={modalOpen}
          onRequestClose={closeModal}
          contentLabel="My dialog"
          className="modal"
          overlayClassName="overlay"
          closeTimeoutMS={500}
        >
          {children}
        </ReactModal>
      </div>
    </>
  );
};

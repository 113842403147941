import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconInsurance = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    strokeWidth={1.5}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m7.333 11.158 2.178 3.094a.933.933 0 0 0 1.517.046L18 5.474"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4 1.999a1.333 1.333 0 0 0-1.333 1.333v8c0 4.279 6.43 9.04 8.616 10.454a1.31 1.31 0 0 0 1.434 0c2.186-1.415 8.616-6.175 8.616-10.454v-8A1.333 1.333 0 0 0 20 2z"
    />
  </svg>
);
export const IconInsurance = memo(SvgIconInsurance);

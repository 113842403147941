import { Modal } from 'uibook';
import { FilterWrapper } from '../FilterWrapper/FilterWrapper';
import { useAppContext } from '@/hooks/useAppContext';
import { track } from '@/integrations/segment/segmentTracking';
import { FilterModalButtons } from '../FilterModalButtons/FilterModalButtons';
import { useTotalRefinementCount } from '../hooks/useTotalRefinementCount';

export const FilterWrapperModal = () => {
  const { modalOpen, setModalOpen, merchantDomain } = useAppContext();
  const { totalRefinementCount } = useTotalRefinementCount();
  const isFiltering = totalRefinementCount > 0;

  if (modalOpen !== 'filters') {
    return null;
  }

  const closeModal = () => {
    setModalOpen(null);
    track('View Filter And Sort Results Clicked', {
      customer_type: 'new',
      merchant: merchantDomain,
    });
  };

  return (
    <>
      <Modal
        modalOpen
        closeModal={closeModal}
        offsetTop={64}
        mobileWidth="calc(100% - 40px)"
        desktopWidth="calc(100% - 40px)"
        maxWidth={500}
        maxHeight={isFiltering ? '80vh' : '90vh'}
      >
        <FilterWrapper />
      </Modal>
      <FilterModalButtons closeModal={closeModal} />
    </>
  );
};

import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconChevronLeftDouble = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M11.689 23.25.97 12.53a.75.75 0 0 1 0-1.06L11.689.75m11.25 22.5L12.22 12.53a.75.75 0 0 1 0-1.06L22.939.75"
    />
  </svg>
);
export const IconChevronLeftDouble = memo(SvgIconChevronLeftDouble);

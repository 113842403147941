import { IIcon } from './types';
import { StyledIcon } from './styles';
import { icons } from './Icons';

const Icon = ({ name, color, size, dataTestId, margin, fill, style, className }: IIcon) => {
  const icon = icons.find(({ iconName }) => iconName === name)?.icon;
  return (
    <StyledIcon
      color={color}
      size={size}
      fill={fill}
      data-testid={dataTestId}
      margin={margin}
      style={style}
      className={className}
    >
      {icon}
    </StyledIcon>
  );
};

export default Icon;

'use client';

import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import { StyledCalloutCarouselWrapper } from './CalloutCarousel.styles';
import { CalloutCarouselProps } from './CalloutCarousel.types';
import { forwardRef, useCallback, useEffect } from 'react';

const CalloutCarousel = forwardRef(function CalloutCarousel(
  { callouts }: CalloutCarouselProps,
  ref: React.Ref<HTMLDivElement>,
) {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      loop: true,
    },
    [Autoplay({ playOnInit: true, delay: 4000 })],
  );

  const resetAutoplay = useCallback(() => {
    const autoplay = emblaApi?.plugins()?.autoplay;
    if (!autoplay) return;
    autoplay.reset();
  }, [emblaApi]);

  const onPrevClick = useCallback(() => {
    emblaApi?.scrollPrev();
    resetAutoplay();
  }, [emblaApi, resetAutoplay]);

  const onNextClick = useCallback(() => {
    emblaApi?.scrollNext();
    resetAutoplay();
  }, [emblaApi, resetAutoplay]);

  const onMouseEnter = useCallback(() => {
    const autoplay = emblaApi?.plugins()?.autoplay;
    if (!autoplay) return;
    autoplay.stop();
  }, [emblaApi]);

  const onMouseLeave = useCallback(() => {
    const autoplay = emblaApi?.plugins()?.autoplay;
    if (!autoplay) return;
    autoplay.play();
  }, [emblaApi]);

  useEffect(() => {
    return () => {
      if (emblaApi) {
        emblaApi.destroy();
      }
    };
  }, [emblaApi]);

  return (
    <StyledCalloutCarouselWrapper
      data-testid="callout-carousel"
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="carousel-button-wrapper">
        <button className="slide-button prev" title="Previous slide" onClick={onPrevClick} />
        <div className="embla" ref={emblaRef}>
          <div className="slide-container">
            {callouts.map((callout, i) => (
              <div key={`callout-${i}`} className="slide">
                {callout}
              </div>
            ))}
          </div>
        </div>
        <button className="slide-button next" title="Next slide" onClick={onNextClick} />
      </div>
    </StyledCalloutCarouselWrapper>
  );
});

export default CalloutCarousel;

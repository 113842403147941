import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconPhoneDoubleCamera = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    strokeWidth={1.5}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3.333 22V4.667A2.667 2.667 0 0 1 6 2h12a2.667 2.667 0 0 1 2.667 2.667V22"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3.333 8.222h7.556a2.667 2.667 0 0 0 2.667-2.666V2M6.444 4.773a.338.338 0 0 0-.311.466.33.33 0 0 0 .311.201.32.32 0 0 0 .33-.329.33.33 0 0 0-.33-.338"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9.778 4.773a.338.338 0 0 0-.312.466.33.33 0 0 0 .312.201.32.32 0 0 0 .329-.329.33.33 0 0 0-.329-.338"
    />
  </svg>
);
export const IconPhoneDoubleCamera = memo(SvgIconPhoneDoubleCamera);

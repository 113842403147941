import { DEFAULT_THEME_VARIABLES } from './themes/default.theme';

/**
 * Match the decoded merchant IDs in the API, but remove the `Merchant-` from the start.
 *
 * For example,
 *
 * - `TWVyY2hhbnQtcmF5bG8=` -> (base64 decoded) -> `Merchant-raylo` -> (remove `Merchant-`) -> `raylo`
 * - `TWVyY2hhbnQtc29ueS1wbGF5c3RhdGlvbi1mbGV4` -> (base64 decoded) ->
 *   `Merchant-sony-playstation-flex` -> (remove `Merchant-`) -> `sony-playstation-flex`
 */
export enum Theme {
  FLEX = 'sony-playstation-flex',
  RAYLO = 'raylo',
}

export type HexColor = `#${string}`;

export type ThemePalette = Record<string, Record<string, HexColor> | HexColor>;

type DefaultThemeVariables = typeof DEFAULT_THEME_VARIABLES;

/**
 * This function is used to define the theme variables for a theme. It enforces two rules:
 *
 * 1. The theme variables must be a subset of the `DEFAULT_THEME_VARIABLES` object, which means that
 *    the keys in the theme being created must have a key in the `DEFAULT_THEME_VARIABLES` object.
 * 2. The key/value in the theme being created must not be the same as the key/value in the
 *    `DEFAULT_THEME_VARIABLES` object. For example, if the `primary` key in the
 *    `DEFAULT_THEME_VARIABLES` object is `#000000`, then the `primary` key in the theme being
 *    created cannot be `#000000`. This is to reduce the chance of a theme being created with the
 *    same values as the default theme, which should reduce the amount of code duplication.
 */
export const defineThemeVariables = <
  T extends Partial<Record<keyof DefaultThemeVariables, HexColor>>,
>(
  variables: T &
    /** #1 Forbid extra keys not in `DEFAULT_THEME_VARIABLES`: */
    Record<Exclude<keyof T, keyof DefaultThemeVariables>, never> & {
      /**
       * #2 For each key that is in T (and in DefaultThemeVariables), reject any literal that
       * matches DEFAULT_THEME_VARIABLES[K].
       */
      [K in keyof T & keyof DefaultThemeVariables]: T[K] extends DefaultThemeVariables[K]
        ? DefaultThemeVariables[K] extends T[K]
          ? never
          : T[K]
        : T[K];
    },
) => variables;

/* eslint-disable @typescript-eslint/no-explicit-any */
type ThrottleFunction = (...args: any[]) => void;

/**
 * Make sure a function only runs once every n amount of ms - first arg is the function, second arg
 * is the wait in ms. Use this to prevent a function from running too many times in a short period
 * of time, useful for things like scroll events and making these more performant.
 */
export function throttle<T extends ThrottleFunction>(
  func: T,
  wait: number,
): (...args: Parameters<T>) => void {
  let timeout: ReturnType<typeof setTimeout> | null = null;
  let lastArgs: Parameters<T> | null = null;

  return function (...args: Parameters<T>): void {
    if (!timeout) {
      // If there's no active timeout, execute the function
      func(...args);
      timeout = setTimeout(() => {
        // Clear timeout and check for queued arguments
        timeout = null;
        if (lastArgs) {
          func(...lastArgs);
          lastArgs = null;
        }
      }, wait);
    } else {
      // Save the latest arguments for execution after the throttle period
      lastArgs = args;
    }
  };
}

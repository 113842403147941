import React from 'react';
import { palette } from '../../../colors';
import Container from '../../../elements/Container';
import { px2Rem } from '../../../utils/px2Rem';
import { IDivider } from './Divider.types';

export const Divider = ({ dataTestId, height }: IDivider) => {
  return (
    <div>
      <Container
        dataTestId={dataTestId}
        noPadding
        height={px2Rem(1)}
        backgroundColor={palette.charcoal[200]}
        margin={`${px2Rem(height || 0)} 0`}
      />
    </div>
  );
};

export const TYPOGRAPHY_VARIANTS_LIST = [
  'h1',
  'h2',
  'h3',
  'h4',
  'body1',
  'body2',
  'fineprint',
] as const;

type TypographyVariantStyle = {
  fontSize: string | number;
  lineHeight: string | number;
};

export const px2Rem = (px: number) => `${px / 16}rem`;

export const TYPOGRAPHY_VARIANTS: Record<
  (typeof TYPOGRAPHY_VARIANTS_LIST)[number],
  TypographyVariantStyle
> = {
  h1: {
    fontSize: px2Rem(36),
    lineHeight: 1.2,
  },
  h2: {
    fontSize: px2Rem(24),
    lineHeight: 1.2,
  },
  h3: {
    fontSize: px2Rem(20),
    lineHeight: 1.2,
  },
  h4: {
    fontSize: px2Rem(18),
    lineHeight: 1.3,
  },
  body1: {
    fontSize: px2Rem(16),
    lineHeight: 1.4,
  },
  body2: {
    fontSize: px2Rem(14),
    lineHeight: 1.4,
  },
  fineprint: {
    fontSize: px2Rem(12),
    lineHeight: 1.4,
  },
};

import { RayloModal } from '../../../designSystem/RayloModal/RayloModal';

type BusinessAccountModalProps = {
  newBusinessModalOpen: boolean;
  setNewBusinessModalOpen: (open: boolean) => void;
  addBusinessAccountOnClick: () => void;
};

export const BusinessAccountModal = ({
  newBusinessModalOpen,
  setNewBusinessModalOpen,
  addBusinessAccountOnClick,
}: BusinessAccountModalProps) => {
  if (!newBusinessModalOpen) return null;

  return (
    <RayloModal
      dataTestId="new-business-modal"
      modalOpen={newBusinessModalOpen}
      handleCloseModal={() => setNewBusinessModalOpen(false)}
      title="Add a business account"
      copy={['To add a new business, you must choose a product and checkout.']}
      primaryButtonText="Choose a product"
      primaryButtonOnClick={() => {
        addBusinessAccountOnClick();
        setNewBusinessModalOpen(false);
      }}
      secondaryButtonText="Cancel"
      secondaryButtonOnClick={() => setNewBusinessModalOpen(false)}
    />
  );
};

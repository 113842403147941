import { useRayloCookiesContext } from '@/hooks/useRayloCookiesContext';
import { DemoModeBanner } from 'uibook';

export const DemoModeBannerWrapper = () => {
  const { cookieValues } = useRayloCookiesContext();

  if (!cookieValues.raylo_demoMode) {
    return null;
  }

  return <DemoModeBanner />;
};

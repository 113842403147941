import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconDeliveryTruck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    strokeWidth={1.5}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M23.25 13.5V6a1.5 1.5 0 0 0-1.5-1.5h-12A1.5 1.5 0 0 0 8.25 6v6m0 0V6h-3a4.5 4.5 0 0 0-4.5 4.5v6a1.5 1.5 0 0 0 1.5 1.5H3"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M.75 12h3a1.5 1.5 0 0 0 1.5-1.5V6m0 11.25a2.25 2.25 0 1 0 4.5 0 2.25 2.25 0 0 0-4.5 0m12 0a2.25 2.25 0 1 0 4.5 0 2.25 2.25 0 0 0-4.5 0M12 18h3"
    />
  </svg>
);
export const IconDeliveryTruck = memo(SvgIconDeliveryTruck);

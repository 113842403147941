'use client';

import { createContext, PropsWithChildren, useCallback, useContext, useMemo } from 'react';
import { themeFeatureExists, Theme } from 'uibook-themes';

type MerchantThemeContextProps = {
  themeName?: Theme;
};

export type MerchantThemeContextType = {
  /** The current theme name */
  themeName: Theme;
  /** If the theme is the Raylo theme */
  isRayloTheme: boolean;
  /** If the theme is a white-label merchant store theme */
  isRayloPayTheme: boolean;
  /** If the feature is enabled for the theme */
  themeHasFeature: (
    feature: Parameters<typeof themeFeatureExists>[1],
  ) => ReturnType<typeof themeFeatureExists>;
};

export const initialMerchantThemeContextState: MerchantThemeContextType = {
  themeName: Theme.RAYLO,
  isRayloTheme: true,
  isRayloPayTheme: false,
  themeHasFeature: (feature) => themeFeatureExists(Theme.RAYLO, feature),
};

export const MerchantThemeContext = createContext<MerchantThemeContextType>(
  initialMerchantThemeContextState,
);

export const MerchantThemeContextProvider = ({
  themeName: themeNameFromProps,
  children,
}: PropsWithChildren<MerchantThemeContextProps>) => {
  const themeName = themeNameFromProps ?? initialMerchantThemeContextState.themeName;

  const themeHasFeature = useCallback(
    (feature: Parameters<typeof themeFeatureExists>[1]) => {
      return themeFeatureExists(themeName, feature);
    },
    [themeName],
  );

  const value = useMemo(
    () => ({
      themeName: themeName,
      isRayloTheme: themeName === Theme.RAYLO,
      isRayloPayTheme: themeName !== Theme.RAYLO,
      themeHasFeature,
    }),
    [themeName, themeHasFeature],
  );

  return <MerchantThemeContext.Provider value={value}>{children}</MerchantThemeContext.Provider>;
};

export const useMerchantTheme = () => {
  return useContext(MerchantThemeContext);
};

import React, { forwardRef } from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import { Typography } from '../../foundations/Typography/Typography';
import { cn } from '../../utils/cn';
import { IconInfoFill } from 'uibook-icons/solid/IconInfoFill';
import { IconExclaimationCircleFilled } from 'uibook-icons/solid/IconExclaimationCircleFilled';
import { IconSuccess } from 'uibook-icons/solid/IconSuccess';

const defaultVariant = 'default';

export const alertVariantsConfig = {
  [defaultVariant]: 'border-charcoal-200 bg-white',
  info: 'border-blue-200 bg-blue-100',
  warning: 'border-warning-200 bg-warning-100',
  success: 'border-success-300 bg-success-100',
  error: 'border-error-200 bg-error-100',
  themed: 'border-tv-alert-border bg-tv-alert-fill',
};

/** Defines styles for the Alert component based on its variant.` */
const alertVariants = cva('relative flex w-full min-w-fit flex-col gap-3 rounded border p-4', {
  variants: {
    variant: alertVariantsConfig,
  },
  defaultVariants: {
    variant: defaultVariant,
  },
});

type AlertCvaProps = VariantProps<typeof alertVariants>;

/** Default icons for each alert variant */
const variantIcons: Record<NonNullable<AlertCvaProps['variant']>, React.ReactNode> = {
  [defaultVariant]: <IconInfoFill className="w-5 text-blue-500" />,
  info: <IconInfoFill className="w-5 text-blue-500" />,
  warning: <IconExclaimationCircleFilled className="text-warning-500 w-5" />,
  success: <IconSuccess className="text-success-500 w-5" />,
  error: <IconExclaimationCircleFilled className="text-error-500 w-5" />,
  themed: <IconInfoFill className="text-tv-icon-primary w-5" />,
};

type AlertIconComponentProps = {
  variant: AlertCvaProps['variant'];
  icon?: React.ReactNode | false;
};

/**
 * AlertIconComponent
 *
 * Renders the appropriate icon for the Alert component based on the `variant` or a custom `icon`
 * prop.
 *
 * @returns The rendered icon or null if no icon is specified.
 */
const AlertIconComponent = ({ variant, icon }: AlertIconComponentProps) => {
  if (icon === false) {
    return null;
  }
  const defaultIcon = variantIcons[variant ?? defaultVariant];
  return <div className="shrink-0">{icon ?? defaultIcon}</div>;
};

export type AlertProps = {
  /**
   * The variant of the alert, which determines its styling and default icon.
   *
   * @example
   *   <Alert variant="info" />;
   *
   * @default 'default'
   */
  variant?: AlertCvaProps['variant'];

  /**
   * The icon displayed on the left. If not provided, it defaults to the icon based on the variant.
   * Accepts:
   *
   * - `React.ReactNode` for a custom icon
   * - `false` to hide the icon
   *
   * @example
   *   // Custom icon
   *   <Alert icon={<CustomIcon />} />;
   *
   * @example
   *   // No icon
   *   <Alert icon={false} />;
   *
   * @default Uses the default icon for the specified variant.
   */
  icon?: React.ReactNode | false;

  /** The title of the alert, which is displayed in bold text. */
  title?: string;

  /**
   * Additional props to customize the `Typography` component used for the alert title.
   *
   * @example
   *   <Alert title="Alert Title" titleProps={{ className: 'text-lg text-blue-500' }} />;
   */
  titleProps?: React.ComponentPropsWithoutRef<typeof Typography>;

  /** The subtitle of the alert, displayed in regular text. */
  subtitle?: string;

  /**
   * Additional props to customize the `Typography` component used for the alert subtitle.
   *
   * @example
   *   <Alert subtitle="Alert Subtitle" subtitleProps={{ className: 'text-sm text-gray-600' }} />;
   */
  subtitleProps?: React.ComponentPropsWithoutRef<typeof Typography>;

  /** The body of the alert, which can include custom content (e.g. Link). */
  body?: React.ReactNode;

  /**
   * An optional action or interactive element displayed on the right. Typically used for close
   * buttons or custom actions.
   *
   * @example
   *   <Alert
   *     variant="info"
   *     action={
   *       <button>
   *         <IconClose className="text-charcoal-400 hover:text-charcoal-500 w-3" />
   *       </button>
   *     }
   *   />;
   */
  action?: React.ReactNode;

  /**
   * Additional props for the wrapper element around the action component.
   *
   * @example
   *   <Alert action={<button>Close</button>} actionWrapperProps={{ className: 'ml-2' }} />;
   */
  actionWrapperProps?: React.ComponentPropsWithoutRef<'div'>;
};

/**
 * Alert Component
 *
 * A customizable alert component with support for various variants, icons, title, subtitle, body
 * content, and actions.
 *
 * ### Props
 *
 * - **`variant`**: `default`, `info`, `warning`, `success`, `error`
 *
 *   - **Default**: `default`
 * - **`icon`**: `ReactNode`, `false`
 *
 *   - **Default**: Variant-specific icon
 * - **`title`**: `string`
 * - **`titleProps`**: Props for customizing the `Typography` component of the title.
 *
 *   - **Example**: `{ className: 'text-lg text-blue-500' }`
 * - **`subtitle`**: `string`
 * - **`subtitleProps`**: Props for customizing the `Typography` component of the subtitle.
 *
 *   - **Example**: `{ className: 'text-sm text-gray-600' }`
 * - **`body`**: `ReactNode`
 * - **`action`**: `ReactNode`
 *
 *   - **Example**: `<button>Close</button>`
 * - **`actionWrapperProps`**: Props for customizing the wrapper of the `action` component.
 *
 *   - **Example**: `{ className: 'ml-2' }`
 * - **`className`**: `string`
 *
 * ### Examples
 *
 * #### Using the `variant` prop
 *
 * ```tsx
 * <Alert variant="default" title="Default Alert" />
 * <Alert variant="info" title="Info Alert" />
 * <Alert variant="warning" title="Warning Alert" />
 * <Alert variant="success" title="Success Alert" />
 * <Alert variant="error" title="Error Alert" />
 * ```
 *
 * #### Using the `icon` prop
 *
 * ```tsx
 * <Alert icon={<CustomIcon />} title="Custom Icon Alert" />
 * <Alert icon={false} title="No Icon Alert" />
 * ```
 *
 * #### Using the `titleProps` and `subtitleProps`
 *
 * ```tsx
 * <Alert
 *   title="Custom Title"
 *   titleProps={{ className: 'text-lg text-blue-500' }}
 *   subtitle="Custom Subtitle"
 *   subtitleProps={{ className: 'text-sm text-gray-600' }}
 * />;
 * ```
 *
 * #### Using the `action` and `actionWrapperProps`
 *
 * ```tsx
 * <Alert action={<button>Close</button>} actionWrapperProps={{ className: 'ml-2' }} />;
 * ```
 *
 * #### Using the `body` prop
 *
 * ```tsx
 * <Alert body={<div>Additional custom content can go here.</div>} />;
 * ```
 */
export const Alert = forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'> & AlertProps>(
  (
    {
      className,
      variant,
      icon,
      title,
      titleProps,
      subtitleProps,
      subtitle,
      body,
      action,
      actionWrapperProps,
      children,
      ...props
    },
    ref,
  ) => (
    <div ref={ref} role="alert" className={cn(alertVariants({ variant }), className)} {...props}>
      <div className="flex items-start justify-between gap-3">
        <div className="flex gap-3">
          <AlertIconComponent variant={variant} icon={icon} />
          <div className="flex flex-col">
            {title && (
              <Typography variant="body2" bold {...titleProps}>
                {title}
              </Typography>
            )}
            {subtitle && (
              <Typography variant="body2" {...subtitleProps}>
                {subtitle}
              </Typography>
            )}
            {body}
          </div>
        </div>
        {action && (
          <div className="shrink-0" {...actionWrapperProps}>
            {action}
          </div>
        )}
      </div>
      {children}
    </div>
  ),
);
Alert.displayName = 'Alert';

'use client';

import { px2Rem } from '../../utils/px2Rem';
import { createGlobalStyle } from 'styled-components';

interface IModalStyles {
  $isOpen: boolean;
  $offSetTop?: number;
  $desktopWidth?: string;
  $mobileWidth?: string;
  $maxWidth?: number;
  $maxHeight?: string;
}

const ModalStyles = createGlobalStyle<IModalStyles>`

  .modal {
    position: fixed;
    top: ${({ $offSetTop }) => ($offSetTop ? px2Rem($offSetTop) : '1.25rem')};
    left: 50%;
    opacity: 0;
    transform: translate(-50%, 0%);
    overflow: auto;
     border-radius: 0;
    outline: none;
    max-height: ${({ $maxHeight }) => $maxHeight ?? '90vh'};
    max-width: ${({ $maxWidth }) => px2Rem($maxWidth ?? 480)};
    border-radius: 0.25rem !important;
    box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.05), 0 10px 15px -3px rgba(0, 0, 0, 0.1);
    transition: all 300ms cubic-bezier(0.5, 0, 0.15, 1) 380ms;
    width: ${({ $desktopWidth }) => $desktopWidth && $desktopWidth};

    @media (max-width: 375px) {
      border-radius: 0.25rem;
       width: ${({ $mobileWidth }) => $mobileWidth && $mobileWidth};
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(14, 14, 14, 0.6);
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms cubic-bezier(0.5, 0, 0.15, 1) 180ms;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }

  .ReactModal__Content.ReactModal__Content--after-open.modal {
    opacity: 1;
    top: ${({ $offSetTop }) => ($offSetTop ? px2Rem($offSetTop - 20) : '1.25rem')};
  }

  @media (max-width: 600px) {
    .modal {
      width: calc(100vw - 40px);
      border-radius: initial;
    }
  }
`;

export { ModalStyles };

import { styled } from 'styled-components';
import { px2Rem } from '../../utils/px2Rem';
import { getPaletteColorByDotNotation, palette, PaletteColorKeys } from '../../colors';
import { ASSETS_SVGS_BASE_URL, CSS_FONT_WEIGHT } from '../../constants';

const accountCircle = (
  $backgroundColor: PaletteColorKeys,
  $color: PaletteColorKeys,
  $letter?: string,
  $showAvatar?: boolean,
) => `
  content: ${$showAvatar ? '""' : `'${$letter}'`};
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${px2Rem(32)};
  height: ${px2Rem(32)};
  background-color: ${getPaletteColorByDotNotation($backgroundColor)};
  border-radius: ${px2Rem(100)};
  color: ${getPaletteColorByDotNotation($color)};
  font-weight: ${CSS_FONT_WEIGHT.vars.bold};
  font-size: ${px2Rem(14)};
  flex-shrink: 0;
  ${
    $showAvatar &&
    `
      background-image: url('${ASSETS_SVGS_BASE_URL}/avatar-icon.svg');
      background-size: ${px2Rem(16)};
      background-repeat: no-repeat;
      background-position: center;
    `
  }
`;

export const StyledAccountSwitcherToggle = styled.button<{
  $active: boolean;
  $letter?: string;
  $showAvatar?: boolean;
}>`
  ${({ $active, $letter, $showAvatar }) => `
    border: none;
    background: transparent;
    padding: 0;
    border-radius: ${px2Rem(100)};
    background-color: ${palette.blue[$active ? 400 : 500]};
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid ${palette.blue[400]};

    &:before {
      ${accountCircle('white', 'blue.500', $letter, $showAvatar)};
    }

    @media (min-width: 360px) {
      &:after {
        height: ${px2Rem(8)};
        width: ${px2Rem(30)};
        content: '';
        display: block;
        background-image: url('${ASSETS_SVGS_BASE_URL}/chevron-down-white.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: ${px2Rem(10)};
        transition: transform 0.3s ease;
        transform: rotate(${$active ? '180deg' : '0deg'});
        transform-origin: center;
      }
    }

    &:hover {
      background-color: ${palette.blue[400]};
    }
`};
`;

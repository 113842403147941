import Logo from './svgs/rayloLogo.svg';
import Twitter from './svgs/twitter.svg';
import Instagram from './svgs/instagram.svg';
import Facebook from './svgs/facebook.svg';
import CertifiedPhoneShield from './svgs/certifiedphoneshield.svg';
import CertifiedWarranty from './svgs/certifiedwarranty.svg';
import Trustpilot2 from './svgs/trustpilot.svg';
import PoweredByRayloPayLogo from './svgs/logo_powered-by-raylo-pay.svg';
import Info from './svgs/icon-custom-info.svg';
import Alert from './svgs/alert.svg';

export const icons = [
  {
    iconName: 'Logo',
    icon: <Logo />,
  },
  {
    iconName: 'Twitter',
    icon: <Twitter />,
  },
  {
    iconName: 'Instagram',
    icon: <Instagram />,
  },
  {
    iconName: 'Facebook',
    icon: <Facebook />,
  },
  {
    iconName: 'CertifiedPhoneShield',
    icon: <CertifiedPhoneShield />,
  },
  {
    iconName: 'CertifiedWarranty',
    icon: <CertifiedWarranty />,
  },
  {
    iconName: 'Trustpilot2',
    icon: <Trustpilot2 />,
  },
  {
    iconName: 'PoweredByRayloPayLogo',
    icon: <PoweredByRayloPayLogo />,
  },
  {
    iconName: 'Info',
    icon: <Info />,
  },
  {
    iconName: 'Alert',
    icon: <Alert />,
  },
] as const;

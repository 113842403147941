import { PropsWithChildren } from 'react';
import { ConsumerTypesEnum, MerchantThemeContextProvider, NavDataModel } from 'uibook';
import { CustomGrowthBookProvider } from './CustomGrowthBookProvider';
import { CookiesProvider } from 'react-cookie';
import { RayloCookiesProvider } from '@/hooks/useRayloCookiesContext';
import { RayloIFrameProvider } from '@/hooks/useRayloIFrameContext';
import { IntercomProvider } from 'react-use-intercom';
import { CustomApolloProvider } from './CustomApolloProvider';
import { ConsumerTypeProvider } from '@/hooks/useConsumerTypeContext';
import { CustomerProvider } from '@/hooks/useCustomerContext';
import { AppProvider } from '@/hooks/useAppContext';
import GlobalNavBar from '@/components/GlobalNavBar/GlobalNavBar';
import { AppLayout } from '@/components/Layouts/AppLayout';
import { StyledComponentsProvider } from './StyledComponentsProvider';
import { Theme } from 'uibook-themes';

export type RayloAppProps = {
  /**
   * `navData` is fetched in `getStaticProps` in the PLP and PDP pages, and is then and passed to
   * the `_app.tsx` component via the `pageProps`.
   *
   * `consumerType` is currently only set PDP and PLP pages. Any other pages, it will default to
   * `ConsumerTypesEnum.PERSONAL`.
   */
  navData?: NavDataModel;
  consumerType?: ConsumerTypesEnum;
  themeName?: Theme;
};

export const GlobalAppProvider = ({
  navData,
  consumerType,
  themeName,
  children,
}: PropsWithChildren<RayloAppProps>) => {
  return (
    <CustomGrowthBookProvider>
      <MerchantThemeContextProvider themeName={themeName}>
        <CookiesProvider>
          <RayloCookiesProvider>
            <RayloIFrameProvider>
              <IntercomProvider
                appId={process.env.NEXT_PUBLIC_INTERCOM_APP_ID}
                initializeDelay={2000}
              >
                <CustomApolloProvider>
                  <ConsumerTypeProvider consumerType={consumerType}>
                    <CustomerProvider>
                      <AppProvider>
                        <StyledComponentsProvider>
                          <GlobalNavBar navData={navData ?? { categories: [] }} />
                          <AppLayout>{children}</AppLayout>
                        </StyledComponentsProvider>
                      </AppProvider>
                    </CustomerProvider>
                  </ConsumerTypeProvider>
                </CustomApolloProvider>
              </IntercomProvider>
            </RayloIFrameProvider>
          </RayloCookiesProvider>
        </CookiesProvider>
      </MerchantThemeContextProvider>
    </CustomGrowthBookProvider>
  );
};

import { VariantCondition } from '../../types/navData.types';
import { Typography } from '../../foundations/Typography/Typography';
import { px2Rem } from '../../utils/px2Rem';
import { CSS_FONT_WEIGHT, TAILWIND_BREAKPOINTS } from '../../constants';
import { ColourSwatches } from '../ColourSwatches/ColourSwatches';
import { cn } from '../../utils/cn';
import Badge from '../Badge';
import SkeletonLoadingBlock from '../../designSystem/components/SkeletonLoadingBlock';
import { getImageProps as getNextImageProps } from 'next/image';
import Head from 'next/head';
import {
  formatImageUrl,
  getProductCardImageSrcSet,
  getProductCardImageUrl,
  getReactImageProps,
  imgixLoader,
  PRODUCT_CARD_IMAGE_SIZES,
} from '../../utils/images';
import { SearchProduct } from '../../types/search.types';

type ProductCardProps = {
  product: SearchProduct;
  displayPrice: string | number | null;
  priceSuffix?: string;
  isPriorityImage?: boolean;
  /**
   * The state of how the prices should be rendered
   *
   * - `displayed` - Display the price
   * - `loading` - Display a loading skeleton
   * - `hidden` - Don't render anything
   */
  priceRenderState: 'displayed' | 'loading' | 'hidden';
  /**
   * Set to true to display the card in the desktop format when on desktop viewports
   *
   * In reality, CSS Container Queries would be better suited here, but at the time of writing this,
   * our apps are not setup for them, and it's only recently become widely supported in browsers.
   */
  useGridLayoutOnDesktop?: boolean;
  /**
   * If rendering in Next.js, you should use `getImageProps` imported from `next/image`. If rending
   * elsewhere, you should use `getReactImageProps` from `uibook/utils/images`
   */
  getImageProps: typeof getNextImageProps | typeof getReactImageProps;
  /**
   * If rending in Next.js, set this to `true`. This allows us to add `preload` tags to the head of
   * the page, if it's a priority image.
   */
  isNextImage?: boolean;
};

export const ProductCard = ({
  product,
  displayPrice,
  isPriorityImage,
  priceSuffix,
  priceRenderState,
  getImageProps,
  isNextImage,
  useGridLayoutOnDesktop,
}: ProductCardProps) => {
  const imageSrc = formatImageUrl(product.variantImageUrl);

  const productCardImageSharedProps = {
    alt: product.model ?? '',
    loader: imgixLoader,
    priority: isPriorityImage,
    quality: 30,
  };

  /**
   * Use the `getImageProps` function to generate the props for the image tags. This can either be
   * the `getImageProps` from `next/image`, or the `getReactImageProps` function from
   * `uibook/utils/images`.
   *
   * @url https://nextjs.org/docs/pages/api-reference/components/image#getimageprops
   */
  const desktopImageProps = getImageProps({
    ...productCardImageSharedProps,
    width: PRODUCT_CARD_IMAGE_SIZES.desktop.width,
    height: PRODUCT_CARD_IMAGE_SIZES.desktop.height,
    src: getProductCardImageUrl({ src: imageSrc, device: 'desktop' }),
    sizes: `${PRODUCT_CARD_IMAGE_SIZES.desktop.width}px`,
  }).props;
  const mobileImageProps = getImageProps({
    ...productCardImageSharedProps,
    width: PRODUCT_CARD_IMAGE_SIZES.mobile.width,
    height: PRODUCT_CARD_IMAGE_SIZES.mobile.height,
    src: getProductCardImageUrl({ src: imageSrc, device: 'mobile' }),
    sizes: `${PRODUCT_CARD_IMAGE_SIZES.mobile.width}px`,
  }).props;

  /**
   * Use these srcSet values to override the `srcSet` values generated by `next/image`.
   *
   * `next/image` likes to create a wide range of `srcSet` values, but we only want to use the
   * initial width and double the width, as we know that the image will be displayed at that width
   * on the page. Mobile is only displayed at the `PRODUCT_CARD_IMAGE_SIZES.mobile.width` width, and
   * desktop is only displayed at the `PRODUCT_CARD_IMAGE_SIZES.desktop.width` width.
   *
   * This allows us to reduce the amount of HTML rendered for the PLP page, as we only generate the
   * exact srcSet values that are needed.
   */
  const mobileImageSrcSet = getProductCardImageSrcSet({
    src: imageSrc,
    device: 'mobile',
    quality: productCardImageSharedProps.quality,
  });
  const desktopImageSrcSet = getProductCardImageSrcSet({
    src: imageSrc,
    device: 'desktop',
    quality: productCardImageSharedProps.quality,
  });

  /** When running in Next.js, we want to add the priority image to the `preload` tag */
  const sharedPreload = {
    rel: 'preload',
    as: 'image',
    fetchPriority: 'high',
  } as const;
  const desktopMedia = `(min-width: ${TAILWIND_BREAKPOINTS.lg}px)`;
  const mobileMedia = `(max-width: ${TAILWIND_BREAKPOINTS.lg - 1}px)`;

  return (
    <>
      {isNextImage && isPriorityImage && (
        <Head>
          <link
            {...sharedPreload}
            imageSizes={desktopImageProps.sizes}
            media={desktopMedia}
            imageSrcSet={desktopImageSrcSet}
            data-testid="desktop-card-preload"
          />
          <link
            {...sharedPreload}
            imageSizes={mobileImageProps.sizes}
            media={mobileMedia}
            imageSrcSet={mobileImageSrcSet}
            data-testid="mobile-card-preload"
          />
        </Head>
      )}
      <div
        data-testid={`desktop-card-${product.variantSlug}`}
        className={cn(
          'shadow-card relative flex h-full w-full flex-col items-start justify-center bg-white py-4 pl-32 pr-3',
          {
            'lg:items-center lg:p-7': useGridLayoutOnDesktop,
          },
        )}
      >
        <div
          className={cn('pb-1', {
            'lg:pb-2': useGridLayoutOnDesktop,
          })}
        >
          <Typography
            bold
            className={cn('mr-1 inline !leading-[1.2]', {
              'lg:mr-0 lg:block lg:pb-2 lg:text-center': useGridLayoutOnDesktop,
            })}
          >
            {product.make}
          </Typography>
          <Typography
            bold
            className={cn('inline !leading-[1.2]', {
              'lg:block lg:text-center': useGridLayoutOnDesktop,
            })}
          >
            {product.model}
          </Typography>
        </div>
        {product.cardDisplaySpecification && (
          <Typography
            variant="body2"
            className={cn('!leading-[1.2]', { 'lg:text-center': useGridLayoutOnDesktop })}
          >
            {product.cardDisplaySpecification}
          </Typography>
        )}

        <div className="flex h-full flex-col justify-end">
          <div
            className={cn(
              'absolute left-4 top-1/2 flex h-[var(--img-h)] w-[var(--img-w)] -translate-y-1/2 transform justify-center',
              {
                'lg:relative lg:left-auto lg:top-auto lg:mb-3 lg:mt-6 lg:h-[var(--lg-img-h)] lg:w-[var(--lg-img-w)] lg:transform-none lg:justify-center':
                  useGridLayoutOnDesktop,
              },
            )}
            style={{
              '--img-w': `${px2Rem(PRODUCT_CARD_IMAGE_SIZES.mobile.width)}`,
              '--img-h': `${px2Rem(PRODUCT_CARD_IMAGE_SIZES.mobile.height)}`,
              '--lg-img-w': `${px2Rem(PRODUCT_CARD_IMAGE_SIZES.desktop.width)}`,
              '--lg-img-h': `${px2Rem(PRODUCT_CARD_IMAGE_SIZES.desktop.height)}`,
            }}
          >
            <picture>
              {useGridLayoutOnDesktop && (
                <source
                  media={desktopMedia}
                  srcSet={desktopImageSrcSet}
                  sizes={desktopImageProps.sizes}
                />
              )}
              <img
                {...mobileImageProps}
                srcSet={mobileImageSrcSet}
                alt={productCardImageSharedProps.alt}
                className="h-auto w-full"
              />
            </picture>
          </div>

          {product.colours.length > 1 ? (
            <ColourSwatches
              dataTestId="colour-swatches"
              colours={product.colours}
              className={cn('items-start justify-start gap-1.5 pb-3 pt-2', {
                'lg:justify-center lg:pb-4 lg:pt-3': useGridLayoutOnDesktop,
              })}
            />
          ) : null}

          <div
            className={cn('flex justify-start gap-2 pb-3', {
              'pt-8': product.colours.length < 2,
              'lg:justify-center lg:pb-4': useGridLayoutOnDesktop,
              'lg:pt-10': useGridLayoutOnDesktop && product.colours.length < 2,
            })}
          >
            {[
              {
                condition: VariantCondition.New,
                label: 'Brand New',
                dataTestId: 'condition-badge-new',
              },
              {
                condition: VariantCondition.Refurbished,
                label: 'Refurbished',
                dataTestId: 'condition-badge-refurbished',
              },
            ]
              .filter((item) => product.condition.includes(item.condition))
              .map((item) => (
                <Badge
                  key={item.condition}
                  type="rounded"
                  fontWeight={CSS_FONT_WEIGHT.vars.regular}
                  color="lightGrey"
                  fontSize={12}
                  textTransform="capitalize"
                  dataTestId={item.dataTestId}
                >
                  {item.label}
                </Badge>
              ))}
          </div>

          {
            {
              displayed: (
                <Typography
                  variant="body2"
                  bold
                  className={cn('!leading-[1.2]', {
                    'lg:type-h4 lg:text-center': useGridLayoutOnDesktop,
                  })}
                >
                  <span
                    className={cn('text-charcoal-400 font-normal', {
                      'lg:type-body2': useGridLayoutOnDesktop,
                    })}
                  >
                    From
                  </span>{' '}
                  £{displayPrice}/mo{' '}
                  {priceSuffix && (
                    <span
                      className={cn('text-charcoal-400 font-normal', {
                        'lg:type-body2': useGridLayoutOnDesktop,
                      })}
                    >
                      {priceSuffix}
                    </span>
                  )}
                </Typography>
              ),
              loading: (
                <SkeletonLoadingBlock
                  dataTestId={`${product.variantSlug}-loading`}
                  $height={18}
                  $borderRadius={5}
                />
              ),
              hidden: null,
            }[priceRenderState]
          }
        </div>
      </div>
    </>
  );
};

export const getSessionStorage = (key: string): string | null => {
  if (typeof window === 'undefined') {
    return null;
  }

  return window?.sessionStorage.getItem(key);
};

export const setSessionStorage = (key: string, value: string): void | null => {
  if (typeof window === 'undefined') {
    return null;
  }

  const stringValue = typeof value === 'string' ? value : JSON.stringify(value);
  window?.sessionStorage.setItem(key, stringValue);
};

export const removeSessionStorage = (key: string): void => window?.sessionStorage?.removeItem(key);

import { styled } from 'styled-components';
import { IStyledIcon } from './types';

const StyledIcon = styled.div<IStyledIcon>`
  ${({ margin }) => margin && `margin: ${margin}`}
  width: ${({ size }) => size && size};
  height: ${({ size }) => size && size};
  & svg {
    width: ${({ size }) => size && size};
    height: ${({ size }) => size && size};
    fill: ${({ fill }) => fill && fill};
  }
  & path {
    color: ${({ color }) => color && color};
    width: ${({ size }) => size && size};
    height: ${({ size }) => size && size};
    stroke: ${({ color }) => color && color};
    fill: ${({ fill }) => fill && fill};
  }
  & circle {
    fill: ${({ color }) => color && color};
  }
  & g {
    clip-path: none;
  }
`;

export { StyledIcon };

import { IconLogoPoweredByRaylo } from 'uibook-icons/custom/IconLogoPoweredByRaylo';
import { Typography } from '../../foundations/Typography/Typography';
import { cn } from '../../utils/cn';
import { useMerchantTheme } from '../MerchantTheme/MerchantTheme';

type PageHeaderBannerProps = {
  className?: string;
  accountBaseUrl: string;
  dataTestId?: string;
};

export const PageHeaderBanner = ({
  className,
  accountBaseUrl,
  dataTestId,
}: PageHeaderBannerProps) => {
  const { isRayloTheme } = useMerchantTheme();

  if (!isRayloTheme) {
    return (
      <div
        className={cn(
          'border-charcoal-200 bg-charcoal-100 justify-end border-b px-6 py-1.5',
          className,
        )}
        data-testid={dataTestId}
      >
        <IconLogoPoweredByRaylo className="h-[1.5625rem]" />
      </div>
    );
  }

  return (
    <div
      className={cn('border-charcoal-200 flex-wrap justify-center border-b px-6 py-2.5', className)}
      data-testid={dataTestId}
    >
      <Typography variant="body2" className="text-charcoal-500" medium>
        Existing customer?&nbsp;
      </Typography>
      <Typography
        className="text-blue-500 hover:underline"
        medium
        element="a"
        variant="body2"
        href={`${accountBaseUrl}/account`}
      >
        Log in to add more tech or upgrade
      </Typography>
    </div>
  );
};

import React, { forwardRef } from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import { Typography } from '../../foundations/Typography/Typography';
import { cn } from '../../utils/cn';
import { alertVariantsConfig } from '../Alert/Alert';

const defaultVariant = 'default';

/** Defines styles for the MediaObject component based on its variant which matches Alert variants.` */
const mediaObjectVariants = cva(
  'relative flex w-full min-w-fit flex-col gap-4 rounded border p-4',
  {
    variants: {
      variant: alertVariantsConfig,
    },
    defaultVariants: {
      variant: defaultVariant,
    },
  },
);

type MediaObjectCvaProps = VariantProps<typeof mediaObjectVariants>;

export type MediaObjectProps = {
  /**
   * The variant of the MediaObject, which determines its styling.
   *
   * Matches the variants defined in the `Alert` component.
   *
   * @example
   *   <MediaObject variant="info" />;
   *
   * @default 'default'
   */
  variant?: MediaObjectCvaProps['variant'];

  /**
   * The media (e.g., an image, animation or svg) displayed on the left of the MediaObject. If not
   * provided, no media will be displayed.
   *
   * Accepts:
   *
   * - `React.ReactNode` for a custom media element.
   *
   * @example
   *   // Custom media
   *   <MediaObject media={<CustomImage />} />;
   */
  media?: React.ReactNode;

  /**
   * Additional props for the wrapper element around the media component.
   *
   * @example
   *   <MediaObject media={<CustomImage /> mediaWrapperProps={{ className: 'size-12' }} />;
   */
  mediaWrapperProps?: React.ComponentPropsWithoutRef<'div'>;

  /** The title of the MediaObject, displayed in bold text. */
  title?: string;

  /**
   * Additional props to customize the `Typography` component used for the MediaObject title.
   *
   * @example
   *   <MediaObject title="Media Title" titleProps={{ className: 'text-lg text-blue-500' }} />;
   */
  titleProps?: React.ComponentPropsWithoutRef<typeof Typography>;

  /** The subtitle of the MediaObject, displayed in regular text. */
  subtitle?: string;

  /**
   * Additional props to customize the `Typography` component used for the MediaObject subtitle.
   *
   * @example
   *   <MediaObject
   *     subtitle="Media Subtitle"
   *     subtitleProps={{ className: 'text-sm text-gray-600' }}
   *   />;
   */
  subtitleProps?: React.ComponentPropsWithoutRef<typeof Typography>;

  /** The body content of the MediaObject, which can include text or custom elements like links. */
  body?: React.ReactNode;
};

/**
 * MediaObject Component
 *
 * A flexible component for displaying a combination of media, title, subtitle, and body content. It
 * supports styling through variants and accepts custom media elements, aligning with the styling
 * conventions of the `Alert` component.
 *
 * ### Props
 *
 * - **`variant`**: `default`, `info`, `warning`, `success`, `error`
 *
 *   - **Default**: `default`
 * - **`media`**: `ReactNode`
 *
 *   - Media (e.g., an image, animation, or SVG) to display on the left side of the MediaObject.
 *   - If not provided, no media will be displayed.
 * - **`mediaWrapperProps`**: `React.ComponentPropsWithoutRef<'div'>`
 *
 *   - Props for customizing the wrapper of the `media` component.
 * - **`title`**: `string`
 *
 *   - The title of the MediaObject, displayed in bold text.
 * - **`titleProps`**: `React.ComponentPropsWithoutRef<typeof Typography>`
 *
 *   - Props for customizing the `Typography` component of the title.
 *   - **Example**: `{ className: 'text-lg text-blue-500' }`
 * - **`subtitle`**: `string`
 *
 *   - The subtitle of the MediaObject, displayed in regular text.
 * - **`subtitleProps`**: `React.ComponentPropsWithoutRef<typeof Typography>`
 *
 *   - Props for customizing the `Typography` component of the subtitle.
 *   - **Example**: `{ className: 'text-sm text-gray-600' }`
 * - **`body`**: `ReactNode`
 *
 *   - The body content of the MediaObject, which can include text or custom elements like links.
 * - **`className`**: `string`
 *
 *   - Additional CSS class names to apply to the root element.
 *
 * ### Examples
 *
 * #### Using the `variant` prop
 *
 * ```tsx
 * <MediaObject
 *   variant="info"
 *   media={<CustomImage />}
 *   title="Media Title"
 *   subtitle="Subtitle Text"
 *   body={<p>Body content here.</p>}
 * />;
 * ```
 *
 * #### Using the `media` prop
 *
 * ```tsx
 * <MediaObject media={<img src="example.png" alt="Example" />} title="Media Title" />;
 * ```
 *
 * #### Using the `mediaWrapperProps` prop
 *
 * ```tsx
 * <MediaObject media={<CustomImage />} mediaWrapperProps={{ className: 'size-12' }} />;
 * ```
 *
 * #### Using the `title` and `titleProps` props
 *
 * ```tsx
 * <MediaObject
 *   media={<CustomImage />}
 *   title="Custom Title"
 *   titleProps={{ className: 'text-lg text-blue-500' }}
 * />;
 * ```
 *
 * #### Using the `subtitle` and `subtitleProps` props
 *
 * ```tsx
 * <MediaObject
 *   media={<CustomImage />}
 *   subtitle="Subtitle Text"
 *   subtitleProps={{ className: 'text-sm text-gray-600' }}
 * />;
 * ```
 *
 * #### Using the `body` prop
 *
 * ```tsx
 * <MediaObject media={<CustomImage />} body={<div>Additional content can go here.</div>} />;
 * ```
 */
export const MediaObject = forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'> & MediaObjectProps
>(
  (
    {
      className,
      variant,
      media,
      mediaWrapperProps,
      title,
      titleProps,
      subtitleProps,
      subtitle,
      body,
      children,
      ...props
    },
    ref,
  ) => (
    <div ref={ref} className={cn(mediaObjectVariants({ variant }), className)} {...props}>
      <div className="flex items-center gap-4">
        {media && (
          <div className="size-16 shrink-0" {...mediaWrapperProps}>
            {media}
          </div>
        )}
        <div className="flex flex-col justify-center">
          {title && (
            <Typography variant="body2" bold {...titleProps}>
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography variant="body2" {...subtitleProps}>
              {subtitle}
            </Typography>
          )}
          {body}
        </div>
      </div>
      {children}
    </div>
  ),
);
MediaObject.displayName = 'MediaObject';

export class AuthTokenInfo {
  readonly value?: string;
  readonly expiresAt?: number;

  constructor(userToken?: string, expiresAt?: string | number) {
    this.value = userToken;
    this.expiresAt =
      typeof expiresAt === 'number' ? expiresAt : Date.parse(expiresAt || '') || undefined;
  }

  hasValue(): boolean {
    return !!this.value;
  }

  isExpired(): boolean {
    return this.hasValue() && this.expiresAt !== undefined && this.expiresAt <= Date.now();
  }

  isValid(): boolean {
    return this.hasValue() && !this.isExpired();
  }
}

import { captureException } from '@sentry/core';
import { AuthTokenInfo } from './authTokenInfo';
import type { AuthTokenType, AuthTokenFetcher } from './auth.types';
import store from 'store';

const USER_STORAGE_KEY = 'clientToken';

export class AuthTokenProvider {
  private tokenFetchInProgress?: Promise<void>;

  constructor(
    private readonly tokenType: AuthTokenType, // 'app' | 'user'
    private readonly getNewToken: AuthTokenFetcher,
  ) {}

  get token(): AuthTokenInfo {
    const token = this.getTokenFromStorage();
    if (!token) {
      throw new Error(
        `No current client ${this.tokenType} token. Use \`renewIfNeeded()\` to fetch a new token.`,
      );
    }
    return token;
  }

  set token(token: AuthTokenInfo) {
    store.set(USER_STORAGE_KEY, token);
  }

  getTokenFromStorage(): AuthTokenInfo | null {
    /** `store` works on both client-side (localStorage) and node */
    const token = store.get(USER_STORAGE_KEY);
    if (!token) return null;

    try {
      return new AuthTokenInfo(token.value, token.expiresAt);
    } catch {
      throw new Error(`Failed to parse client ${this.tokenType} token from storage.`);
    }
  }

  async renewIfNeeded(): Promise<void> {
    if (this.tokenFetchInProgress) {
      await this.tokenFetchInProgress;
      return;
    }

    if (!this.isTokenValid()) {
      this.tokenFetchInProgress = this.fetchAndSetToken();
      await this.tokenFetchInProgress;
    }
  }

  private async fetchAndSetToken(): Promise<void> {
    try {
      const response = await this.getNewToken();
      const newToken = new AuthTokenInfo(response.token, response.expiresAt);

      if (response.expiresAt && !newToken.expiresAt) {
        captureException(
          new Error(
            `Failed to parse client ${this.tokenType} token expiry date ('expiresAt': '${response.expiresAt}')`,
          ),
        );
      }

      this.token = newToken;
    } catch (error) {
      throw new Error(`Failed to fetch client ${this.tokenType} token: ${error}`);
    } finally {
      this.tokenFetchInProgress = undefined;
    }
  }

  private isTokenValid(): boolean {
    const token = this.getTokenFromStorage();
    return token ? !token.isExpired() : false;
  }
}

import { StyledBurgerNavButton } from '../NavBarWrapper/NavBarWrapper.styles';
import {
  StyledCloseButton,
  StyledMenuItem,
  StyledMenuListItem,
  StyledNavigateBack,
} from '../SideNavSubMenu/SideNavSubMenu.styles';
import type {
  AccountStatusType,
  Link,
  MenuItemProps,
  NavigateBackProps,
  handleUpgradeExitModalOpenProps,
} from '../Navbar.types';
import { IconPhone } from 'uibook-icons/solid/IconPhone';
import { IconDesktopComputer } from 'uibook-icons/solid/IconDesktopComputer';
import { IconSmartwatch } from 'uibook-icons/solid/IconSmartwatch';
import { IconConsole } from 'uibook-icons/solid/IconConsole';
import { IconTablet } from 'uibook-icons/solid/IconTablet';
import { IconLaptop } from 'uibook-icons/solid/IconLaptop';
import { IconHeadphones2 } from 'uibook-icons/solid/IconHeadphones2';
import { IconTv } from 'uibook-icons/solid/IconTv';
import { IconLeases } from 'uibook-icons/solid/IconLeases';
import { IconAccount } from 'uibook-icons/solid/IconAccount';
import { IconReferrals } from 'uibook-icons/solid/IconReferrals';
import { IconSpeaker } from 'uibook-icons/solid/IconSpeaker';
import { IconHowItWorks } from 'uibook-icons/solid/IconHowItWorks';
import { palette } from '../../../colors';
import type { SideNavType } from '../NavBarContext/NavBarContext';
import Badge from '../../Badge';
import { CSS_FONT_WEIGHT } from '../../../constants';
import { cn } from '../../../utils/cn';

export const BurgerNav = ({
  onClick,
  sideNavOpen,
  dataTestId,
}: {
  onClick?: () => void;
  sideNavOpen: SideNavType;
  dataTestId?: string;
}) => (
  <StyledBurgerNavButton
    className="burger-nav-button"
    onClick={onClick}
    $sideNavOpen={sideNavOpen === 'product'}
    data-testid={dataTestId}
  >
    <span data-testid={`${dataTestId}-1`} />
    <span data-testid={`${dataTestId}-2`} />
    <span data-testid={`${dataTestId}-3`} />
  </StyledBurgerNavButton>
);

const getBadge = (badge: AccountStatusType) => {
  const isArrears = badge === 'arrears';
  return (
    <div className="ml-4">
      <Badge
        fontWeight={CSS_FONT_WEIGHT.vars.regular}
        color={isArrears ? 'red' : 'green'}
        type="rounded"
        fontSize={12}
        textTransform="capitalize"
      >
        {isArrears ? 'Payment overdue' : 'Upgrade available'}
      </Badge>
    </div>
  );
};

export const MenuItem = ({
  label,
  onClick,
  icon,
  isBold,
  hideChevron,
  border,
  dataTestId,
  productsUrl,
  LinkComponent,
  as,
  badge,
}: MenuItemProps) => (
  <StyledMenuListItem as={as} className="menu-item">
    <StyledMenuItem
      data-testid={dataTestId}
      title={label ?? ''}
      as={(LinkComponent ?? typeof onClick === 'string') ? 'a' : 'button'}
      onClick={typeof onClick === 'string' ? undefined : onClick}
      href={typeof onClick === 'string' ? `${productsUrl ?? ''}${onClick}` : undefined}
      $icon={icon}
      $isBold={isBold}
      $hideChevron={hideChevron}
      $border={border}
    >
      {icon && (
        <div
          className={cn('mr-4', {
            'bg-charcoal-100 flex size-12 items-center justify-center rounded-full':
              icon !== 'nav-recycle',
          })}
        >
          {getIcon(icon)}
        </div>
      )}
      {label}
      {badge && getBadge(badge)}
    </StyledMenuItem>
  </StyledMenuListItem>
);

export const NavigateBack = ({ onClick, title, titleLink }: NavigateBackProps) => (
  <StyledNavigateBack data-testid="back">
    <button data-testid="back-button" onClick={onClick} />
    {titleLink ? <NavLink href={titleLink} title={title} /> : <p>{title}</p>}
  </StyledNavigateBack>
);

type MenuLinksProps = {
  links: Link[];
  dataTestId?: string;
  LinkComponent?: React.ElementType;
};

export const MenuLinks = ({ dataTestId, links, LinkComponent }: MenuLinksProps) => (
  <>
    {links.map(({ displayName, link }, i) => (
      <StyledMenuItem
        data-testid={`${dataTestId}-${i}`}
        key={displayName}
        as={LinkComponent ?? 'a'}
        href={link}
        $hideChevron
      >
        {displayName}
      </StyledMenuItem>
    ))}
  </>
);

export const CloseButton = ({
  onClick,
  dataTestId,
  fillColour,
  hoverFillColour,
}: {
  onClick: () => void;
  dataTestId?: string;
  fillColour?: string;
  hoverFillColour?: string;
}) => {
  const resolvedFillColour = fillColour ?? palette.white;
  const resolvedHoverFillColour = hoverFillColour ?? palette.blue[200];
  return (
    <StyledCloseButton
      data-testid={dataTestId}
      onClick={onClick}
      $fillColour={resolvedFillColour}
      $hoverFillColour={resolvedHoverFillColour}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          data-testid={`${dataTestId}-rect`}
          x="12.707"
          y="12.707"
          width="12"
          height="2"
          transform="rotate(135 12.707 12.707)"
          fill={resolvedFillColour}
        />
        <rect
          x="21.1934"
          y="4.22192"
          width="12"
          height="2"
          transform="rotate(135 21.1934 4.22192)"
          fill={resolvedFillColour}
        />
        <rect
          x="11.293"
          y="12.707"
          width="12"
          height="2"
          transform="rotate(-135 11.293 12.707)"
          fill={resolvedFillColour}
        />
        <rect
          x="19.7773"
          y="21.1924"
          width="12"
          height="2"
          transform="rotate(-135 19.7773 21.1924)"
          fill={resolvedFillColour}
        />
      </svg>
    </StyledCloseButton>
  );
};

const getIcon = (icon: string) => {
  const iconClassNames = 'size-5 text-tv-icon-primary';
  switch (icon) {
    case 'phones':
      return <IconPhone className={iconClassNames} />;
    case 'laptops':
      return <IconLaptop className={iconClassNames} />;
    case 'desktops':
      return <IconDesktopComputer className={iconClassNames} />;
    case 'consoles':
      return <IconConsole className={iconClassNames} />;
    case 'tablets':
      return <IconTablet className={iconClassNames} />;
    case 'headphones':
      return <IconHeadphones2 className={iconClassNames} />;
    case 'watches':
      return <IconSmartwatch className={iconClassNames} />;
    case 'tvs':
      return <IconTv className={iconClassNames} />;
    case 'speakers':
      return <IconSpeaker className={iconClassNames} />;
    case 'referrals':
      return <IconReferrals className={iconClassNames} />;
    case 'my-tech':
      return <IconLeases className={iconClassNames} />;
    case 'account-details':
      return <IconAccount className={iconClassNames} />;
    case 'nav-recycle':
      return <IconHowItWorks className="size-6" />;
    default:
      return undefined;
  }
};

export const NavLink = ({
  href,
  title,
  dataTestId,
  LinkComponent,
  className,
  handleUpgradeExitModal,
}: {
  href: string;
  handleUpgradeExitModal?: (action: handleUpgradeExitModalOpenProps) => void;
  title: string;
  dataTestId?: string;
  LinkComponent?: React.ElementType;
  className?: string;
}) => {
  if (handleUpgradeExitModal) {
    return (
      <button
        className={`${className} desktop-link`}
        data-testid={dataTestId}
        onClick={() => handleUpgradeExitModal({ category: title })}
      >
        {title}
      </button>
    );
  }

  const Link = LinkComponent || 'a';

  return (
    <Link className={className} data-testid={dataTestId} href={href} title={title}>
      {title}
    </Link>
  );
};

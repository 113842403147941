import { GraphQLFormattedError } from 'graphql';
import { ApolloClient, ApolloLink, from, HttpLink } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { AuthTokenProvider } from '../auth/authTokenProvider';
import { AUTHENTICATE_CLIENT_MUTATION } from './mutations/authenticateClient.graphql';
import { AuthTokenInfo } from '../auth/authTokenInfo';

type GraphQLFormattedErrorWithTypeAndAuth = GraphQLFormattedError & {
  type: string;
  authorizations?: {
    client?: boolean;
    user?: boolean;
  };
};

type GraphQLErrorEvents = 'unprocessable' | 'not_ready' | 'unauthorized' | 'default';

/**
 * Each client type uses a different set of links. For example, the `app` client does not use the
 * `createUserAuthLink` or the `createLogLink`, but does use the `createRetryLink` and the
 * `createErrorLink`.
 *
 * This can be overridden by the `linkConfig` property in the `RayloApolloClientConfig` object.
 */
const defaultClientLinks = {
  app: ['createClientAuthLink', 'createLogLink', 'createHttpLink'] as const,
  user: [
    'createClientAuthLink',
    'createUserAuthLink',
    'createRetryLink',
    'createErrorLink',
    'createHttpLink',
  ] as const,
};

type ApolloLinkType = Exclude<
  (typeof defaultClientLinks)[keyof typeof defaultClientLinks][number],
  'createClientAuthLink' | 'createHttpLink'
>;

type RayloApolloClientConfig<TCacheShape> = {
  graphql: {
    endpoint: string;
    clientId: string;
  };
  apollo: {
    ApolloClient: typeof ApolloClient<TCacheShape>;
    /**
     * Optionally override the default `link` config when creating the client instance. The array
     * must start with `createClientAuthLink` and end with `createHttpLink`.
     *
     * This entire config can be overridden by passing a `link` item to the `clientConfig` property.
     */
    linkConfig?: ['createClientAuthLink', ...ApolloLinkType[], 'createHttpLink'];
    clientConfig: ConstructorParameters<typeof ApolloClient<TCacheShape>>[0];
  };
  errorHandlers?: Partial<
    Record<GraphQLErrorEvents, (error: GraphQLFormattedErrorWithTypeAndAuth) => void>
  >;
} & (
  | {
      authTokenType: 'app';
      /** `app` client type does not use cookies, it should only run on the server, and does not use */
      cookies?: never;
    }
  | {
      authTokenType: 'user';
      /** `user` client type uses cookies so that the user can be authorized */
      cookies: {
        get: (key: string) => Promise<string | undefined>;
      };
    }
);

export class RayloApolloClient<TCacheShape> {
  private config: RayloApolloClientConfig<TCacheShape>;

  constructor(config: RayloApolloClientConfig<TCacheShape>) {
    this.config = config;
  }

  createClient() {
    const clientLinks =
      this.config.apollo.linkConfig ?? defaultClientLinks[this.config.authTokenType];

    return new this.config.apollo.ApolloClient({
      /**
       * Each client type uses a different set of links. For example, the `app` client does not use
       * the `createUserAuthLink` or the `createLogLink`, but does use the `createRetryLink` and the
       * `createErrorLink`.
       *
       * At the moment, this is not configurable when creating the `RayloApolloClient` instance, but
       * it could be in the future.
       */
      link: from(clientLinks.map((link) => this[link]())),
      ...this.config.apollo.clientConfig,
    });
  }

  createHttpLink() {
    return new HttpLink({
      uri: this.config.graphql.endpoint,
    });
  }

  createLogLink() {
    return new ApolloLink((operation, forward) => {
      return forward(operation).map((result) => {
        if (result.errors) {
          console.error(operation.operationName, operation.variables, result);
        }
        return result;
      });
    });
  }

  createSimpleClient() {
    return new this.config.apollo.ApolloClient({
      link: this.createHttpLink(),
      cache: this.config.apollo.clientConfig.cache,
    });
  }

  createClientAuthLink() {
    const clientAuthTokenProvider = new AuthTokenProvider(this.config.authTokenType, async () => {
      const { data, errors } = await this.createSimpleClient().mutate({
        mutation: AUTHENTICATE_CLIENT_MUTATION,
        variables: { clientId: this.config.graphql.clientId },
      });

      if (errors) {
        /** @todo Improve error handling */
        console.error('Error authenticating client:', errors);
        return {};
      }

      if (!data?.authenticateClient?.accessToken.value) {
        /** @todo Improve error handling */
        console.error('Error authenticating client:', errors);
        return {};
      }

      const clientToken = data?.authenticateClient?.accessToken;

      return {
        token: clientToken?.value,
        expiresAt: clientToken?.expiresAt,
      };
    });

    return setContext(async (_, { headers }) => {
      await clientAuthTokenProvider.renewIfNeeded();

      return {
        headers: {
          ...headers,
          authorization: `Bearer ${clientAuthTokenProvider.token.value}`,
        },
      };
    });
  }

  createUserAuthLink() {
    return setContext(async (_, { headers }) => {
      if (this.config.authTokenType === 'user') {
        const userToken = await this.config.cookies.get('raylo_userToken');
        const userTokenExpiresAt = await this.config.cookies.get('raylo_userTokenExpiresAt');
        const userAuthToken = new AuthTokenInfo(userToken, userTokenExpiresAt);

        if (userAuthToken.isValid()) {
          return {
            headers: {
              ...headers,
              'X-User-Token': userAuthToken.value,
            },
          };
        }
      }

      return { headers };
    });
  }

  createRetryLink() {
    const maxRetryAttempts = 5;

    return new RetryLink({
      attempts: (count, operation, error) => {
        if (count === 1) {
          console.error(`GraphQL network error - query: ${operation.operationName}. Retrying`);
        } else if (count === maxRetryAttempts) {
          console.error(
            `GraphQL network error - query: ${operation.operationName}. Maximum retry limit reached.`,
          );
          // captureErrorEvent(
          //   `GraphQL network error - query: ${operation.operationName}`,
          //   { graphQLErrors: error, operation: operation },
          // );
        }
        return !!error && count !== maxRetryAttempts;
      },
    });
  }

  createErrorLink() {
    return onError(({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        for (const err of graphQLErrors as GraphQLFormattedErrorWithTypeAndAuth[]) {
          switch (err.type) {
            case 'UNPROCESSABLE': {
              /** @todo Implement `removeCheckoutTokenFromSession` */
              // removeCheckoutTokenFromSession();

              /** @todo Cleanup in future */
              /** `window.location` is handled in the app, not in this package */
              // window.location = FRONTEND_URL;
              this.config.errorHandlers?.unprocessable?.(err);
              return;
            }
            case 'NOT_READY': {
              // captureErrorEvent('GraphQL NOT_READY Error', err);
              // window.location = `/errors/not-ready`;
              this.config.errorHandlers?.not_ready?.(err);
              return;
            }
            case 'UNAUTHORIZED':
              if (err.authorizations && err.authorizations.client && !err.authorizations.user) {
                console.log('[GraphQL UNAUTHORIZED Error]', {
                  graphQLErrors: graphQLErrors,
                  networkError: networkError,
                  operation: operation,
                  forward: forward,
                  error: err,
                });
                // captureErrorEvent('[GraphQL UNAUTHORIZED Error]', {
                //   graphQLErrors: graphQLErrors,
                //   networkError: networkError,
                //   operation: operation,
                //   forward: forward,
                //   error: err,
                // });
                // removeUserToken();
                // window.location = '/login';
              }
              this.config.errorHandlers?.unauthorized?.(err);
              return;

            default:
              this.config.errorHandlers?.default?.(err);
              console.error(`GraphQL error from ${operation.operationName} query`, err);

            // captureErrorEvent(
            //   `GraphQL error from ${operation.operationName} query`,
            //   {
            //     graphQLErrors: graphQLErrors,
            //     networkError: networkError,
            //     operation: operation,
            //     forward: forward,
            //     error: err,
            //   },
            // );
          }
        }
      }
    });
  }
}
